import { Card, Row } from '@indico-data/design-system';
import { Loading } from 'components/Loading';
import { formatToReadableUSDateTime } from 'utils/dateUtils';
import { graphql } from 'gql';
import { ClearanceInsuredBlock_SubmissionFragment, DataType } from 'gql/graphql';
import styled from 'styled-components';
import { InsuredBlockField } from './InsuredBlockField';

graphql(`
  fragment ClearanceInsuredBlock_Submission on Submission {
    createdAt
    fields {
      ...ClearanceInsuredBlock_SubmissionLinkedField
    }
  }

  fragment ClearanceInsuredBlock_SubmissionLinkedField on SubmissionLinkedField {
    field {
      displayName
      vectorName
      isWorkQueueColumn
      typeConfig {
        type
      }
    }
    values {
      uuid
      value
    }
  }
`);

type Props = {
  submission?: ClearanceInsuredBlock_SubmissionFragment;
  isLoading: boolean;
};

export default function ClearanceInsuredBlock({ submission, isLoading }: Props) {
  const primaryFields = submission?.fields.filter((field) => field.field.isWorkQueueColumn);

  // TODO: This is hardcoded to display specific fields.
  // In the future, we will allow the user to configure which fields are displayed here (e.g. a "primary field" configuration).
  const fieldNamesToDisplay = ['Named Insured', 'Broker Name', 'Mailing Address'];
  const fieldsToDisplay = primaryFields
    ?.filter((field) => fieldNamesToDisplay.includes(field.field.displayName))
    .sort(
      (a, b) =>
        fieldNamesToDisplay.indexOf(a.field.displayName) -
        fieldNamesToDisplay.indexOf(b.field.displayName),
    );

  return (
    <StyledClearanceInsuredBlock>
      {isLoading ? (
        <Loading message="Fetching Fields..." minHeight={0} padding={0} />
      ) : (
        <Row>
          <InsuredBlockField
            title="Received"
            value={formatToReadableUSDateTime(new Date(submission?.createdAt))}
            dataType={DataType.Datetime}
          />
          {fieldsToDisplay?.map(({ field, values }) => (
            <InsuredBlockField
              key={field.displayName}
              title={field.displayName}
              value={values[0]?.value}
              dataType={field.typeConfig.type}
            />
          ))}
        </Row>
      )}
    </StyledClearanceInsuredBlock>
  );
}

const StyledClearanceInsuredBlock = styled(Card)`
  background-color: var(--pf-background-color-dark);
  margin-bottom: var(--pf-margin-5);
  padding: var(--pf-padding-5);
  min-height: 150px;
  max-height: 300px;
  flex-shrink: 0;

  h3 {
    margin-top: var(--pf-margin-1);
    margin-bottom: var(--pf-margin-1);
    font-size: var(--pf-font-size-body);
    font-weight: var(--pf-font-weight-bold);
    color: var(--pf-font-color-light-header);
  }

  p {
    color: var(--pf-font-color-emphasis);
    margin-top: var(--pf-margin-1);
    margin-bottom: var(--pf-margin-3);
    font-size: var(--pf-font-size-body);
    font-weight: var(--pf-font-weight-medium);
    padding-right: var(--pf-padding-2);
  }
`;
