import { Filter } from 'gql/graphql';
import { createContext, useContext } from 'react';
import { useFieldTableParams } from './hooks/useFieldTableParams';

type FieldDrivenTableContextType = {
  tableKey: string;
  defaultFilters?: Filter[];
};

export const FieldDrivenTableContext = createContext<FieldDrivenTableContextType | null>(null);

// Provides the table state for a field driven table using the table key to prefix the query params for table state
export const useFieldDrivenTableState = () => {
  const context = useContext(FieldDrivenTableContext);

  if (!context) {
    throw new Error('useFieldDrivenTableState must be used within a FieldDrivenTable');
  }

  const { tableKey, defaultFilters } = context;

  return {
    tableKey,
    defaultFilters,
    ...useFieldTableParams({
      tableKey,
    }),
  };
};
