import { Icon } from '@indico-data/design-system';
import { graphql } from 'gql';
import { DeclineDetails_DetailsFragment } from 'gql/graphql';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

graphql(`
  fragment DeclineDetails_Details on SuggestedActionDeclineDetails {
    reasons
  }
`);

type Props = {
  details: DeclineDetails_DetailsFragment;
};

export const DeclineDetails = ({ details }: Props) => {
  const { reasons } = details;

  return (
    <StyledDeclineDetails>
      {reasons[0]}{' '}
      <span className="text-decoration--underline" data-tooltip-id="decline-details-tooltip">
        See failed conditions
      </span>
      <StyledTooltip id="decline-details-tooltip" className="tooltip" place="bottom" opacity={1}>
        <StyledTooltipContent>
          {reasons.map((reason) => (
            <StyledReason key={reason}>
              <StyledIconContainer>
                <Icon name="fa-times-circle" />
              </StyledIconContainer>
              {reason}
            </StyledReason>
          ))}
        </StyledTooltipContent>
      </StyledTooltip>
    </StyledDeclineDetails>
  );
};

const StyledReason = styled.div`
  background: var(--pf-background-color-dark);
  border-radius: var(--pf-rounded);
  padding: var(--pf-padding-2);
  margin-bottom: var(--pf-margin-2);
`;

const StyledDeclineDetails = styled.span``;

const StyledTooltip = styled(Tooltip)`
  &#decline-details-tooltip {
    max-width: 500px;
    background: var(--pf-background-color);
  }
`;

const StyledTooltipContent = styled.div`
  padding: var(--pf-padding-2) 0;
`;

const StyledIconContainer = styled.div`
  color: var(--pf-error-color);
  margin-right: var(--pf-margin-1);
  display: inline-block;
  vertical-align: middle;
`;
