import { DataType } from 'gql/graphql';
import { ColumnConfig, isCategoricalTypeConfig } from '../../types';
import { TableHeaderSearchWithMultiSelect } from './TableHeaderSearchWithMultiSelect';
import { TableHeaderRangeInput } from './TableHeaderRangeInput';
import { TableHeaderSearch } from './TableHeaderSearch';
import { useFieldDrivenTableState } from '../../FieldDrivenTableContext';

type Props = {
  fieldConfig: ColumnConfig;
};

const filterComponentMap: Record<DataType, React.FC<any>> = {
  [DataType.Categorical]: TableHeaderSearchWithMultiSelect,
  [DataType.Number]: TableHeaderRangeInput,
  [DataType.Address]: TableHeaderSearch,
  [DataType.Boolean]: TableHeaderSearch,
  [DataType.Composite]: TableHeaderSearch,
  [DataType.Currency]: TableHeaderRangeInput,
  [DataType.Datetime]: TableHeaderSearch,
  [DataType.Name]: TableHeaderSearch,
  [DataType.Summary]: TableHeaderSearch,
  [DataType.Text]: TableHeaderSearch,
  [DataType.BigText]: TableHeaderSearch,
};

export const FieldColumnHeaderFilter = ({ fieldConfig }: Props) => {
  const { filters } = useFieldDrivenTableState();

  const FilterComponent = filterComponentMap[fieldConfig.typeConfig.type];
  const options = isCategoricalTypeConfig(fieldConfig.typeConfig)
    ? fieldConfig.typeConfig.options
    : [];

  const appliedFilter = filters?.find((filter) => filter.field === fieldConfig.vectorName) ?? null;

  return (
    <FilterComponent
      displayName={fieldConfig.displayName}
      options={options}
      appliedFilter={appliedFilter}
    />
  );
};
