import { Button, Col, Container, Icon, Row } from '@indico-data/design-system';
import { graphql } from 'gql';
import { DocumentItem_DocumentFragment } from 'gql/graphql';
import { useClearancePageParams } from 'pages/clearance-app/pages/clearance/hooks/useClearancePageParams';
import { useDocumentViewerStore } from 'store/documentViewer/documentViewerStore';
import styled from 'styled-components';

graphql(`
  fragment DocumentItem_Document on Document {
    uuid
    fileName
  }
`);

export const DocumentItem = ({ document }: { document: DocumentItem_DocumentFragment }) => {
  const { selectedDocId, setSelectedDocId } = useClearancePageParams();
  const isSelected = selectedDocId === document.uuid;
  const { changePage } = useDocumentViewerStore();

  const handleDocumentSelect = () => {
    setSelectedDocId(document.uuid);
    changePage(1, { resetZoomFactor: true });
  };

  return (
    <StyledDocumentListItem onClick={handleDocumentSelect} $isSelected={isSelected}>
      <Container fluid>
        <Row nogutter align="center">
          <Col xs="content">
            <Icon name="file" size="lg" />
          </Col>
          <Col>
            <p className="ml-2">{document.fileName}</p>
          </Col>
          <Col xs="content">
            <Button
              iconName="chevron-right"
              ariaLabel="Open Document"
              variant="text"
              color="secondary"
              onClick={handleDocumentSelect}
            />
          </Col>
        </Row>
      </Container>
    </StyledDocumentListItem>
  );
};

const StyledDocumentListItem = styled.li<{ $isSelected: boolean }>`
  border: var(--pf-border-sm) solid var(--pf-border-color);
  border-radius: var(--pf-rounded);
  background: var(--pf-background-color-dark);
  margin-bottom: var(--pf-margin-2);
  padding-top: var(--pf-padding-4);
  padding-bottom: var(--pf-padding-4);
  cursor: pointer;
  background: ${({ $isSelected }) => ($isSelected ? 'var(--pf-background-color-light)' : '')};
`;