import { DataType, FieldDrivenTable_FieldConfigFragment } from 'gql/graphql';
import { FieldFromRowSelector } from '../../types';
import { MultiCategoricalCell, CategoricalCell, BigTextCell, SingleValueCell } from './core';

type Props<T> = {
  row: T;
  fieldFromRowSelector: FieldFromRowSelector<T>;
  fieldConfig: FieldDrivenTable_FieldConfigFragment;
};

// This component is responsible for rendering the correct cell type based on configuration of a field
export const FieldCell = <T extends any>({ row, fieldFromRowSelector, fieldConfig }: Props<T>) => {
  const linkedField = fieldFromRowSelector(row, fieldConfig);

  if (!linkedField) {
    return null;
  }

  const { values } = linkedField;

  if (!values.length) {
    return null;
  }

  if (fieldConfig.multi && fieldConfig.typeConfig.type === DataType.Categorical) {
    return (
      <MultiCategoricalCell
        values={values.map((value) => ({ id: value.uuid, value: value.value }))}
      />
    );
  }

  if (!fieldConfig.multi && fieldConfig.typeConfig.type === DataType.Categorical) {
    return <CategoricalCell value={values[0].value} />;
  }

  if ([DataType.BigText, DataType.Summary].includes(fieldConfig.typeConfig.type)) {
    return <BigTextCell id={values[0].uuid} value={values[0].value} />;
  }

  const isNumericalField = [DataType.Number, DataType.Currency].includes(
    fieldConfig.typeConfig.type,
  );

  return <SingleValueCell value={values[0].value} isNumber={isNumericalField} />;
};
