import { Button, Menu, FloatUI } from '@indico-data/design-system';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { KabobMenuItem } from '../../clearance/types';

interface StyledKabobMenuProps {
  items: KabobMenuItem[];
  onMenuSelect: (id: string) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  minWidth?: number;
}

export default function KabobMenu(props: StyledKabobMenuProps) {
  const { items, onMenuSelect, isOpen, setIsOpen, minWidth } = props;

  return (
    <FloatUI ariaLabel="Menu" isOpen={isOpen} setIsOpen={setIsOpen}>
      <Button
        color="secondary"
        iconName="kabob"
        ariaLabel="Menu Toggle"
        data-testid="kabob-menu-button"
        variant="outline"
        onClick={() => setIsOpen(!isOpen)}
      />
      <Menu>
        {items.map((item) => (
          <StyledMenuButton
            key={item.id}
            data-testid={item.id}
            ariaLabel={item.label}
            iconName={item.isLoading ? undefined : item.icon}
            onClick={() => onMenuSelect(item.id)}
            isLoading={item.isLoading}
            $minWidth={minWidth}
          >
            {item.label}
          </StyledMenuButton>
        ))}
      </Menu>
    </FloatUI>
  );
}

const StyledMenuButton = styled(Button)<{ $minWidth?: number }>`
  white-space: nowrap;
  font-weight: var(--pf-font-weight-medium) !important;
  padding-right: var(--pf-padding-6);
  // Temporary hack to provide room for extra elements that may be added (e.g. loading icon)
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}px;`}
`;
