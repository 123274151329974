import { useCallback, useRef } from 'react';
import styled from 'styled-components';

export const RangeSlider = ({
  min,
  max,
  values,
  onChange,
}: {
  min: number;
  max: number;
  values: number[];
  onChange: (value: number[]) => void;
}) => {
  const minVal = values[0];
  const maxVal = values[1];
  const minValRef = useRef(min);
  const maxValRef = useRef(max);

  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  const handleChangeLeft = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(Number(event.target.value), values[1] - 1);
    onChange([value, values[1]]);
    minValRef.current = value;
  };

  const handleChangeRight = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(Number(event.target.value), values[0] + 1);
    onChange([values[0], value]);
    maxValRef.current = value;
  };

  return (
    <StyledRangeInput>
      <ThumbLeft
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={handleChangeLeft}
        // Prevents the left thumb from being unusable when the min value is at the max value
        $zIndex={minVal > max - 100 ? 5 : 3}
      />
      <ThumbRight type="range" min={min} max={max} value={maxVal} onChange={handleChangeRight} />

      <StyledSlider>
        <SliderTrack />
        <SliderRange $left={getPercent(minVal)} $width={getPercent(maxVal - minVal)} />
        <LeftInput
          value={minVal}
          type="number"
          min={min}
          max={max - 1}
          onChange={handleChangeLeft}
          onKeyDown={(e) => e.preventDefault()}
        />
        <RightInput
          value={maxVal}
          type="number"
          min={min + 1}
          max={max}
          onChange={handleChangeRight}
          onKeyDown={(e) => e.preventDefault()}
        />
      </StyledSlider>
    </StyledRangeInput>
  );
};

const StyledRangeInput = styled.div`
  padding: var(--pf-padding-4);
  height: 70px;
`;

const StyledSlider = styled.div`
  position: relative;
  width: 200px;
`;

const SliderTrack = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 5px;
  background-color: var(--pf-primary-color-600);
  width: 100%;
  z-index: 1;
`;

const SliderRange = styled.div<{ $left: number; $width: number }>`
  position: absolute;
  border-radius: 3px;
  height: 5px;
  background-color: var(--pf-secondary-color);
  z-index: 2;

  /* Determines the position and width of the slider range */
  left: ${({ $left }) => $left}%;
  width: ${({ $width }) => $width}%;
`;

const SliderInput = styled.input`
  position: absolute;
  margin-top: var(--pf-margin-5);
  width: 55px;
  background: var(--pf-background-color);
  color: var(--pf-white-color);
  border: 1px solid var(--pf-border-color);
  border-radius: var(--pf-rounded);

  /* Hide the cursor, only allow interaction via the up/down controls */
  color: transparent;
  text-shadow: 0 0 0 var(--pf-white-color);

  &:focus {
    outline: none;
  }

  /* Always show the up/down controls on number inputs */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
    color: var(--pf-gray-color-600);
  }
`;

const LeftInput = styled(SliderInput)`
  left: 0;
`;

const RightInput = styled(SliderInput)`
  right: 0;
`;

const Thumb = styled.input`
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    background-color: var(--pf-white-color);
    border: none;
    border-radius: var(--pf-rounded-circle);
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  &::-moz-range-thumb {
    background-color: var(--pf-white-color);
    border: none;
    border-radius: var(--pf-rounded-circle);
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
`;

const ThumbLeft = styled(Thumb)<{ $zIndex?: number }>`
  z-index: ${({ $zIndex }) => $zIndex};
`;

const ThumbRight = styled(Thumb)`
  z-index: 4;
`;
