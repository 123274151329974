import React from 'react';
import classNames from 'classnames';
import { IconName } from '@indico-data/design-system/src/types';
import { NavLink } from 'react-router-dom';
import { Icon } from '@indico-data/design-system';
import { StyledDrawerLinkList } from './DrawerLinkList.styles';

export interface ListItem {
  id: string;
  icon: IconName;
  label: string;
  path: string;
}

interface Props {
  listItems?: any[];
  isOpen: boolean;
}

export const DrawerLinkList = (props: Props) => {
  const { listItems, isOpen } = props;

  return (
    <StyledDrawerLinkList className={classNames('drawer__content__list')}>
      {listItems && (
        <ul className="drawer__list">
          {listItems.map((item: Record<string, any>) => (
            <NavLink
              key={item.id}
              to={item.path}
              className={({ isActive, isPending }) => {
                return classNames(
                  'drawer__list__item',
                  { 'drawer__list__item--active': isActive },
                  { 'drawer__list__item--pending': isPending },
                );
              }}
              role="link"
              aria-label={item.label}
            >
              <li className="drawer__list__item__link">
                <span className="drawer__list__item__icon">
                  <Icon size="lg" name={item.icon} ariaLabel={`${item.label} icon`} />
                </span>
                <span
                  className={`drawer__list__item__label ${
                    !isOpen ? 'drawer__list__item__label--hidden' : ''
                  }`}
                  aria-hidden={!isOpen}
                >
                  {item.label}
                </span>
              </li>
            </NavLink>
          ))}
        </ul>
      )}
    </StyledDrawerLinkList>
  );
};
