import { ToastContainer as ToastifyToastContainer } from 'react-toastify';

import styled from 'styled-components';
import { TOAST_AUTO_CLOSE_TIME } from './constants';

// TODO: Move custom toast styles to PF
export const ToastContainer = () => {
  return (
    <StyledToastContainer>
      <ToastifyToastContainer
        theme="dark"
        autoClose={TOAST_AUTO_CLOSE_TIME}
        position="top-center"
      />
    </StyledToastContainer>
  );
};

const StyledToastContainer = styled.div`
  a {
    color: var(--pf-secondary-color-400);
  }
`;
