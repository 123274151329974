export const users = [
  'Julia Patel',
  'Jane Doe',
  'Jim Doe',
  'Jack Doe',
  'Alexander Johnson',
  'Isabella Thompson',
  'Benjamin Harrison',
  'Christopher Wellington III',
  'Elizabeth Montgomery-Smythe',
  'Nathaniel Vanderbilt-Carmichael',
];
