import { RiskArea } from './types';

export const computeRiskAppetite = (riskAreas: RiskArea[]) => {
  const totalQuestions = riskAreas.reduce((acc, riskArea) => acc + riskArea.questions.length, 0);

  const trueQuestions = riskAreas.reduce((accA, riskArea) => {
    return (
      accA +
      riskArea.questions.reduce(
        (accB, question) => (question.answer === false ? accB + 1 : accB),
        0,
      )
    );
  }, 0);

  const riskAppetite = (trueQuestions / totalQuestions) * 100;

  if (riskAppetite <= 30) {
    return 'Low';
  }

  if (riskAppetite <= 70) {
    return 'Medium';
  }

  return 'High';
};
