import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { media, textTruncationWithEllipsis } from 'utils/styleUtils';

type Props = {
  value: any;
  id: string;
};

export const BigTextCell = ({ value, id }: Props) => {
  return (
    <StyledBigTextCell data-tag="allowRowEvents">
      <StyledText data-tag="allowRowEvents" data-tooltip-id={`${id}-tooltip`}>
        {value}
      </StyledText>
      <Tooltip id={`${id}-tooltip`}>{value}</Tooltip>
    </StyledBigTextCell>
  );
};

const StyledBigTextCell = styled.div`
  width: 100%;
  max-width: 200px;

  ${media.xs} {
    max-width: 250px;
  }
  ${media.sm} {
    max-width: 300px;
  }
  ${media.md} {
    max-width: 400px;
  }
  ${media.lg} {
    max-width: 500px;
  }
  ${media.xl} {
    max-width: 600px;
  }
  ${media.xxl} {
    max-width: 700px;
  }
  ${media.xxxl} {
    max-width: 1000px;
  }
`;

const StyledText = styled.p`
  ${textTruncationWithEllipsis};
  width: 100%;
`;
