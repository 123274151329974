import { AttachedDocuments_InboxEventFragment, EventInfo_InboxEventFragment } from 'gql/graphql';

export const findFieldWithDisplayName = (
  event: EventInfo_InboxEventFragment,
  displayName: string,
) => {
  return event.fields.find((field) => field.field.displayName === displayName);
};

// TODO: This is a temporary function to get the document type from the "Doc Type" field on an event.
// Eventually, fields (such as "Doc Type") will be exposed directly on Documents.
export const getDocumentType = (docUuid: string, event: AttachedDocuments_InboxEventFragment) => {
  const docTypeValues = event.fields.find(
    (field) => field.field.displayName === 'Doc Type',
  )?.values;
  return docTypeValues?.find((fieldValue) => fieldValue.document?.uuid === docUuid)?.value;
};
