import { DocumentControls } from 'pages/clearance-app/components/document/DocumentControls/DocumentControls';
import { Thumbnails } from 'pages/clearance-app/components/document/Thumbnails/Thumbnails';
import { DocumentContainer } from 'pages/clearance-app/components/document/DocumentContainer';
import styled from 'styled-components';
import { DocumentInfo } from 'pages/clearance-app/components/document/DocumentInfo/DocumentInfo';
import { CirclePulse } from '@indico-data/design-system';
import { useState } from 'react';
import { graphql } from 'gql';
import { useQuery } from 'urql';
import { convertStoragePathToAppPath } from 'utils/urls';

type Props = {
  documentId: string;
  submissionId: string;
};

export const SubmissionDocument = ({ documentId, submissionId }: Props) => {
  const [isThumbnailsVisible, setIsThumbnailsVisible] = useState(true);

  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query SubmissionDocument_GetDocuments($submissionId: String!) {
        submissions(uuids: [$submissionId]) {
          items {
            uuid
            events {
              uuid
              documents {
                uuid
                filesDigest(filenames: ["_original", "etl_output.json"])
                ...DocumentInfo_Document
              }
            }
          }
        }
      }
    `),
    variables: {
      submissionId,
    },
  });

  const document = data?.submissions.items[0].events
    .map((event) => event.documents)
    .flat()
    .find((doc) => doc.uuid === documentId);

  const etlOutputPath =
    !fetching && document
      ? convertStoragePathToAppPath(document?.filesDigest?.['etl_output.json'])
      : undefined;

  if (fetching) {
    return (
      <StyledSubmissionDocument>
        <StyledLoadingContainer>
          Loading Document
          <CirclePulse overallSize="100px" />
        </StyledLoadingContainer>
        <StyledLoadingThumbnails>
          Loading Thumbnails
          <CirclePulse overallSize="100px" />
        </StyledLoadingThumbnails>
      </StyledSubmissionDocument>
    );
  }

  if (!document || !etlOutputPath) {
    return (
      <StyledSubmissionDocument>
        <StyledNoDocumentFound>
          No document with ID {documentId} could be found.
        </StyledNoDocumentFound>
      </StyledSubmissionDocument>
    );
  }

  return (
    <StyledSubmissionDocument>
      <DocumentInfo document={document} />
      <StyledDocumentWithControls $isThumbnailsVisible={isThumbnailsVisible}>
        <DocumentControls />
        <DocumentContainer etlOutputPath={etlOutputPath} />
      </StyledDocumentWithControls>
      <Thumbnails
        isVisible={isThumbnailsVisible}
        toggleThumbnails={() => setIsThumbnailsVisible(!isThumbnailsVisible)}
      />
    </StyledSubmissionDocument>
  );
};

const StyledSubmissionDocument = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledDocumentArea = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const StyledDocumentWithControls = styled(StyledDocumentArea) <{ $isThumbnailsVisible: boolean }>`
  padding: var(--pf-padding-5);
  padding-bottom: var(--pf-padding-3);
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledLoadingContainer = styled(StyledDocumentArea)`
  height: 100%;
  color: var(--pf-black-color);
  background: var(--pf-white-color);
`;

const StyledNoDocumentFound = styled(StyledDocumentArea)`
  padding: var(--pf-padding-5);
  text-align: center;
  background-color: var(--pf-background-color);
`;

const StyledLoadingThumbnails = styled.div`
  border: var(--pf-border-thin) solid var(--pf-border-color);
  text-align: center;
  padding: var(--pf-padding-5);
  width: 100%;
  background-color: var(--pf-background-color);
`;
