import styled from 'styled-components';
import { useClearancePageParams } from 'pages/clearance-app/pages/clearance/hooks/useClearancePageParams';
import { useDocumentViewerStore } from 'store/documentViewer/documentViewerStore';
import { replaceCitationsWithButtons } from '../../helpers';
import { Citation, MessageFeedItem } from '../../types';

const StyledSystemMessageItem = styled.div``;
const StyledSystemMessageTextResponse = styled.div`
  white-space: pre-wrap;
`;

// TOOD: include some kind of loading state which does not hide the text as it's generated
// const SystemMessageLoading = () => <div>Loading...</div>;

export default function SystemMessage({ text, id, citations = [] }: MessageFeedItem) {
  const { setSelectedDocId } = useClearancePageParams();
  const { setCitation } = useDocumentViewerStore();

  function handleGoToCitation(citation: Citation) {
    setSelectedDocId(citation.documentLocation.fileUuid);
    setCitation(citation.documentLocation.boundingBox);
  }
  return (
    <StyledSystemMessageItem key={id}>
      {/* {isLoading ? (
        <SystemMessageLoading />
      ) : ( */}
      <StyledSystemMessageTextResponse>
        <p>{replaceCitationsWithButtons(text, citations, handleGoToCitation)}</p>
      </StyledSystemMessageTextResponse>
      {/* )} */}
    </StyledSystemMessageItem>
  );
}
