import styled from 'styled-components';

export const NoDataComponent = () => {
  return <StyledNoDataComponent>No results found</StyledNoDataComponent>;
};

const StyledNoDataComponent = styled.div`
  border-top: 1px solid var(--pf-table-border-color);
  width: 100%;
  padding: var(--pf-padding-4);
  background: var(--pf-table-background-color);
  color: var(--pf-table-font-color);
`;
