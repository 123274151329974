import { AxiosRequestConfig } from 'axios';
import { OriginType, buildUrlFromPath } from 'utils/urls';
import instance from './axiosInstance';

export const fetchData = async (
  path: string,
  config?: {
    headers?: AxiosRequestConfig;
    originType: OriginType;
  },
) => {
  try {
    const response = await instance.get(buildUrlFromPath(path, config?.originType), {
      ...config?.headers,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postData = async (
  path: string,
  body?: any,
  config?: {
    headers?: AxiosRequestConfig;
    originType?: OriginType;
  },
) => {
  try {
    const response = await instance.post(buildUrlFromPath(path, config?.originType), body, {
      ...config?.headers,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
