import { TableColumn } from '@indico-data/design-system/src/types';
import { DataType, EventsTable_InboxEventFragment, InboxEventStatus, Priority } from 'gql/graphql';
import {
  PriorityCell,
  EventStatusCell,
} from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/cells/auxiliary';
import { SingleValueCell } from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/cells/core/SingleValueCell';
import { FieldColumnHeader } from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/header/FieldColumnHeader';
import { ColumnConfig } from 'pages/clearance-app/components/tables/FieldsDrivenTable/types';
import { formatToReadableUSDateTime } from 'utils/dateUtils';

const fixedColumnConfigs: Record<string, ColumnConfig> = {
  status: {
    displayName: 'Status',
    vectorName: 'status',
    typeConfig: {
      type: DataType.Categorical,
      options: Object.values(InboxEventStatus),
    },
    multi: false,
  },
  received: {
    displayName: 'Received',
    vectorName: '_creationTimeUnix',
    typeConfig: {
      type: DataType.Datetime,
    },
    multi: false,
  },
  priority: {
    displayName: 'Priority',
    vectorName: 'priority',
    typeConfig: {
      type: DataType.Categorical,
      options: Object.values(Priority),
    },
    multi: false,
  },
  riskAppetite: {
    displayName: 'Risk Appetite',
    vectorName: 'risk_appetite',
    typeConfig: {
      type: DataType.Number,
    },
    multi: false,
  },
};

export const inboxFixedColumns: TableColumn<EventsTable_InboxEventFragment>[] = [
  {
    name: <FieldColumnHeader fieldConfig={fixedColumnConfigs.received} />,
    id: fixedColumnConfigs.received.displayName,
    selector: (event) => formatToReadableUSDateTime(new Date(event.createdAt)),
    width: '225px',
  },
  {
    name: <FieldColumnHeader fieldConfig={fixedColumnConfigs.status} />,
    id: fixedColumnConfigs.status.displayName,
    cell: (event) => <EventStatusCell status={event.status} />,
    width: '175px',
  },
  {
    name: <FieldColumnHeader fieldConfig={fixedColumnConfigs.priority} />,
    id: fixedColumnConfigs.priority.displayName,
    cell: (event) => <PriorityCell priority={event.priority} />,
    width: '150px',
  },
  {
    name: <FieldColumnHeader fieldConfig={fixedColumnConfigs.riskAppetite} />,
    id: fixedColumnConfigs.riskAppetite.displayName,
    cell: (event) => <SingleValueCell value={event.riskAppetite} isNumber />,
    width: '175px',
  },
];
