import { useState, useEffect, useCallback } from 'react';

type UseDebouncedProps = {
  initialValue: string;
  delay: number;
};

export const useDebounced = ({ initialValue, delay }: UseDebouncedProps) => {
  const [value, setValue] = useState<string>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<string>(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return { value, handleChange, debouncedValue };
};
