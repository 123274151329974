import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

type AuthUser = {
  id: number;
  active: boolean;
  name: string;
  uuid: string;
  email: string;
};

type AuthState = {
  authUser: AuthUser | null;
};

type AuthActions = {
  setAuthUser: (authUser: AuthUser | null) => void;
  removeAuthUser: () => void;
};

const useAuthStore = create<AuthState & AuthActions>((set) => ({
  authUser: null,
  setAuthUser: (authUser: AuthUser | null) => {
    // Convert authUser to a JSON string and store it in local storage
    localStorage.setItem('imIn', JSON.stringify(authUser));
    // Update state
    set({ authUser });
  },
  removeAuthUser: () => {
    // Remove the authUser from local storage
    localStorage.removeItem('imIn');
    // Update state
    set({ authUser: null });
  },
}));

export default useAuthStore;

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Auth Store', useAuthStore);
}
