import { Button } from '@indico-data/design-system';
import { InboxAction } from 'gql/graphql';
import { useState } from 'react';
import styled from 'styled-components';
import { INBOX_ACTION_TO_ICON_NAME } from '../constants';

type Props = {
  onAssociate: () => Promise<void>;
};

export const AssociateButton = ({ onAssociate }: Props) => {
  const [isAssociating, setIsAssociating] = useState(false);

  const handleAssociate = async () => {
    setIsAssociating(true);
    await onAssociate();
    setIsAssociating(false);
  };

  return (
    <StyledAssociateButton
      iconName={
        isAssociating ? undefined : INBOX_ACTION_TO_ICON_NAME[InboxAction.AssociateSubmission]
      }
      isLoading={isAssociating}
      ariaLabel="Associate"
      variant="outline"
      onClick={handleAssociate}
    >
      Associate
    </StyledAssociateButton>
  );
};

export const StyledAssociateButton = styled(Button)`
  font-size: var(--pf-table-font-size);
`;
