import { fetchData, postData } from 'utils/axios/restApiHelpers';
import { AskMySubmissionTab_DocumentFragment } from 'gql/graphql';
import { graphql } from 'gql';
import { useQuery } from 'urql';

import { buildUrlFromPath } from 'utils/urls';
import { ASK_MY_DOCS_API } from 'constants/routes';

import { ConversationMeta, APIMessage } from './types';

export function getNewConversation(
  submissionUid: string,
  fileUUIDs: string[],
): Promise<ConversationMeta> {
  return postData(`${ASK_MY_DOCS_API}/get-new-conversation/`, {
    submission_uuid: submissionUid,
    file_uuids: fileUUIDs,
  });
}

export function getConversationsList(
  submissionUid: string,
): Promise<{ conversations: ConversationMeta[] }> {
  return fetchData(`${ASK_MY_DOCS_API}/conversations?submission_uuid=${submissionUid}`);
}

export function getConversation(conversationId: number): Promise<{ messages: APIMessage[] }> {
  return fetchData(`${ASK_MY_DOCS_API}/conversation/${conversationId}`);
}

export function getSummaryEventSource(chatId: number | null, message: string) {
  if (chatId === null) {
    throw Error("Chat didn't initialize properly. Do some error handling.");
  }
  const text = encodeURIComponent(message);
  const chatIdEncoded = encodeURIComponent(chatId);
  return new EventSource(
    buildUrlFromPath(
      `${ASK_MY_DOCS_API}/get-summary/${chatIdEncoded}?text=${text}&include_quotes=false`,
    ),
    {
      withCredentials: true,
    },
  );
}

export function useDocuments(submissionId: string): {
  documents: AskMySubmissionTab_DocumentFragment[] | undefined;
  fetching: boolean;
} {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query AskMySubmissionTab_GetDocuments($submissionId: String!) {
        submissions(uuids: [$submissionId]) {
          items {
            events {
              documents {
                ...AskMySubmissionTab_Document
              }
            }
          }
        }
      }
    `),
    variables: {
      submissionId,
    },
  });

  return {
    documents: data?.submissions.items[0].events.map((event) => event.documents).flat(),
    fetching,
  };
}
