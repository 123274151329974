export enum OriginType {
  API = 'API',
  AUTH = 'AUTH',
}

export const getOrigin = (originType: OriginType): string => {
  const reactAppOrigin = process.env[`REACT_APP_${originType}_ORIGIN`];

  // If an env variable is found for the origin type, return it
  if (process.env.NODE_ENV === 'development' && reactAppOrigin) {
    return reactAppOrigin;
  }

  // If an env variable is not found for the origin type, default to the API origin
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_API_ORIGIN) {
    return process.env.REACT_APP_API_ORIGIN;
  }

  return window.location.origin;
};

export const buildUrlFromPath = (path: string, originType: OriginType = OriginType.API) =>
  getOrigin(originType) + (path.length && !path.startsWith('/') ? '/' : '') + path;

export const convertStoragePathToAppPath = (storage: string) => {
  // replace the indico storage path with a fully qualified app path
  const storageURI = storage.replace(/^indico-.*:\/\/blob/, '');
  return `/storage/${storageURI}`;
};

export const removeFileExtensionFromPath = (url: string) => {
  const lastDotIndex = url.lastIndexOf('.');
  return lastDotIndex !== -1 ? url.substring(0, lastDotIndex) : url;
};
