import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { fetchData } from '../utils/axios/restApiHelpers';
import * as routes from '../constants/routes';

const useFeatureConfigs = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      window.Insights = {
        features: { askMyDocument: true },
      };
    } else {
      const fetchFeatureConfigs = async () => {
        try {
          const data = await fetchData(routes.FEATURE_CONFIG);
          if (typeof data !== 'object' || data === null) {
            throw new Error('Response is not a JSON object');
          }
          window.Insights = {
            features: data.features,
          };
        } catch (error) {
          toast.error('Error fetching feature configs');
        }
      };

      fetchFeatureConfigs();
    }
  }, []);
};

export default useFeatureConfigs;
