// Apps
export const SEARCH_APP = '/search-app';
export const CLEARANCE_APP = '/clearance-app';

// Auth
export const AUTH = '/auth/users';
export const LOGIN = `${AUTH}/authenticate`;
export const LOGOUT = `${AUTH}/logout`;
export const GET_USER_DETAILS = `${AUTH}/details`;
export const SSO = `${AUTH}/saml/sso?redirect_url=${window.location}`;

// Feature Config
export const FEATURE_CONFIG = '/feature_config.json';

// Storage
export const STORAGE = '/storage';

// Lagoon
export const API = '/graphql';

// Ask My Docs
export const ASK_MY_DOCS_API = '/ask-my-docs/api';
