import React from 'react';
import { Col, Row, Skeleton } from '@indico-data/design-system';
import { useQuery } from 'urql';
import { graphql } from 'gql';
import styled from 'styled-components';
import ClearanceViewTabs from '../../components/clearance/tabs/ClearanceViewTabs';
import ClearanceInsuredBlock from '../../components/clearance/insuredBlock/ClearanceInsuredBlock';
import ClearanceTopBar from '../../components/clearance/header/ClearanceTopBar';
import { CLEARANCE_VIEW_TABS } from './constants';
import SubmissionTab from '../../components/clearance/tabs/items/submission/SubmissionTab';
import DocumentsTab from '../../components/clearance/tabs/items/documents/DocumentsTab';
import RiskAppetiteTab from '../../components/clearance/tabs/items/riskAppetite/RiskAppetiteTab';
import { useTabNavigation } from '../../hooks/useTabNavigation';
import HistoryTab from '../../components/clearance/tabs/items/history/HistoryTab';
import AskMySubmissionTab from '../../components/clearance/tabs/items/askMySubmission/AskMySubmissionTab';
import { SubmissionDocumentPanel } from './components/SubmissionDocumentPanel';
import { useClearancePageParams } from './hooks/useClearancePageParams';

export type ClearancePageTabProps = {
  submissionId: string;
};

const ClearancePageTabMap: { [tabId: string]: React.FC<ClearancePageTabProps> | null } = {
  submission: SubmissionTab,
  documents: DocumentsTab,
  appetite: RiskAppetiteTab,
  history: HistoryTab,
  askMySubmission: AskMySubmissionTab,
};

graphql(`
  fragment ClearancePage_Submission on Submission {
    uuid
    ...ClearanceInsuredBlock_Submission
  }
`);

const ClearancePage = () => {
  const { activeTab, handleSelectTab } = useTabNavigation('submission');
  const { submissionId } = useClearancePageParams();

  const [{ data: submissionData, fetching }] = useQuery({
    query: graphql(`
      query ClearancePage_GetSubmission($submissionId: String!) {
        submissions(uuids: [$submissionId]) {
          items {
            ...ClearancePage_Submission
          }
        }
      }
    `),
    variables: { submissionId: submissionId || '' },
  });

  const submission = submissionData?.submissions.items[0];

  const CurrentTab = ClearancePageTabMap[activeTab];

  if (!fetching && !submission) {
    return (
      <StyledNoSubmissionFound>
        No submission with ID {submissionId} could be found.
      </StyledNoSubmissionFound>
    );
  }

  return (
    <StyledClearancePage>
      <StyledClearanceStickyWrapper>
        {fetching || !submission ? (
          <Skeleton height={36} width={200} className="mx-6" />
        ) : (
          <ClearanceTopBar />
        )}
      </StyledClearanceStickyWrapper>
      <StyledWrapper>
        <StyledRow nogutter>
          <StyledCol xs={6}>
            <StyledClearancePageContent>
              <ClearanceInsuredBlock submission={submission} isLoading={fetching} />
              <ClearanceViewTabs
                tabList={CLEARANCE_VIEW_TABS}
                activeTab={activeTab}
                handleSelectTab={(tab) => handleSelectTab(tab)}
              />
              <StyledTabContainer>
                {/* TODO: Handle empty or invalid submission IDs */}
                {CurrentTab ? <CurrentTab submissionId={submissionId || ''} /> : null}
              </StyledTabContainer>
            </StyledClearancePageContent>
          </StyledCol>
          <StyledCol xs={6}>
            <SubmissionDocumentPanel />
          </StyledCol>
        </StyledRow>
      </StyledWrapper>
    </StyledClearancePage>
  );
};

const StyledClearancePage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledClearanceStickyWrapper = styled.div`
  background-color: var(--pf-background-color-dark);
  margin-left: var(--pf-negative-margin-5);
  margin-right: var(--pf-negative-margin-5);
  padding: var(--pf-padding-5);
  border-bottom: var(--pf-border-sm) solid var(--pf-border-color);
`;

const StyledWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const StyledNoSubmissionFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledClearancePageContent = styled.div`
  padding: var(--pf-padding-5);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledCol = styled(Col)`
  height: 100%;
`;

const StyledTabContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: var(--pf-padding-3);
`;

export default ClearancePage;
