import { Get, Set, Page, ScrollPosition, ChangePageOptions, BoundingBox } from './types';

export const zoomFactorAction = (set: Set) => {
  return (factor: number) => {
    set(() => {
      return { zoomFactor: factor };
    });
  };
};

export const incrementZoomFactorAction = (set: Set, get: Get) => {
  return (factor: number) => {
    const { zoomFactor } = get();
    set(() => {
      if ((factor < 0 && zoomFactor > 1) || factor > 0) {
        return { zoomFactor: zoomFactor + factor };
      }

      return {};
    });
  };
};

export const pagesAction = (set: Set) => {
  return (pages: Page[]) => {
    set(() => {
      return { pages };
    });
  };
};

export const documentPageLoadingAction = (set: Set) => {
  return (value: boolean) => {
    set(() => ({
      documentPageLoading: value,
    }));
  };
};

export const changePageAction = (set: Set, get: Get) => {
  return (newPageNumber: number, options?: ChangePageOptions) => {
    const { setScrollPosition, setZoomFactor } = get();

    set(() => {
      return { currentPageNumber: newPageNumber };
    });

    if (options?.resetZoomFactor) {
      setZoomFactor(1);
    }

    if (options?.scrollPosition) {
      setScrollPosition(options.scrollPosition);
    } else {
      setScrollPosition('top');
    }
  };
};

export const incrementPageAction = (get: Get) => {
  return () => {
    const { currentPageNumber, pages, changePage } = get();

    if (currentPageNumber < pages.length) {
      changePage(currentPageNumber + 1);
    }
  };
};

export const decrementPageAction = (get: Get) => {
  return () => {
    const { currentPageNumber, changePage } = get();

    if (currentPageNumber > 1) {
      changePage(currentPageNumber - 1);
    }
  };
};

export const scrollPositionAction = (set: Set) => {
  return (position: ScrollPosition) => {
    set(() => ({
      scrollPosition: position,
    }));
  };
};

export const citationAction = (set: Set, get: Get) => {
  return (box: BoundingBox | null) => {
    if (box !== null) {
      const { changePage } = get();
      changePage(box.pageNumber);
    }
    set(() => {
      return { citation: box };
    });
  };
};
