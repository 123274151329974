import styled, { css } from 'styled-components';
import { graphql } from 'gql';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextFieldInput_SubmissionLinkedFieldFragment } from 'gql/graphql';
import { Button, Textarea } from '@indico-data/design-system';
import { useClearancePageParams } from 'pages/clearance-app/pages/clearance/hooks/useClearancePageParams';
import { useDocumentViewerStore } from 'store/documentViewer/documentViewerStore';
import { SubmissionFieldFormState } from '../../../types';

type Props = {
  value: string;
  field: TextFieldInput_SubmissionLinkedFieldFragment;
  isEditable: boolean;
  index: number;
  disableRemoval: boolean;
  documentId?: string;
  autoFocus?: boolean;
  onRemove: () => void;
};

graphql(`
  fragment TextFieldInput_SubmissionLinkedField on SubmissionLinkedField {
    field {
      displayName
      multi
    }
  }
`);

const MIN_TEXTAREA_HEIGHT = 40;

export const SubmissionTextFieldRow = ({
  value,
  field,
  isEditable,
  index,
  onRemove,
  disableRemoval,
  documentId,
  autoFocus,
}: Props) => {
  const { register } = useFormContext<SubmissionFieldFormState>();

  const { setSelectedDocId } = useClearancePageParams();
  const { changePage } = useDocumentViewerStore();

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { field: fieldData } = field;
  const { ref, ...rest } = register(`values.${index}.value`);

  const handleRightChevronClick = (docId: string) => {
    setSelectedDocId(docId);
    changePage(1, { resetZoomFactor: true });
  };

  // Dynamically set the height of the textarea to fit the content, with a minimum height of 45px
  useEffect(() => {
    if (inputRef.current) {
      // Temporarily set the height to 'inherit' to get the correct scrollHeight
      inputRef.current.style.height = 'inherit';
      const newHeight = Math.max(MIN_TEXTAREA_HEIGHT, inputRef.current?.scrollHeight || 0);
      // Set the height of the input to the new height
      inputRef.current.style.height = `${newHeight}px`;
    }
  }, [inputRef.current?.scrollHeight]);

  // When the field is editable, focus it and set the cursor to the end of the text
  useEffect(() => {
    if (autoFocus && isEditable) {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length,
      );
    }
  }, [autoFocus, isEditable]);

  return (
    <StyledFormFieldWrapper $isEditable={isEditable}>
      <Textarea
        label={`${fieldData.displayName}`}
        {...rest}
        hasHiddenLabel
        defaultValue={value}
        readonly={!isEditable}
        placeholder={!isEditable ? 'No Value' : ''}
        rows={1}
        // Attach both the input ref and the ref returned by Reach Hook Form
        ref={(e) => {
          ref(e);
          // @ts-ignore
          inputRef.current = e;
        }}
      />
      {!isEditable && documentId && (
        <Button
          iconName="chevron-right"
          className="color-secondary"
          ariaLabel="expand"
          size="sm"
          onClick={() => handleRightChevronClick(documentId)}
        />
      )}
      {fieldData.multi && isEditable && (
        <Button
          iconName="trash"
          className="color-error ml-1"
          ariaLabel="delete"
          size="md"
          isDisabled={disableRemoval}
          onClick={onRemove}
        />
      )}
    </StyledFormFieldWrapper>
  );
};

const StyledFormFieldWrapper = styled.div<{ $isEditable: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: var(--pf-margin-3);
  margin-bottom: var(--pf-margin-1);

  &:not(:last-child) {
    ${({ $isEditable }) =>
      !$isEditable &&
      css`
        border-bottom: var(--pf-border-sm) solid var(--pf-border-color);
      `}
  }

  textarea {
    max-height: 150px;
    padding: var(--pf-padding-2) var(--pf-padding-3);

    &::placeholder {
      font-style: italic;
    }

    ${({ $isEditable }) =>
      !$isEditable &&
      css`
        &,
        &:focus,
        &:active,
        &:hover {
          outline: none;
          border: none;
        }
      `}
  }
`;
