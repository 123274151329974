import { useNavigate } from 'react-router-dom';
import useAuthStore from 'store/auth/authStore';
import { fetchData, postData } from 'utils/axios/restApiHelpers';
import * as routes from 'constants/routes';
import { OriginType } from 'utils/urls';
import { useCallback } from 'react';
import { isAxiosError } from 'axios';

export type AuthFormValues = {
  email: string;
  password: string;
};

/*
 * This hook provides the necessary functions to authenticate the user, login, and logout.
 */
export const useAuth = () => {
  const { authUser, setAuthUser, removeAuthUser } = useAuthStore();
  const navigate = useNavigate();

  // Attempt to authenticate the auth user and redirect to homepage
  const authenticateUser = useCallback(async () => {
    try {
      const response = await fetchData(routes.GET_USER_DETAILS, {
        originType: OriginType.AUTH,
      });

      const userDetails = {
        id: response.id,
        active: response.active,
        name: response.name,
        uuid: response.uuid,
        email: response.email,
      };

      setAuthUser(userDetails);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 401) {
        console.error('Failed to initialize auth user. Credentials were invalid or empty.', error);
      } else {
        console.error('Failed to initialize auth user', error);
      }

      throw error;
    }
  }, [setAuthUser]);

  // Attempt to login with the provided credentials
  const login = useCallback(
    async (authFormValues: AuthFormValues) => {
      try {
        await postData(routes.LOGIN, authFormValues, {
          originType: OriginType.AUTH,
        });
      } catch (error) {
        console.error('Failed to login', error);
        throw error;
      }

      await authenticateUser();
      navigate('/', { replace: true });
    },
    [authenticateUser, navigate],
  );

  // Logout the user and navigate to the login page
  const logout = useCallback(async () => {
    try {
      await postData(routes.LOGOUT, undefined, {
        originType: OriginType.AUTH,
      });
      removeAuthUser();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Failed to logout', error);
    }
  }, [navigate, removeAuthUser]);

  return { authUser, authenticateUser, login, logout };
};
