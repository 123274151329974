import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { Card, Icon } from '@indico-data/design-system';
import { RiskAnswer, RiskQuestion } from '../../types';
import { Thumb } from './components/Thumb';
import { AnswerButton } from './components/AnswerButton';

interface RiskAppetiteCardProps {
  riskQuestion: RiskQuestion;
  onAnswer: (answer: RiskAnswer) => void;
}

export const RiskAppetiteCard = (props: RiskAppetiteCardProps) => {
  const { riskQuestion: riskArea, onAnswer } = props;

  return (
    <StyledRiskAppetiteCard>
      <StyledBody>{riskArea.question}</StyledBody>
      <StyledFooter>
        <StyledAnswer $answer={riskArea.answer}>
          <AnswerButton
            isSelected={riskArea.answer === true}
            onClick={() => onAnswer(true)}
            isGenerated={!!riskArea.isAnswerGenerated}
            ariaLabel="Yes"
          >
            Yes
          </AnswerButton>
          {riskArea.answer === null && <StyledDivider />}
          <AnswerButton
            isSelected={riskArea.answer === false}
            onClick={() => onAnswer(false)}
            isGenerated={!!riskArea.isAnswerGenerated}
            ariaLabel="No"
          >
            No
          </AnswerButton>
          {riskArea.answer === null && <Icon className="ml-2" name="warning" />}
          {riskArea.isAnswerGenerated && (
            <Tooltip
              id="explanation-suggestion-tooltip"
              content={riskArea.suggestionForExplanation}
              place="bottom-start"
            />
          )}
        </StyledAnswer>
        {riskArea.answer !== null && <Thumb isPositive={riskArea.answer === false} />}
      </StyledFooter>
    </StyledRiskAppetiteCard>
  );
};

const StyledRiskAppetiteCard = styled(Card)`
  height: 100%;

  .card__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const StyledBody = styled.div`
  flex: 1;
  padding-bottom: var(--pf-padding-2);
`;

const StyledFooter = styled.div`
  border-top: 1px solid var(--pf-border-color);
  padding-top: var(--pf-padding-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledAnswer = styled.div<{ $answer: RiskAnswer }>`
  display: flex;
  align-items: center;

  button {
    &,
    &:hover {
      border: 1px solid var(--pf-border-color-light);
    }
  }

  button:first-child {
    border-right: none;
    border-radius: 0;
    border-top-left-radius: var(--pf-rounded);
    border-bottom-left-radius: var(--pf-rounded);
  }

  button:not(:first-child) {
    border-left: none;
    border-radius: 0;
    border-top-right-radius: var(--pf-rounded);
    border-bottom-right-radius: var(--pf-rounded);
  }
`;

const StyledDivider = styled.div`
  width: 1px;
  height: 28px;
  background: var(--pf-border-color-light);
`;
