import styled from 'styled-components';
import { Col, Icon, Row } from '@indico-data/design-system';
import { graphql } from 'gql';
import { formatToReadableUSDateTime } from 'utils/dateUtils';
import { DocumentInfo_DocumentFragment } from 'gql/graphql';

graphql(`
  fragment DocumentInfo_Document on Document {
    uuid
    ingestedAt
    fileName
  }
`);

type Props = {
  document: DocumentInfo_DocumentFragment;
};

export function DocumentInfo({ document }: Props) {
  const { ingestedAt, fileName } = document;

  return (
    <StyledDocumentInfo>
      <Row gutterWidth={20}>
        <Col>
          <Row align="center" gutterWidth={12}>
            <Col xs="content">
              <Icon name="document" size="xl" />
            </Col>
            <Col>
              <div className="truncate-wrapper">
                <h3 className="truncate-clip">{fileName}</h3>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="content">
          <p>
            <StyledInfoLabel>Received:</StyledInfoLabel>{' '}
            <StyledInfoValue>{formatToReadableUSDateTime(new Date(ingestedAt))}</StyledInfoValue>
          </p>
        </Col>
      </Row>
    </StyledDocumentInfo>
  );
}

const StyledDocumentInfo = styled.div`
  border-bottom: var(--pf-border-sm) solid var(--pf-border-color);
  padding: var(--pf-padding-4) var(--pf-padding-5);

  // TODO -- Move to design system as a wrapper component
  .truncate-wrapper {
    display: flex;
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-clip {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;

const StyledInfoLabel = styled.p`
  font-size: var(--pf-font-size-base);
  font-weight: var(--pf-font-weight-bold);
  color: var(--pf-font-color-light-header);
`;

const StyledInfoValue = styled.span`
  color: var(--pf-font-color-emphasis);
`;
