import { MutableRefObject } from 'react';
import { ScrollPosition } from 'store/documentViewer/types';
import { VERTICAL_SCROLL_PADDING } from './constants';

export const getCurrentScrollPosition = <T extends HTMLElement>(
  ref: MutableRefObject<T>,
): ScrollPosition | null => {
  if (ref.current.scrollHeight - ref.current.scrollTop - ref.current.clientHeight < 1) {
    return 'bottom';
  }

  if (ref.current.scrollTop === 0) {
    return 'top';
  }

  return null;
};

export const scrollContainerToPosition = <T extends HTMLElement>(
  ref: MutableRefObject<T>,
  scrollPosition: ScrollPosition,
  currentPageNumber: number,
) => {
  if (scrollPosition === 'top') {
    ref.current.scrollTo({
      top: ref.current.scrollHeight - ref.current.clientHeight - VERTICAL_SCROLL_PADDING / 2,
      left: 0,
    });
  } else {
    ref.current.scrollTo({
      top: currentPageNumber === 1 ? 0 : VERTICAL_SCROLL_PADDING / 2,
      left: 0,
    });
  }
};
