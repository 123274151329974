import { Col } from '@indico-data/design-system';
import { DataType } from 'gql/graphql';
import styled from 'styled-components';
import { FieldColWidthSize } from '../tabs/items/submission/helpers';
import { getResponsiveInsuredColWidthByFieldType } from './helpers';

type Props = {
  title: string;
  value?: string;
  dataType: DataType;
};

export const InsuredBlockField = ({ title, value, dataType }: Props) => {
  return (
    <Col
      key={title}
      sm={getResponsiveInsuredColWidthByFieldType(FieldColWidthSize.SM, dataType)}
      lg={getResponsiveInsuredColWidthByFieldType(FieldColWidthSize.LG, dataType)}
      xl={getResponsiveInsuredColWidthByFieldType(FieldColWidthSize.XL, dataType)}
    >
      <h3>{title}</h3>
      <StyledValue>{value}</StyledValue>
    </Col>
  );
};

const StyledValue = styled.p`
  white-space: pre-wrap;
`;
