import styled from 'styled-components';

interface ProgressBarProps {
  progressValue: number;
  $minWidth?: string;
}

const StyledProgressBar = styled.div<{ $minWidth?: string }>`
  .progress-bar {
    &__track {
      min-width: ${({ $minWidth }) => $minWidth || '100%'};
      max-width: 400px;
      background-color: var(--progress-bar-track-color);
      border: solid 1px var(--progress-bar-border-color);
      border-radius: var(--pf-rounded-pill);
      overflow: hidden;
      position: relative;
      display: flex;
      margin-left: var(--pf-margin-2);
      margin-right: var(--pf-margin-2);
    }

    &__fill {
      background-color: var(--progress-bar-color);
      width: 0;
      transition: width 0.5s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 15px;
    }
  }
`;

export function ProgressBar(props: ProgressBarProps) {
  const { progressValue, $minWidth } = props;

  return (
    <StyledProgressBar $minWidth={$minWidth}>
      <div className="progress-bar">
        <div className="progress-bar__track">
          <div className="progress-bar__fill" style={{ width: `${progressValue}%` }} />
        </div>
      </div>
    </StyledProgressBar>
  );
}
