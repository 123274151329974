import { PropsWithChildren } from 'react';
import { devtoolsExchange } from '@urql/devtools';
import * as CLEARANCE_MUTATIONS from 'pages/clearance-app/mutations';
import * as CONFIGURE_FIELDS_MUTATIONS from 'pages/configure-fields/gql';
import { Document, Field, FieldValueLink, InboxEvent, Submission } from 'gql/graphql';
import { UpdateFieldMutationVariables } from 'pages/configure-fields/gql';
import { Client, Provider, fetchExchange } from 'urql';
import { cacheExchange as urqlCacheExchange } from '@urql/exchange-graphcache';
import { buildUrlFromPath } from 'utils/urls';
import * as routes from '../constants/routes';

export const UrqlProvider = ({ children }: PropsWithChildren<{}>) => {
  const cacheExchange = urqlCacheExchange({
    keys: {
      Submission: (submission: Submission) => submission.uuid,
      Document: (document: Document) => document.uuid,
      InboxEvent: (inboxEvent: InboxEvent) => inboxEvent.uuid,
      Field: (field: Field) => field.vectorName,
      FieldValueLink: (fieldValueLink: FieldValueLink) => fieldValueLink.uuid,

      // EMBEDDED ENTITIES
      SimpleTypeConfig: () => null,
      CategoricalTypeConfig: () => null,
      SubmissionPage: () => null,
      ApplicationDefinition: () => null,
      FieldsNamespace: () => null,
      SuggestedInboxAction: () => null,
      InboxEventPage: () => null,
      LinkedField: () => null,
      SubmissionLinkedField: () => null,
    },
    optimistic: {
      updateField(args: UpdateFieldMutationVariables) {
        return {
          __typename: 'Field',
          id: args.fieldId,
          position: args.position,
          filterable: args.filterable,
          highlighted: args.highlighted,
        };
      },
    },
    updates: {
      Mutation: {
        updateField: CONFIGURE_FIELDS_MUTATIONS.updateField.updater,
        updateEvents: CLEARANCE_MUTATIONS.updateEvents.updater,
        createSubmission: CLEARANCE_MUTATIONS.createSubmission.updater,
        associateEventsWithSubmission: CLEARANCE_MUTATIONS.associateEventsWithSubmission.updater,
        updateSubmission: CLEARANCE_MUTATIONS.updateSubmission.updater,
        confirmSubmissionField: CLEARANCE_MUTATIONS.confirmSubmissionField.updater,
      },
    },
  });

  const client = new Client({
    url: buildUrlFromPath(routes.API),
    exchanges: [devtoolsExchange, cacheExchange, fetchExchange],
    fetchOptions: {
      credentials: 'include',
    },
  });

  return <Provider value={client}>{children}</Provider>;
};
