import { Cache, ResolveInfo, UpdateResolver } from '@urql/exchange-graphcache';
import { MutationAndUpdater } from 'pages/clearance-app/types';
import { gql } from 'urql';
import { GET_FIELDS } from './queries';

export type UpdateFieldMutationVariables = {
  fieldId: number;
  position?: number;
  filterable?: boolean;
  highlighted?: boolean;
};

export type UpdateFieldMutationData = {
  updateField: {
    id: string;
    position: number;
    filterable: boolean;
    highlighted: boolean;
    displayName: string;
  };
};

const UPDATE_FIELD = gql<UpdateFieldMutationData, UpdateFieldMutationVariables>`
  mutation updateField(
    $fieldId: Int!
    $position: Int
    $filterable: Boolean
    $highlighted: Boolean
  ) {
    updateField(
      fieldId: $fieldId
      position: $position
      filterable: $filterable
      highlighted: $highlighted
    ) {
      id
      position
      filterable
      highlighted
      displayName
      __typename
    }
  }
`;

const updateFieldUpdater: UpdateResolver = (
  _result,
  args: UpdateFieldMutationVariables,
  cache: Cache,
  _info: ResolveInfo,
) => {
  const { libraryUuid, limit, offset, prefix } = _info.variables;

  const highlightedCountIncrement = (() => {
    if (args.highlighted === undefined) {
      return 0;
    }

    return args.highlighted ? 1 : -1;
  })();

  cache.updateQuery(
    {
      query: GET_FIELDS,
      variables: {
        libraryUuid: libraryUuid as string,
        limit: limit as number,
        offset: offset as number,
        prefix: prefix as string,
      },
    },
    (data) => {
      if (!data) {
        return null;
      }

      return {
        ...data,
        fields: {
          ...data?.fields,
          highlightedCount: data.fields.highlightedCount + highlightedCountIncrement,
        },
      };
    },
  );
};

export const updateField: MutationAndUpdater = {
  mutation: UPDATE_FIELD,
  updater: updateFieldUpdater,
};
