// TODO: Move component to work queue dir
import { SUBMISSION_STATUS_TO_PILL_COLOR } from 'pages/clearance-app/pages/work-queue/constants';
import { SubmissionStatus } from 'gql/graphql';
import { formatStatus } from 'pages/clearance-app/helpers/string_formatting';
import { CategoricalCell } from '../core';

type Props = {
  status: SubmissionStatus;
};

export const SubmissionStatusCell = ({ status }: Props) => {
  return (
    <CategoricalCell
      isCentered
      value={formatStatus(status)}
      pillColor={SUBMISSION_STATUS_TO_PILL_COLOR[status]}
    />
  );
};
