import { InboxEventStatus } from 'gql/graphql';
import { useMutation } from 'urql';
import {
  associateEventsWithSubmission as associateEventsWithSubmissionMutation,
  createSubmission as createSubmissionMutation,
  updateEvents as updateEventsMutation,
} from 'pages/clearance-app/mutations';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import {
  getSubmissionCreatedMessage,
  getEventsAssociatedMessage,
  getEventsMarkedAsSpamMessage,
  getEventsDeclinedMessage,
  getErrorMessage,
} from './messages';
import { useInboxPageParams } from '../hooks/useInboxPageParams';

export const useCreateSubmission = () => {
  const [{ fetching }, createSubmission] = useMutation(createSubmissionMutation.mutation);
  const { setHighlightedInboxEventId } = useInboxPageParams();

  const action = useCallback(
    async (eventId: string) => {
      const response = await createSubmission({ eventId });

      const newSubmission = response.data?.createSubmission;
      if (newSubmission) {
        toast.success(getSubmissionCreatedMessage(newSubmission.uuid, newSubmission.displayId));
        setHighlightedInboxEventId(null);
      }

      if (response.error) {
        toast.error(getErrorMessage('Failed to create submission'));
      }
    },
    [createSubmission, setHighlightedInboxEventId],
  );

  return { fetching, action };
};

export const useAssociateEvents = () => {
  const [{ fetching }, associateEvents] = useMutation(
    associateEventsWithSubmissionMutation.mutation,
  );
  const { setHighlightedInboxEventId } = useInboxPageParams();

  const action = useCallback(
    async (submissionId: string, eventIds: string[]) => {
      const response = await associateEvents({ submissionId, eventIds });
      if (response.data?.associateEvents) {
        toast.success(
          getEventsAssociatedMessage(
            response.data.associateEvents.uuid,
            response.data.associateEvents.displayId,
            eventIds.length,
          ),
        );
        setHighlightedInboxEventId(null);
      }

      if (response.error) {
        toast.error(getErrorMessage('Failed to associate events'));
      }
    },
    [associateEvents, setHighlightedInboxEventId],
  );

  return { fetching, action };
};

export const useMarkEventsAsSpam = () => {
  const [{ fetching }, updateStatusOfEvents] = useMutation(updateEventsMutation.mutation);
  const { setHighlightedInboxEventId } = useInboxPageParams();
  const action = useCallback(
    async (eventIds: string[]) => {
      const response = await updateStatusOfEvents({ eventIds, status: InboxEventStatus.Spam });
      if (response.data?.updateEvents) {
        toast.success(getEventsMarkedAsSpamMessage(eventIds.length));
        setHighlightedInboxEventId(null);
      }

      if (response.error) {
        toast.error(getErrorMessage('Failed to mark events as spam'));
      }
    },
    [updateStatusOfEvents, setHighlightedInboxEventId],
  );

  return { fetching, action };
};

export const useDeclineEvents = () => {
  const [{ fetching }, updateStatusOfEvents] = useMutation(updateEventsMutation.mutation);
  const { setHighlightedInboxEventId } = useInboxPageParams();

  const action = useCallback(
    async (eventIds: string[]) => {
      const response = await updateStatusOfEvents({ eventIds, status: InboxEventStatus.Rejected });
      if (response.data?.updateEvents) {
        toast.success(getEventsDeclinedMessage(eventIds.length));
        setHighlightedInboxEventId(null);
      }

      if (response.error) {
        toast.error(getErrorMessage('Failed to decline events'));
      }
    },
    [updateStatusOfEvents, setHighlightedInboxEventId],
  );

  return { fetching, action };
};
