// eslint-disable-next-line import/no-extraneous-dependencies
import { TextSpan, MessageFeedItem, APIMessage, Citation } from './types';
import CitationButton from './components/feed/CitationButton';

// This function reads the start and end values from the citation object and extracts the text from the message into a citation string.
export function extractCitationText(text: string, citationCoordinates: TextSpan): string {
  const { start, end } = citationCoordinates;
  return text.substring(start, end);
}

// This grabs the message values returned from the API and formats them for consumption in our UI
export function formatMessages(messages: APIMessage[]): MessageFeedItem[] {
  return messages.map((message: APIMessage) => {
    return {
      id: message.messageId,
      isLoading: !message.finished,
      messageType: message.messageType,
      text: message.text,
      timestamp: message.createdAt,
      citations: message.citations.map((citation) => {
        return {
          confidence: citation.confidence,
          documentLocation: {
            fileUuid: citation.document.fileUuid,
            boundingBox: {
              top: citation.document.boundingBox.top,
              bottom: citation.document.boundingBox.bottom,
              left: citation.document.boundingBox.left,
              right: citation.document.boundingBox.right,
              pageNumber: citation.document.boundingBox.pageNumber + 1,
            },
          },
          responseLocation: citation.response,
          id: citation.id,
        };
      }),
    };
  });
}

const extractCitationNumber = (citationText: string) => citationText.slice(1, -1);

// This function replaces the citation number with a button link that forwards the value to the doc viewer
export const replaceCitationsWithButtons = (
  text: string,
  citations: Citation[],
  handleCitationClick: (citation: Citation) => void,
): (JSX.Element | string)[] => {
  let offset = 0;
  const elements: (JSX.Element | string)[] = [];

  citations
    .sort((c) => c.responseLocation.start)
    .forEach((citation) => {
      const { start, end } = citation.responseLocation;
      const citationText = text.substring(start, end);
      const citationNumber = extractCitationNumber(citationText);

      const textSpan = text.slice(offset, start);
      const button = (
        <CitationButton
          citation={citation}
          handleCitationClick={handleCitationClick}
          citationNumber={citationNumber}
        />
      );

      offset = end;
      elements.push(textSpan, button);
    });

  elements.push(text.slice(offset, text.length));

  return elements;
};

// This function returns the last system message that has finished loading from the chatMessages array
export const getLastSystemMessage = (chatMessages: MessageFeedItem[]) => {
  return chatMessages
    .filter(({ messageType, isLoading }) => messageType === 'ai' && !isLoading)
    .pop();
};

// This function copies the text from the last system message to the clipboard
export const copyLastSystemMessage = (systemMessages: MessageFeedItem[]) => {
  const lastSystemMessage = getLastSystemMessage(systemMessages);
  if (lastSystemMessage) {
    navigator.clipboard.writeText(lastSystemMessage.text);
  }
};
