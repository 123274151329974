/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment ClearanceTopBar_Submission on Submission {\n    uuid\n    status\n    igoPercentage\n    alerts\n    displayId\n  }\n": types.ClearanceTopBar_SubmissionFragmentDoc,
    "\n      query ClearanceTopBar_GetSubmission($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            ...ClearanceTopBar_Submission\n          }\n        }\n      }\n    ": types.ClearanceTopBar_GetSubmissionDocument,
    "\n  fragment ClearanceInsuredBlock_Submission on Submission {\n    createdAt\n    fields {\n      ...ClearanceInsuredBlock_SubmissionLinkedField\n    }\n  }\n\n  fragment ClearanceInsuredBlock_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n      vectorName\n      isWorkQueueColumn\n      typeConfig {\n        type\n      }\n    }\n    values {\n      uuid\n      value\n    }\n  }\n": types.ClearanceInsuredBlock_SubmissionFragmentDoc,
    "\n  fragment AskMySubmissionTab_Document on Document {\n    uuid\n  }\n": types.AskMySubmissionTab_DocumentFragmentDoc,
    "\n      query AskMySubmissionTab_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            events {\n              documents {\n                ...AskMySubmissionTab_Document\n              }\n            }\n          }\n        }\n      }\n    ": types.AskMySubmissionTab_GetDocumentsDocument,
    "\n  fragment DocumentItem_Document on Document {\n    uuid\n    fileName\n  }\n": types.DocumentItem_DocumentFragmentDoc,
    "\n      query DocumentsTab_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            events {\n              uuid\n              documents {\n                uuid\n                ...DocumentItem_Document\n              }\n            }\n          }\n        }\n      }\n    ": types.DocumentsTab_GetDocumentsDocument,
    "\n      query HistoryTab_GetAuditLog($submissionId: String!) {\n        logs(submissionUuid: $submissionId) {\n          id\n          ...LineItemCard_AuditLog\n        }\n      }\n    ": types.HistoryTab_GetAuditLogDocument,
    "\n  fragment LineItemCard_AuditLog on AuditLog {\n    message\n    timestamp\n  }\n": types.LineItemCard_AuditLogFragmentDoc,
    "\n  fragment SubmissionBooleanField_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n    }\n  }\n": types.SubmissionBooleanField_SubmissionLinkedFieldFragmentDoc,
    "\n  fragment SubmissionFieldEdit_LinkedFieldWithValues on SubmissionLinkedField {\n    field {\n      typeConfig {\n        type\n      }\n      displayName\n      vectorName\n      multi\n    }\n    values {\n      value\n      uuid\n      document {\n        uuid\n      }\n    }\n  }\n": types.SubmissionFieldEdit_LinkedFieldWithValuesFragmentDoc,
    "\n  fragment SubmissionTextField_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      typeConfig {\n        type\n      }\n      displayName\n      vectorName\n    }\n    ...SubmissionFieldHeader_SubmissionLinkedField\n    ...SubmissionFieldEdit_LinkedFieldWithValues\n  }\n": types.SubmissionTextField_SubmissionLinkedFieldFragmentDoc,
    "\n  fragment TextFieldInput_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n      multi\n    }\n  }\n": types.TextFieldInput_SubmissionLinkedFieldFragmentDoc,
    "\n  fragment SubmissionField_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      typeConfig {\n        type\n      }\n      vectorName\n      section\n    }\n    ...SubmissionTextField_SubmissionLinkedField\n    ...SubmissionBooleanField_SubmissionLinkedField\n  }\n": types.SubmissionField_SubmissionLinkedFieldFragmentDoc,
    "\n  fragment SubmissionFieldHeader_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      displayName\n      typeConfig {\n        type\n      }\n    }\n    ...SubmissionFieldHeaderIcon_SubmissionLinkedField\n  }\n": types.SubmissionFieldHeader_SubmissionLinkedFieldFragmentDoc,
    "\n  fragment SubmissionFieldHeaderIcon_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      required\n    }\n  }\n": types.SubmissionFieldHeaderIcon_SubmissionLinkedFieldFragmentDoc,
    "\n  fragment SubmissionSection_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      required\n      displayName\n      vectorName\n    }\n    ...SubmissionField_SubmissionLinkedField\n  }\n": types.SubmissionSection_SubmissionLinkedFieldFragmentDoc,
    "\n      query SubmissionSection_GetFields($submissionId: String!, $section: String = null) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            fields(sectionName: $section) {\n              ...SubmissionSection_SubmissionLinkedField\n            }\n          }\n        }\n      }\n    ": types.SubmissionSection_GetFieldsDocument,
    "\n      query SubmissionTab_GetSections {\n        application {\n          fields {\n            sections\n          }\n        }\n      }\n    ": types.SubmissionTab_GetSectionsDocument,
    "\n  fragment DocumentInfo_Document on Document {\n    uuid\n    ingestedAt\n    fileName\n  }\n": types.DocumentInfo_DocumentFragmentDoc,
    "\n  fragment EventsTable_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n    priority\n    riskAppetite\n    status\n    fields(onlyInboxColumns: true) {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n  fragment EventsTable_Field on Field {\n    ...FieldDrivenTable_FieldConfig\n  }\n": types.EventsTable_InboxEventFragmentDoc,
    "\n      query EventsTable_GetInboxEventsAndFields(\n        $limit: Int\n        $offset: Int\n        $sortByField: String\n        $sortAscending: Boolean\n        $filters: [Filter!]\n      ) {\n        events(\n          limit: $limit\n          offset: $offset\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          filters: $filters\n        ) {\n          items {\n            uuid\n            primaryDocument {\n              uuid\n              filesDigest(filenames: [\"original_page_0.png\"])\n            }\n            ...EventsTable_InboxEvent\n          }\n        }\n        application {\n          fields {\n            fields {\n              isInboxColumn\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    ": types.EventsTable_GetInboxEventsAndFieldsDocument,
    "\n  # Field configuration for determining how to display a column\n  fragment FieldDrivenTable_FieldConfig on Field {\n    displayName\n    vectorName\n    typeConfig {\n      type\n      ... on CategoricalTypeConfig {\n        options\n      }\n    }\n    multi\n  }\n\n  # Linked field with values\n  fragment FieldDrivenTable_LinkedFieldWithValues on BaseLinkedField {\n    values {\n      value\n      uuid\n    }\n    field {\n      ...FieldDrivenTable_FieldConfig\n    }\n  }\n": types.FieldDrivenTable_FieldConfigFragmentDoc,
    "\n  mutation UpdateStatusOfEvents($eventIds: [String!]!, $status: InboxEventStatus!) {\n    updateEvents(uuids: $eventIds, status: $status) {\n      items {\n        uuid\n        status\n      }\n    }\n  }\n": types.UpdateStatusOfEventsDocument,
    "\n  mutation CreateSubmission($eventId: String!) {\n    createSubmission(eventUuids: [$eventId]) {\n      uuid\n      displayId\n    }\n  }\n": types.CreateSubmissionDocument,
    "\n  mutation AssociateEventsWithSubmission($submissionId: String!, $eventIds: [String!]!) {\n    associateEvents(submissionUuid: $submissionId, uuids: $eventIds) {\n      uuid\n      displayId\n    }\n  }\n": types.AssociateEventsWithSubmissionDocument,
    "\n  mutation UpdateSubmission($submissionId: String!, $status: SubmissionStatus!) {\n    updateSubmissions(uuids: [$submissionId], status: $status) {\n      ...ClearancePage_Submission\n    }\n  }\n": types.UpdateSubmissionDocument,
    "\n  mutation ConfirmSubmissionField(\n    $fieldVectorName: String!\n    $submissionUuid: String!\n    $valueLinkUuids: [String!]!\n    $values: [FieldValue!]!\n  ) {\n    confirmSubmissionField(\n      fieldVectorName: $fieldVectorName\n      submissionUuid: $submissionUuid\n      valueLinkUuids: $valueLinkUuids\n      values: $values\n    ) {\n      ...SubmissionField_SubmissionLinkedField\n    }\n  }\n": types.ConfirmSubmissionFieldDocument,
    "\n    fragment SubmissionFields on Submission {\n      uuid\n      fields(sectionName: $sectionName) {\n        ...SubmissionField_SubmissionLinkedField\n      }\n    }\n  ": types.SubmissionFieldsFragmentDoc,
    "\n  fragment ClearancePage_Submission on Submission {\n    uuid\n    ...ClearanceInsuredBlock_Submission\n  }\n": types.ClearancePage_SubmissionFragmentDoc,
    "\n      query ClearancePage_GetSubmission($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            ...ClearancePage_Submission\n          }\n        }\n      }\n    ": types.ClearancePage_GetSubmissionDocument,
    "\n      query SubmissionDocument_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            events {\n              uuid\n              documents {\n                uuid\n                filesDigest(filenames: [\"_original\", \"etl_output.json\"])\n                ...DocumentInfo_Document\n              }\n            }\n          }\n        }\n      }\n    ": types.SubmissionDocument_GetDocumentsDocument,
    "\n  fragment EventActions_InboxEvent on InboxEvent {\n    uuid\n    ...SuggestedAction_InboxEvent\n  }\n": types.EventActions_InboxEventFragmentDoc,
    "\n  fragment SuggestedAction_InboxEvent on InboxEvent {\n    uuid\n    status\n    ...AssociateDetails_InboxEvent\n  }\n": types.SuggestedAction_InboxEventFragmentDoc,
    "\n      query SuggestedAction_GetSuggestedAction($eventUuid: String!) {\n        suggestInboxAction(eventUuid: $eventUuid) {\n          action\n          details {\n            ...SuggestedActionDetails_Details\n          }\n        }\n      }\n    ": types.SuggestedAction_GetSuggestedActionDocument,
    "\n  fragment SuggestedActionDetails_Details on SuggestedActionDetails {\n    __typename\n    ...AssociateDetails_Details\n    ...DeclineDetails_Details\n    ...SpamDetails_Details\n  }\n\n  fragment SuggestedActionDetails_InboxEvent on InboxEvent {\n    ...AssociateDetails_InboxEvent\n  }\n": types.SuggestedActionDetails_DetailsFragmentDoc,
    "\n  fragment AssociationModal_Submission on Submission {\n    uuid\n    displayId\n    createdAt\n    fields {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n": types.AssociationModal_SubmissionFragmentDoc,
    "\n      query AssociationModal_GetSubmissionsAndFields(\n        $limit: Int\n        $offset: Int\n        $sortByField: String\n        $sortAscending: Boolean\n        $filters: [Filter!]\n      ) {\n        submissions(\n          limit: $limit\n          offset: $offset\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          filters: $filters\n        ) {\n          items {\n            ...WorkQueue_Submission\n          }\n        }\n        application {\n          fields {\n            fields {\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    ": types.AssociationModal_GetSubmissionsAndFieldsDocument,
    "\n  fragment AssociateDetails_Details on SuggestedActionAssociateDetails {\n    submission {\n      uuid\n      displayId\n      createdAt\n    }\n    highlightedFields {\n      eventLinkedField {\n        field {\n          vectorName\n        }\n      }\n      ...FieldValueComparison_HighlightedField\n    }\n  }\n\n  fragment AssociateDetails_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n  }\n": types.AssociateDetails_DetailsFragmentDoc,
    "\n  fragment DeclineDetails_Details on SuggestedActionDeclineDetails {\n    reasons\n  }\n": types.DeclineDetails_DetailsFragmentDoc,
    "\n  fragment SpamDetails_Details on SuggestedActionSpamDetails {\n    reasons\n  }\n": types.SpamDetails_DetailsFragmentDoc,
    "\n  fragment FieldValueComparison_HighlightedField on HighlightedField {\n    matchesSubmission\n    submissionLinkedField {\n      field {\n        displayName\n      }\n      values {\n        value\n      }\n    }\n    eventLinkedField {\n      field {\n        displayName\n      }\n      values {\n        value\n      }\n    }\n  }\n": types.FieldValueComparison_HighlightedFieldFragmentDoc,
    "\n      query EventContent_GetInboxEvent($uuid: String!) {\n        events(uuids: [$uuid]) {\n          items {\n            ...EventInfo_InboxEvent\n          }\n        }\n      }\n    ": types.EventContent_GetInboxEventDocument,
    "\n  fragment AttachedDocuments_InboxEvent on InboxEvent {\n    fields(onlyInboxColumns: false) {\n      field {\n        displayName\n      }\n      values {\n        uuid\n        document {\n          uuid\n        }\n        value\n      }\n    }\n    documents {\n      uuid\n      fileName\n    }\n  }\n": types.AttachedDocuments_InboxEventFragmentDoc,
    "\n  fragment EventInfo_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n    fields(onlyInboxColumns: false) {\n      ...EventInfo_LinkedField\n    }\n    documents {\n      uuid\n      filesDigest(filenames: [\"_original\"])\n    }\n    primaryDocument {\n      uuid\n      filesDigest(filenames: [\"original_page_0.png\"])\n    }\n    ...EventActions_InboxEvent\n    ...AttachedDocuments_InboxEvent\n  }\n\n  fragment EventInfo_LinkedField on LinkedField {\n    field {\n      displayName\n    }\n    values {\n      value\n      document {\n        uuid\n      }\n    }\n  }\n": types.EventInfo_InboxEventFragmentDoc,
    "\n  fragment WorkQueue_Submission on Submission {\n    uuid\n    createdAt\n    displayId\n    riskAppetite\n    priority\n    status\n    fields(onlyQueueColumns: true) {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n": types.WorkQueue_SubmissionFragmentDoc,
    "\n      query WorkQueue_GetSubmissionsAndFields(\n        $sortByField: String\n        $sortAscending: Boolean\n        $limit: Int\n        $offset: Int\n        $filters: [Filter!]\n      ) {\n        submissions(\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          limit: $limit\n          offset: $offset\n          filters: $filters\n        ) {\n          items {\n            ...WorkQueue_Submission\n          }\n        }\n        application {\n          fields {\n            fields {\n              isWorkQueueColumn\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    ": types.WorkQueue_GetSubmissionsAndFieldsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ClearanceTopBar_Submission on Submission {\n    uuid\n    status\n    igoPercentage\n    alerts\n    displayId\n  }\n"): (typeof documents)["\n  fragment ClearanceTopBar_Submission on Submission {\n    uuid\n    status\n    igoPercentage\n    alerts\n    displayId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query ClearanceTopBar_GetSubmission($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            ...ClearanceTopBar_Submission\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ClearanceTopBar_GetSubmission($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            ...ClearanceTopBar_Submission\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ClearanceInsuredBlock_Submission on Submission {\n    createdAt\n    fields {\n      ...ClearanceInsuredBlock_SubmissionLinkedField\n    }\n  }\n\n  fragment ClearanceInsuredBlock_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n      vectorName\n      isWorkQueueColumn\n      typeConfig {\n        type\n      }\n    }\n    values {\n      uuid\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment ClearanceInsuredBlock_Submission on Submission {\n    createdAt\n    fields {\n      ...ClearanceInsuredBlock_SubmissionLinkedField\n    }\n  }\n\n  fragment ClearanceInsuredBlock_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n      vectorName\n      isWorkQueueColumn\n      typeConfig {\n        type\n      }\n    }\n    values {\n      uuid\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AskMySubmissionTab_Document on Document {\n    uuid\n  }\n"): (typeof documents)["\n  fragment AskMySubmissionTab_Document on Document {\n    uuid\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query AskMySubmissionTab_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            events {\n              documents {\n                ...AskMySubmissionTab_Document\n              }\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query AskMySubmissionTab_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            events {\n              documents {\n                ...AskMySubmissionTab_Document\n              }\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DocumentItem_Document on Document {\n    uuid\n    fileName\n  }\n"): (typeof documents)["\n  fragment DocumentItem_Document on Document {\n    uuid\n    fileName\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query DocumentsTab_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            events {\n              uuid\n              documents {\n                uuid\n                ...DocumentItem_Document\n              }\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query DocumentsTab_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            events {\n              uuid\n              documents {\n                uuid\n                ...DocumentItem_Document\n              }\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query HistoryTab_GetAuditLog($submissionId: String!) {\n        logs(submissionUuid: $submissionId) {\n          id\n          ...LineItemCard_AuditLog\n        }\n      }\n    "): (typeof documents)["\n      query HistoryTab_GetAuditLog($submissionId: String!) {\n        logs(submissionUuid: $submissionId) {\n          id\n          ...LineItemCard_AuditLog\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment LineItemCard_AuditLog on AuditLog {\n    message\n    timestamp\n  }\n"): (typeof documents)["\n  fragment LineItemCard_AuditLog on AuditLog {\n    message\n    timestamp\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SubmissionBooleanField_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n    }\n  }\n"): (typeof documents)["\n  fragment SubmissionBooleanField_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SubmissionFieldEdit_LinkedFieldWithValues on SubmissionLinkedField {\n    field {\n      typeConfig {\n        type\n      }\n      displayName\n      vectorName\n      multi\n    }\n    values {\n      value\n      uuid\n      document {\n        uuid\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SubmissionFieldEdit_LinkedFieldWithValues on SubmissionLinkedField {\n    field {\n      typeConfig {\n        type\n      }\n      displayName\n      vectorName\n      multi\n    }\n    values {\n      value\n      uuid\n      document {\n        uuid\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SubmissionTextField_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      typeConfig {\n        type\n      }\n      displayName\n      vectorName\n    }\n    ...SubmissionFieldHeader_SubmissionLinkedField\n    ...SubmissionFieldEdit_LinkedFieldWithValues\n  }\n"): (typeof documents)["\n  fragment SubmissionTextField_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      typeConfig {\n        type\n      }\n      displayName\n      vectorName\n    }\n    ...SubmissionFieldHeader_SubmissionLinkedField\n    ...SubmissionFieldEdit_LinkedFieldWithValues\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TextFieldInput_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n      multi\n    }\n  }\n"): (typeof documents)["\n  fragment TextFieldInput_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      displayName\n      multi\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SubmissionField_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      typeConfig {\n        type\n      }\n      vectorName\n      section\n    }\n    ...SubmissionTextField_SubmissionLinkedField\n    ...SubmissionBooleanField_SubmissionLinkedField\n  }\n"): (typeof documents)["\n  fragment SubmissionField_SubmissionLinkedField on SubmissionLinkedField {\n    field {\n      typeConfig {\n        type\n      }\n      vectorName\n      section\n    }\n    ...SubmissionTextField_SubmissionLinkedField\n    ...SubmissionBooleanField_SubmissionLinkedField\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SubmissionFieldHeader_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      displayName\n      typeConfig {\n        type\n      }\n    }\n    ...SubmissionFieldHeaderIcon_SubmissionLinkedField\n  }\n"): (typeof documents)["\n  fragment SubmissionFieldHeader_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      displayName\n      typeConfig {\n        type\n      }\n    }\n    ...SubmissionFieldHeaderIcon_SubmissionLinkedField\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SubmissionFieldHeaderIcon_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      required\n    }\n  }\n"): (typeof documents)["\n  fragment SubmissionFieldHeaderIcon_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      required\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SubmissionSection_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      required\n      displayName\n      vectorName\n    }\n    ...SubmissionField_SubmissionLinkedField\n  }\n"): (typeof documents)["\n  fragment SubmissionSection_SubmissionLinkedField on SubmissionLinkedField {\n    confirmed\n    field {\n      required\n      displayName\n      vectorName\n    }\n    ...SubmissionField_SubmissionLinkedField\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query SubmissionSection_GetFields($submissionId: String!, $section: String = null) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            fields(sectionName: $section) {\n              ...SubmissionSection_SubmissionLinkedField\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query SubmissionSection_GetFields($submissionId: String!, $section: String = null) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            fields(sectionName: $section) {\n              ...SubmissionSection_SubmissionLinkedField\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query SubmissionTab_GetSections {\n        application {\n          fields {\n            sections\n          }\n        }\n      }\n    "): (typeof documents)["\n      query SubmissionTab_GetSections {\n        application {\n          fields {\n            sections\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DocumentInfo_Document on Document {\n    uuid\n    ingestedAt\n    fileName\n  }\n"): (typeof documents)["\n  fragment DocumentInfo_Document on Document {\n    uuid\n    ingestedAt\n    fileName\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EventsTable_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n    priority\n    riskAppetite\n    status\n    fields(onlyInboxColumns: true) {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n  fragment EventsTable_Field on Field {\n    ...FieldDrivenTable_FieldConfig\n  }\n"): (typeof documents)["\n  fragment EventsTable_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n    priority\n    riskAppetite\n    status\n    fields(onlyInboxColumns: true) {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n  fragment EventsTable_Field on Field {\n    ...FieldDrivenTable_FieldConfig\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query EventsTable_GetInboxEventsAndFields(\n        $limit: Int\n        $offset: Int\n        $sortByField: String\n        $sortAscending: Boolean\n        $filters: [Filter!]\n      ) {\n        events(\n          limit: $limit\n          offset: $offset\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          filters: $filters\n        ) {\n          items {\n            uuid\n            primaryDocument {\n              uuid\n              filesDigest(filenames: [\"original_page_0.png\"])\n            }\n            ...EventsTable_InboxEvent\n          }\n        }\n        application {\n          fields {\n            fields {\n              isInboxColumn\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query EventsTable_GetInboxEventsAndFields(\n        $limit: Int\n        $offset: Int\n        $sortByField: String\n        $sortAscending: Boolean\n        $filters: [Filter!]\n      ) {\n        events(\n          limit: $limit\n          offset: $offset\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          filters: $filters\n        ) {\n          items {\n            uuid\n            primaryDocument {\n              uuid\n              filesDigest(filenames: [\"original_page_0.png\"])\n            }\n            ...EventsTable_InboxEvent\n          }\n        }\n        application {\n          fields {\n            fields {\n              isInboxColumn\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  # Field configuration for determining how to display a column\n  fragment FieldDrivenTable_FieldConfig on Field {\n    displayName\n    vectorName\n    typeConfig {\n      type\n      ... on CategoricalTypeConfig {\n        options\n      }\n    }\n    multi\n  }\n\n  # Linked field with values\n  fragment FieldDrivenTable_LinkedFieldWithValues on BaseLinkedField {\n    values {\n      value\n      uuid\n    }\n    field {\n      ...FieldDrivenTable_FieldConfig\n    }\n  }\n"): (typeof documents)["\n  # Field configuration for determining how to display a column\n  fragment FieldDrivenTable_FieldConfig on Field {\n    displayName\n    vectorName\n    typeConfig {\n      type\n      ... on CategoricalTypeConfig {\n        options\n      }\n    }\n    multi\n  }\n\n  # Linked field with values\n  fragment FieldDrivenTable_LinkedFieldWithValues on BaseLinkedField {\n    values {\n      value\n      uuid\n    }\n    field {\n      ...FieldDrivenTable_FieldConfig\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateStatusOfEvents($eventIds: [String!]!, $status: InboxEventStatus!) {\n    updateEvents(uuids: $eventIds, status: $status) {\n      items {\n        uuid\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateStatusOfEvents($eventIds: [String!]!, $status: InboxEventStatus!) {\n    updateEvents(uuids: $eventIds, status: $status) {\n      items {\n        uuid\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSubmission($eventId: String!) {\n    createSubmission(eventUuids: [$eventId]) {\n      uuid\n      displayId\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSubmission($eventId: String!) {\n    createSubmission(eventUuids: [$eventId]) {\n      uuid\n      displayId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AssociateEventsWithSubmission($submissionId: String!, $eventIds: [String!]!) {\n    associateEvents(submissionUuid: $submissionId, uuids: $eventIds) {\n      uuid\n      displayId\n    }\n  }\n"): (typeof documents)["\n  mutation AssociateEventsWithSubmission($submissionId: String!, $eventIds: [String!]!) {\n    associateEvents(submissionUuid: $submissionId, uuids: $eventIds) {\n      uuid\n      displayId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSubmission($submissionId: String!, $status: SubmissionStatus!) {\n    updateSubmissions(uuids: [$submissionId], status: $status) {\n      ...ClearancePage_Submission\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSubmission($submissionId: String!, $status: SubmissionStatus!) {\n    updateSubmissions(uuids: [$submissionId], status: $status) {\n      ...ClearancePage_Submission\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ConfirmSubmissionField(\n    $fieldVectorName: String!\n    $submissionUuid: String!\n    $valueLinkUuids: [String!]!\n    $values: [FieldValue!]!\n  ) {\n    confirmSubmissionField(\n      fieldVectorName: $fieldVectorName\n      submissionUuid: $submissionUuid\n      valueLinkUuids: $valueLinkUuids\n      values: $values\n    ) {\n      ...SubmissionField_SubmissionLinkedField\n    }\n  }\n"): (typeof documents)["\n  mutation ConfirmSubmissionField(\n    $fieldVectorName: String!\n    $submissionUuid: String!\n    $valueLinkUuids: [String!]!\n    $values: [FieldValue!]!\n  ) {\n    confirmSubmissionField(\n      fieldVectorName: $fieldVectorName\n      submissionUuid: $submissionUuid\n      valueLinkUuids: $valueLinkUuids\n      values: $values\n    ) {\n      ...SubmissionField_SubmissionLinkedField\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment SubmissionFields on Submission {\n      uuid\n      fields(sectionName: $sectionName) {\n        ...SubmissionField_SubmissionLinkedField\n      }\n    }\n  "): (typeof documents)["\n    fragment SubmissionFields on Submission {\n      uuid\n      fields(sectionName: $sectionName) {\n        ...SubmissionField_SubmissionLinkedField\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ClearancePage_Submission on Submission {\n    uuid\n    ...ClearanceInsuredBlock_Submission\n  }\n"): (typeof documents)["\n  fragment ClearancePage_Submission on Submission {\n    uuid\n    ...ClearanceInsuredBlock_Submission\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query ClearancePage_GetSubmission($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            ...ClearancePage_Submission\n          }\n        }\n      }\n    "): (typeof documents)["\n      query ClearancePage_GetSubmission($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            ...ClearancePage_Submission\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query SubmissionDocument_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            events {\n              uuid\n              documents {\n                uuid\n                filesDigest(filenames: [\"_original\", \"etl_output.json\"])\n                ...DocumentInfo_Document\n              }\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query SubmissionDocument_GetDocuments($submissionId: String!) {\n        submissions(uuids: [$submissionId]) {\n          items {\n            uuid\n            events {\n              uuid\n              documents {\n                uuid\n                filesDigest(filenames: [\"_original\", \"etl_output.json\"])\n                ...DocumentInfo_Document\n              }\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EventActions_InboxEvent on InboxEvent {\n    uuid\n    ...SuggestedAction_InboxEvent\n  }\n"): (typeof documents)["\n  fragment EventActions_InboxEvent on InboxEvent {\n    uuid\n    ...SuggestedAction_InboxEvent\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SuggestedAction_InboxEvent on InboxEvent {\n    uuid\n    status\n    ...AssociateDetails_InboxEvent\n  }\n"): (typeof documents)["\n  fragment SuggestedAction_InboxEvent on InboxEvent {\n    uuid\n    status\n    ...AssociateDetails_InboxEvent\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query SuggestedAction_GetSuggestedAction($eventUuid: String!) {\n        suggestInboxAction(eventUuid: $eventUuid) {\n          action\n          details {\n            ...SuggestedActionDetails_Details\n          }\n        }\n      }\n    "): (typeof documents)["\n      query SuggestedAction_GetSuggestedAction($eventUuid: String!) {\n        suggestInboxAction(eventUuid: $eventUuid) {\n          action\n          details {\n            ...SuggestedActionDetails_Details\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SuggestedActionDetails_Details on SuggestedActionDetails {\n    __typename\n    ...AssociateDetails_Details\n    ...DeclineDetails_Details\n    ...SpamDetails_Details\n  }\n\n  fragment SuggestedActionDetails_InboxEvent on InboxEvent {\n    ...AssociateDetails_InboxEvent\n  }\n"): (typeof documents)["\n  fragment SuggestedActionDetails_Details on SuggestedActionDetails {\n    __typename\n    ...AssociateDetails_Details\n    ...DeclineDetails_Details\n    ...SpamDetails_Details\n  }\n\n  fragment SuggestedActionDetails_InboxEvent on InboxEvent {\n    ...AssociateDetails_InboxEvent\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AssociationModal_Submission on Submission {\n    uuid\n    displayId\n    createdAt\n    fields {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n"): (typeof documents)["\n  fragment AssociationModal_Submission on Submission {\n    uuid\n    displayId\n    createdAt\n    fields {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query AssociationModal_GetSubmissionsAndFields(\n        $limit: Int\n        $offset: Int\n        $sortByField: String\n        $sortAscending: Boolean\n        $filters: [Filter!]\n      ) {\n        submissions(\n          limit: $limit\n          offset: $offset\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          filters: $filters\n        ) {\n          items {\n            ...WorkQueue_Submission\n          }\n        }\n        application {\n          fields {\n            fields {\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query AssociationModal_GetSubmissionsAndFields(\n        $limit: Int\n        $offset: Int\n        $sortByField: String\n        $sortAscending: Boolean\n        $filters: [Filter!]\n      ) {\n        submissions(\n          limit: $limit\n          offset: $offset\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          filters: $filters\n        ) {\n          items {\n            ...WorkQueue_Submission\n          }\n        }\n        application {\n          fields {\n            fields {\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AssociateDetails_Details on SuggestedActionAssociateDetails {\n    submission {\n      uuid\n      displayId\n      createdAt\n    }\n    highlightedFields {\n      eventLinkedField {\n        field {\n          vectorName\n        }\n      }\n      ...FieldValueComparison_HighlightedField\n    }\n  }\n\n  fragment AssociateDetails_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n  }\n"): (typeof documents)["\n  fragment AssociateDetails_Details on SuggestedActionAssociateDetails {\n    submission {\n      uuid\n      displayId\n      createdAt\n    }\n    highlightedFields {\n      eventLinkedField {\n        field {\n          vectorName\n        }\n      }\n      ...FieldValueComparison_HighlightedField\n    }\n  }\n\n  fragment AssociateDetails_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DeclineDetails_Details on SuggestedActionDeclineDetails {\n    reasons\n  }\n"): (typeof documents)["\n  fragment DeclineDetails_Details on SuggestedActionDeclineDetails {\n    reasons\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SpamDetails_Details on SuggestedActionSpamDetails {\n    reasons\n  }\n"): (typeof documents)["\n  fragment SpamDetails_Details on SuggestedActionSpamDetails {\n    reasons\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FieldValueComparison_HighlightedField on HighlightedField {\n    matchesSubmission\n    submissionLinkedField {\n      field {\n        displayName\n      }\n      values {\n        value\n      }\n    }\n    eventLinkedField {\n      field {\n        displayName\n      }\n      values {\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment FieldValueComparison_HighlightedField on HighlightedField {\n    matchesSubmission\n    submissionLinkedField {\n      field {\n        displayName\n      }\n      values {\n        value\n      }\n    }\n    eventLinkedField {\n      field {\n        displayName\n      }\n      values {\n        value\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query EventContent_GetInboxEvent($uuid: String!) {\n        events(uuids: [$uuid]) {\n          items {\n            ...EventInfo_InboxEvent\n          }\n        }\n      }\n    "): (typeof documents)["\n      query EventContent_GetInboxEvent($uuid: String!) {\n        events(uuids: [$uuid]) {\n          items {\n            ...EventInfo_InboxEvent\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AttachedDocuments_InboxEvent on InboxEvent {\n    fields(onlyInboxColumns: false) {\n      field {\n        displayName\n      }\n      values {\n        uuid\n        document {\n          uuid\n        }\n        value\n      }\n    }\n    documents {\n      uuid\n      fileName\n    }\n  }\n"): (typeof documents)["\n  fragment AttachedDocuments_InboxEvent on InboxEvent {\n    fields(onlyInboxColumns: false) {\n      field {\n        displayName\n      }\n      values {\n        uuid\n        document {\n          uuid\n        }\n        value\n      }\n    }\n    documents {\n      uuid\n      fileName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EventInfo_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n    fields(onlyInboxColumns: false) {\n      ...EventInfo_LinkedField\n    }\n    documents {\n      uuid\n      filesDigest(filenames: [\"_original\"])\n    }\n    primaryDocument {\n      uuid\n      filesDigest(filenames: [\"original_page_0.png\"])\n    }\n    ...EventActions_InboxEvent\n    ...AttachedDocuments_InboxEvent\n  }\n\n  fragment EventInfo_LinkedField on LinkedField {\n    field {\n      displayName\n    }\n    values {\n      value\n      document {\n        uuid\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment EventInfo_InboxEvent on InboxEvent {\n    uuid\n    createdAt\n    fields(onlyInboxColumns: false) {\n      ...EventInfo_LinkedField\n    }\n    documents {\n      uuid\n      filesDigest(filenames: [\"_original\"])\n    }\n    primaryDocument {\n      uuid\n      filesDigest(filenames: [\"original_page_0.png\"])\n    }\n    ...EventActions_InboxEvent\n    ...AttachedDocuments_InboxEvent\n  }\n\n  fragment EventInfo_LinkedField on LinkedField {\n    field {\n      displayName\n    }\n    values {\n      value\n      document {\n        uuid\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment WorkQueue_Submission on Submission {\n    uuid\n    createdAt\n    displayId\n    riskAppetite\n    priority\n    status\n    fields(onlyQueueColumns: true) {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n"): (typeof documents)["\n  fragment WorkQueue_Submission on Submission {\n    uuid\n    createdAt\n    displayId\n    riskAppetite\n    priority\n    status\n    fields(onlyQueueColumns: true) {\n      ...FieldDrivenTable_LinkedFieldWithValues\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query WorkQueue_GetSubmissionsAndFields(\n        $sortByField: String\n        $sortAscending: Boolean\n        $limit: Int\n        $offset: Int\n        $filters: [Filter!]\n      ) {\n        submissions(\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          limit: $limit\n          offset: $offset\n          filters: $filters\n        ) {\n          items {\n            ...WorkQueue_Submission\n          }\n        }\n        application {\n          fields {\n            fields {\n              isWorkQueueColumn\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    "): (typeof documents)["\n      query WorkQueue_GetSubmissionsAndFields(\n        $sortByField: String\n        $sortAscending: Boolean\n        $limit: Int\n        $offset: Int\n        $filters: [Filter!]\n      ) {\n        submissions(\n          sortByField: $sortByField\n          sortAscending: $sortAscending\n          limit: $limit\n          offset: $offset\n          filters: $filters\n        ) {\n          items {\n            ...WorkQueue_Submission\n          }\n        }\n        application {\n          fields {\n            fields {\n              isWorkQueueColumn\n              ...FieldDrivenTable_FieldConfig\n            }\n          }\n        }\n      }\n    "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;