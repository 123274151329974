import React from "react";
import { nanoid } from 'nanoid';
import { Button, Form, Textarea } from '@indico-data/design-system';
import styled from 'styled-components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MessageFeedItem } from '../types';

interface UserChatActionsProps {
  onUserSendMessage: (message: MessageFeedItem) => boolean;
  allowSend: boolean;
}

export default function UserChatActions({ onUserSendMessage, allowSend }: UserChatActionsProps) {
  const onSubmit: SubmitHandler<Record<string, any>> = async (formData: any) => {
    const message: MessageFeedItem = {
      text: formData['user-chat-input'],
      timestamp: new Date().toISOString(),
      messageType: 'user',
      citations: [],
      isLoading: true,
      id: nanoid(),
    };
    // onUserSendMessage returns true if the message is successfully sent, false otherwise
    if (onUserSendMessage(message)) {
      reset();
    }
  };

  const { register, handleSubmit, reset } = useForm<Record<string, any>>({ mode: 'onChange' });

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const modKey = e.ctrlKey || e.metaKey || e.shiftKey;
    if (e.key === 'Enter' && !modKey) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  }

  return (
    <StyledChatInput>
      <Form onSubmit={handleSubmit(onSubmit) as any}>
        <Textarea
          label="Type your message"
          rows={3}
          hasHiddenLabel
          // TODO -- update design system prop to accept this
          // @ts-ignore
          onKeyDown={onKeyDown}
          {...register('user-chat-input')}
        />
        <Button type="submit" iconName="faPaperPlane" ariaLabel="send" isDisabled={!allowSend}>
          Send
        </Button>
      </Form>
    </StyledChatInput>
  );
}

const StyledChatInput = styled.div`
  margin-top: var(--pf-margin-5);
`;
