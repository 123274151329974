import { FieldTypeEnum } from 'types';
import { FieldsOnLibraryQueryData } from './queries';

export const FIELDS_LIST_QUERY_MOCK_RESPONSE: { data: FieldsOnLibraryQueryData } = {
  data: {
    fields: {
      aggregateCount: 1,
      highlightedCount: 0,
      items: [
        {
          id: 2,
          position: 3,
          displayName: 'lorem_Field_Name_1',
          filterable: true,
          highlighted: false,
          name: 'lorem_Field_Name_199b027dc45e39a9af5bd543bdab581ac4c3bbbd7',
          type: FieldTypeEnum.TEXT,
        },
      ],
    },
  },
};
