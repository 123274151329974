import { Get } from './types';

export const currentPageAspectRatio = (get: Get) => {
  return () => {
    const { currentPage } = get();
    const page = currentPage();
    return page ? page.dimensions.width / page.dimensions.height : 0;
  };
};

export const currentPage = (get: Get) => {
  return () => {
    const { pages, currentPageNumber } = get();

    return pages.length ? pages[currentPageNumber - 1] : null;
  };
};

export const currentPageCitation = (get: Get) => {
  return () => {
    const { currentPageNumber, citation } = get();
    if (citation?.pageNumber === currentPageNumber) {
      return citation;
    }
    return null;
  };
};
