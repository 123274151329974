import { gql } from 'urql';
import { Field } from '../types';

export type FieldsOnLibraryQueryData = {
  fields: {
    aggregateCount: number;
    highlightedCount: number;
    items: Field[];
  };
};

export type FieldsOnLibraryQueryVariables = {
  libraryUuid: string;
  limit: number;
  offset: number;
  prefix?: string;
};

export const GET_FIELDS = gql<FieldsOnLibraryQueryData, FieldsOnLibraryQueryVariables>`
  query getFieldsOnLibrary($libraryUuid: String!, $limit: Int!, $offset: Int!, $prefix: String) {
    fields(
      libraryUuid: $libraryUuid
      limit: $limit
      offset: $offset
      prefix: $prefix
      onlyFilterable: false
      onlyHighlighted: false
    ) {
      aggregateCount
      highlightedCount
      items {
        id
        position
        filterable
        highlighted
        vectorName
        displayName
        type
        __typename
      }
    }
  }
`;
