import { Pill } from '@indico-data/design-system';
import { PillColor } from '@indico-data/design-system/src/types';
import { textTruncationWithEllipsis } from 'utils/styleUtils';
import styled from 'styled-components';

const StyledPill = styled(Pill)`
  ${textTruncationWithEllipsis};
`;

type Props = {
  value: any;
  children?: React.ReactNode;
  pillColor?: PillColor;
  isCentered?: boolean;
};

export const CategoricalCell = ({
  value,
  children,
  pillColor = 'info',
  isCentered = false,
}: Props) => {
  return (
    <StyledCategoricalCell data-tag="allowRowEvents" $isCentered={isCentered}>
      {value && (
        <StyledPill data-tag="allowRowEvents" color={pillColor} className="mr-1 ">
          {value}
        </StyledPill>
      )}
      <span className="text-decoration--underline">{children}</span>
    </StyledCategoricalCell>
  );
};

const StyledCategoricalCell = styled.div<{ $isCentered: boolean }>`
  display: flex;
  justify-content: ${({ $isCentered }) => ($isCentered ? 'center' : 'flex-start')};
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;
