import styled, { css } from 'styled-components';

export const StyledColumnHeader = styled.div<{ $isOpen?: boolean }>`
  white-space: nowrap;
  padding: var(--pf-padding-4);
  display: flex;
  align-items: center;
  position: relative;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      /* Fix for lightmode */
      background: var(--pf-primary-color-400);
    `}
`;
