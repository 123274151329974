import { create } from 'zustand';
import { MIN_ZOOM_FACTOR } from 'pages/clearance-app/components/document/constants';
import { InitState, Set, Store } from './types';
import {
  changePageAction,
  documentPageLoadingAction,
  pagesAction,
  incrementZoomFactorAction,
  zoomFactorAction,
  incrementPageAction,
  decrementPageAction,
  scrollPositionAction,
  citationAction,
} from './actions';
import { currentPage, currentPageAspectRatio, currentPageCitation } from './getters';

// TODO: Initialize state at component-render time with fetched pages
export const initialState: InitState = {
  currentPageNumber: 1,
  zoomFactor: MIN_ZOOM_FACTOR,
  pages: [],
  documentPageLoading: false,
  scrollPosition: 'top',
  citation: null,
};

const resetUIStateAction = (set: Set) => {
  return () => {
    set(() => ({
      ...initialState,
    }));
  };
};

export const useDocumentViewerStore = create<Store>((set, get) => ({
  ...initialState,
  resetUIState: resetUIStateAction(set),
  changePage: changePageAction(set, get),
  incrementPage: incrementPageAction(get),
  decrementPage: decrementPageAction(get),
  incrementZoomFactor: incrementZoomFactorAction(set, get),
  setZoomFactor: zoomFactorAction(set),
  setDocumentPageLoading: documentPageLoadingAction(set),
  setPages: pagesAction(set),
  setScrollPosition: scrollPositionAction(set),
  setCitation: citationAction(set, get),
  currentPageAspectRatio: currentPageAspectRatio(get),
  currentPage: currentPage(get),
  currentPageCitation: currentPageCitation(get),
}));
