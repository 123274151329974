import styled from 'styled-components';
import { Button, Col, Row } from '@indico-data/design-system';

type Props = {
  isDisabledZoomIn: boolean;
  isDisabledZoomOut: boolean;
  onZoomIn(): void;
  onZoomOut(): void;
  zoomPercent: string;
};

export function ZoomControls(props: Props) {
  const { isDisabledZoomIn, isDisabledZoomOut, zoomPercent, onZoomIn, onZoomOut } = props;

  return (
    <StyledZoomControls>
      <Row align="center">
        <Col>
          <Button
            onClick={onZoomOut}
            isDisabled={isDisabledZoomOut}
            ariaLabel="zoom out"
            iconName="zoom-out"
          />
        </Col>
        <Col xs="content">
          <StyledZoomPercent>{zoomPercent}</StyledZoomPercent>
        </Col>
        <Col>
          <Button
            onClick={onZoomIn}
            isDisabled={isDisabledZoomIn}
            ariaLabel="zoom in"
            iconName="zoom-in"
          />
        </Col>
      </Row>
    </StyledZoomControls>
  );
}

const StyledZoomControls = styled.div`
  border: var(--pf-border-sm) solid var(--pf-border-color);
  border-radius: var(--pf-rounded);
  display: flex;
  align-items: center;
`;

const StyledZoomPercent = styled.p`
  font-weight: var(--pf-font-weight-bold);
  font-size: var(--pf-font-size-h3);
`;
