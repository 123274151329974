import { useState } from 'react';
import { useFieldTableParams } from 'pages/clearance-app/components/tables/FieldsDrivenTable/hooks/useFieldTableParams';
import { EventsTable_InboxEventFragment } from 'gql/graphql';
import { Button, Col, Row } from '@indico-data/design-system';
import { EventsTable } from 'pages/clearance-app/pages/inbox/components/EventsTable/EventsTable';
import styled from 'styled-components';
import { EventContent } from './components/EventContent/EventContent';
import { useInboxPageParams } from './hooks/useInboxPageParams';
import { INBOX_TABLE_KEY } from './constants';
import { DEFAULT_INBOX_FILTERS } from './components/EventActions/constants';
import { BulkActions } from './components/BulkActions/BulkActions';

export default function InboxPage() {
  const { initFilters } = useFieldTableParams({
    defaultSortByField: '_creationTimeUnix',
    defaultFilters: DEFAULT_INBOX_FILTERS,
    tableKey: INBOX_TABLE_KEY,
  });

  const { highlightedInboxEventId, setHighlightedInboxEventId } = useInboxPageParams();
  const [selectedInboxEventIds, setSelectedInboxEventIds] = useState<string[]>([]);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [isEventContentOpen, setIsEventContentOpen] = useState(false);

  const handleRowClicked = (inboxEvent: EventsTable_InboxEventFragment) => {
    setHighlightedInboxEventId(inboxEvent.uuid);
    setIsEventContentOpen(true);
  };

  const handleBulkAction = () => {
    setSelectedInboxEventIds([]);
    setToggleClearRows(!toggleClearRows);
  };

  const isRightPanelOpen = isEventContentOpen && highlightedInboxEventId;

  return (
    <StyledPage className="pa-5">
      <StyledHeader>
        <StyledHeaderContent>
          <h1>Inbox</h1>
          <Button
            variant="outline"
            color="secondary"
            onClick={initFilters}
            ariaLabel="Reset filters"
          >
            Reset filters
          </Button>
        </StyledHeaderContent>
        {!isRightPanelOpen && (
          <Button
            iconName="fa-angle-double-left"
            ariaLabel="Open Panel"
            variant="outline"
            color="secondary"
            isDisabled={!highlightedInboxEventId}
            onClick={() => setIsEventContentOpen(true)}
          />
        )}
      </StyledHeader>
      <StyledInbox>
        <StyledInboxRow gutterWidth={15} direction="row">
          <StyledCol
            xs={isRightPanelOpen ? 5 : 12}
            xl={isRightPanelOpen ? 6 : 12}
            xxl={isRightPanelOpen ? 7 : 12}
          >
            <EventsTable
              toggleClearRows={toggleClearRows}
              selectedInboxEventIds={selectedInboxEventIds}
              onInboxEventClick={handleRowClicked}
              onSelectedInboxEventsChange={setSelectedInboxEventIds}
            />
            <BulkActions
              isHidden={!selectedInboxEventIds.length}
              selectedInboxEventIds={selectedInboxEventIds}
              handleBulkAction={handleBulkAction}
            />
          </StyledCol>
          {isRightPanelOpen && (
            <Col xs={7} xl={6} xxl={5}>
              <EventContent
                highlightedInboxEventId={highlightedInboxEventId}
                onClosePanel={() => setIsEventContentOpen(false)}
              />
            </Col>
          )}
        </StyledInboxRow>
      </StyledInbox>
    </StyledPage>
  );
}

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--pf-margin-2);
  margin-bottom: var(--pf-margin-5);
`;

const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: var(--pf-size-2);
`;

const StyledInbox = styled.div`
  flex: 1;
  margin-bottom: var(--pf-negative-margin-5);
`;

const StyledInboxRow = styled(Row)`
  height: 100%;
`;

const StyledCol = styled(Col)`
  position: relative;
`;
