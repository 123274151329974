import { Filter, Operator, SubmissionStatus } from 'gql/graphql';

export const ASSOCIATION_MODAL_TABLE_KEY = 'association';
export const ASSOCIATION_MODAL_FIELD_NAMES = ['Named Insured', 'Mailing Address'];
export const DEFAULT_ASSOCIATION_MODAL_FILTERS: Filter[] = [
  {
    field: 'status',
    op: Operator.Equal,
    values: [SubmissionStatus.Incomplete, SubmissionStatus.QuoteReady],
  },
];
