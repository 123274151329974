import { Pill } from '@indico-data/design-system';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { CategoricalCell } from './CategoricalCell';

type Value = { id: string; value: string };

type Props = {
  values: Value[];
};

export const MultiCategoricalCell = ({ values }: Props) => {
  return (
    <CategoricalCell value={values[0].value}>
      {values.length > 1 && (
        <>
          <Tooltip id={`${values[0].id}-tooltip`}>
            {values.slice(1, 11).map(({ value, id }) => (
              <Pill key={id} color="info" className="mx-1 mb-1">
                {value}
              </Pill>
            ))}
          </Tooltip>
          <StyledPlusNumber
            data-tag="allowRowEvents"
            data-tooltip-id={`${values[0].id}-tooltip`}
            aria-label="More values"
          >
            +{values.length - 1}
          </StyledPlusNumber>
        </>
      )}
    </CategoricalCell>
  );
};

const StyledPlusNumber = styled.span`
  font-size: var(--pf-font-size-body2);
  color: var(--pf-font-color);
  white-space: nowrap;
`;
