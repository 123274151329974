import { useQuery } from 'urql';
import { graphql } from 'gql';
import { INBOX_COLUMNS, INBOX_TABLE_KEY } from 'pages/clearance-app/pages/inbox/constants';
import { DEFAULT_INBOX_FILTERS } from 'pages/clearance-app/pages/inbox/components/EventActions/constants';
import { useInboxPageParams } from 'pages/clearance-app/pages/inbox/hooks/useInboxPageParams';
import { inboxFixedColumns } from 'pages/clearance-app/pages/inbox/inbox_page_fixed_columns';
import {
  EventsTable_GetInboxEventsAndFieldsQuery,
  EventsTable_InboxEventFragment,
  FieldDrivenTable_FieldConfigFragment,
  QueryEventsArgs,
} from 'gql/graphql';
import { FieldDrivenTable } from '../../../../components/tables/FieldsDrivenTable';
import { ITEMS_PER_PAGE } from '../../../../components/tables/FieldsDrivenTable/constants';
import { TableLoading } from '../../../../../../components/Loading/TableLoading';
import { useFieldTableParams } from '../../../../components/tables/FieldsDrivenTable/hooks/useFieldTableParams';
import { ColumnDefinition } from '../../../../components/tables/FieldsDrivenTable/types';

type Props = {
  onInboxEventClick: (inboxEvent: EventsTable_InboxEventFragment) => void;
  selectedInboxEventIds: string[];
  onSelectedInboxEventsChange: (selectedInboxEventIds: string[]) => void;
  toggleClearRows: boolean;
};

// TODO: Make this its own query
graphql(`
  fragment EventsTable_InboxEvent on InboxEvent {
    uuid
    createdAt
    priority
    riskAppetite
    status
    fields(onlyInboxColumns: true) {
      ...FieldDrivenTable_LinkedFieldWithValues
    }
  }
  fragment EventsTable_Field on Field {
    ...FieldDrivenTable_FieldConfig
  }
`);

export const EventsTable = ({
  onInboxEventClick,
  selectedInboxEventIds,
  onSelectedInboxEventsChange,
  toggleClearRows,
}: Props) => {
  const { highlightedInboxEventId: openInboxEventId } = useInboxPageParams();

  const { filters, sortByField, sortAscending } = useFieldTableParams({
    defaultSortByField: '_creationTimeUnix',
    defaultFilters: DEFAULT_INBOX_FILTERS,
    tableKey: INBOX_TABLE_KEY,
  });

  const [{ data, fetching }] = useQuery<EventsTable_GetInboxEventsAndFieldsQuery, QueryEventsArgs>({
    query: graphql(`
      query EventsTable_GetInboxEventsAndFields(
        $limit: Int
        $offset: Int
        $sortByField: String
        $sortAscending: Boolean
        $filters: [Filter!]
      ) {
        events(
          limit: $limit
          offset: $offset
          sortByField: $sortByField
          sortAscending: $sortAscending
          filters: $filters
        ) {
          items {
            uuid
            primaryDocument {
              uuid
              filesDigest(filenames: ["original_page_0.png"])
            }
            ...EventsTable_InboxEvent
          }
        }
        application {
          fields {
            fields {
              isInboxColumn
              ...FieldDrivenTable_FieldConfig
            }
          }
        }
      }
    `),
    variables: {
      sortByField,
      sortAscending,
      filters,
      // TODO: Should be settable in field table
      limit: ITEMS_PER_PAGE,
    },
    requestPolicy: 'cache-and-network',
  });

  const fieldFromRowSelector = (
    event: EventsTable_InboxEventFragment,
    fieldConfig: FieldDrivenTable_FieldConfigFragment,
  ) => {
    return event.fields.find((field) => field.field.displayName === fieldConfig.displayName);
  };

  const inboxEvents = data?.events?.items ?? [];
  const inboxFieldConfigs = data?.application?.fields?.fields.filter(
    (field) => field.isInboxColumn,
  );

  // Temporarily hardcoding inbox columns and their ordering
  const columnDefinitions: ColumnDefinition<EventsTable_InboxEventFragment>[] = inboxFieldConfigs
    ? INBOX_COLUMNS.map((column) => {
        const fixedColumnCandidate = inboxFixedColumns.find(
          (fixedColumn) => fixedColumn.id === column,
        );

        if (fixedColumnCandidate) {
          return fixedColumnCandidate;
        }

        const fieldConfigCandidate = inboxFieldConfigs.find(
          (field) => field.displayName === column,
        );

        if (fieldConfigCandidate) {
          return fieldConfigCandidate;
        }

        throw new Error(`No fixed column or field config found for ${column}`);
      })
    : [];

  return (
    <FieldDrivenTable
      defaultFilters={DEFAULT_INBOX_FILTERS}
      keyField="uuid"
      columnDefinitions={columnDefinitions}
      conditionalRowStyles={[
        {
          when: (event) => event.uuid === openInboxEventId,
          classNames: ['highlighted'],
        },
        {
          when: (event) =>
            !!selectedInboxEventIds.find((selectedEventId) => selectedEventId === event.uuid),
          classNames: ['checked'],
        },
      ]}
      fieldFromRowSelector={fieldFromRowSelector}
      data={inboxEvents}
      pagination
      paginationServer
      progressComponent={<TableLoading message="Fetching Table Data" />}
      paginationPerPage={25}
      fixedHeaderScrollHeight="calc(100vh - 154px)"
      pointerOnHover
      isLoading={fetching}
      onRowClicked={onInboxEventClick}
      defaultSortFieldId="from"
      selectableRows
      onSelectedRowsChange={({ selectedRows }) =>
        onSelectedInboxEventsChange(selectedRows.map((row) => row.uuid))
      }
      clearSelectedRows={toggleClearRows}
      tableKey={INBOX_TABLE_KEY}
    />
  );
};
