import classNames from 'classnames';
import { useDocumentViewerStore } from 'store/documentViewer/documentViewerStore';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { buildUrlFromPath } from 'utils/urls';
import { Page } from 'store/documentViewer/types';
import 'swiper/css';
import { Button, Col, Icon, Row } from '@indico-data/design-system';

interface ThumbnailProps {
  isVisible: boolean;
  toggleThumbnails: () => void;
}

export const Thumbnails = ({
  isVisible,
  toggleThumbnails,
}: ThumbnailProps) => {
  const { pages, currentPageNumber, changePage, documentPageLoading } = useDocumentViewerStore();

  return (
    <StyledThumbnails $isVisible={isVisible}>
      <Row>
        <Col xs={2}>
          <StyledLeftThumbnailAction>
            <Button
              className="swiper-button-prev"
              iconName="chevron-left"
              size="lg"
              ariaLabel="Previous List Of Thumbnails"
            />
          </StyledLeftThumbnailAction>
        </Col>
        <Col xs={8}>
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={10}
            slidesPerView={5}
            slidesPerGroup={5}
            freeMode
            className="thumbnail-slider"
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            {pages &&
              pages.map((page: Page, index: number) => {
                return (
                  <SwiperSlide key={`thumbnail-${page.thumbnailPath}`}>
                    <StyledThumbnailWrapper>
                      <StyledThumnailPagination>{index + 1}</StyledThumnailPagination>
                      <StyledThumbnail
                        className={classNames('thumbnail', {
                          selected: index + 1 === currentPageNumber,
                          disabled: documentPageLoading,
                        })}
                        onClick={() => {
                          if (!documentPageLoading) {
                            changePage(index + 1);
                          }
                        }}
                      >
                        {page.thumbnailPath && (
                          <img
                            src={buildUrlFromPath(page.thumbnailPath)}
                            alt={`page-${index + 1}`}
                            loading="lazy"
                          />
                        )}
                      </StyledThumbnail>
                    </StyledThumbnailWrapper>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </Col>
        <Col xs={2}>
          <StyledRightThumbnailAction>
            <Button
              className="swiper-button-next"
              iconName="chevron-right"
              size="lg"
              ariaLabel="Next List Of Thumbnails"
            />
          </StyledRightThumbnailAction>
        </Col>
      </Row>
      <StyledHideThumbnailsButton onClick={toggleThumbnails}>
        {isVisible ? (
          <StyledToggleThumbnailsWrapper>
            <p className="text-caption">Hide Pages</p>
            <Icon className="ml-2" name="chevron-down" size="sm" />
          </StyledToggleThumbnailsWrapper>
        ) : (
          <StyledToggleThumbnailsWrapper>
            <p className="text-caption">Show Pages</p>
            <Icon className="ml-2" name="chevron-up" size="sm" />
          </StyledToggleThumbnailsWrapper>
        )}
      </StyledHideThumbnailsButton>
    </StyledThumbnails>
  );
};

const StyledThumbnails = styled.div<{ $isVisible: boolean }>`
  position: relative;
  background-color: var(--pf-card-background-color);
  width: 100%;
  padding: ${({ $isVisible }) => ($isVisible ? 'var(--pf-padding-2)' : '0')};
  height: ${({ $isVisible }) => ($isVisible ? '150px' : '0')};

  .thumbnail-slider {
    max-width: 600px;
  }
`;

const StyledThumbnailWrapper = styled.div`
  position: relative;
`;

const StyledThumnailPagination = styled.div`
  position: absolute;
  top: 2px;
  right: 15px;
  height: 25px;
  width: 25px;
  background-color: var(--pf-background-color-dark);
  color: var(--pf-text-color);
  border-radius: var(--pf-rounded-md);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLeftThumbnailAction = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
`;

const StyledRightThumbnailAction = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
`;

const StyledThumbnail = styled.li`
  display: inline-block;
  min-width: 100px;
  max-width: 100px;

  cursor: pointer;
  img {
    border-radius: var(--pf-rounded);
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.selected {
    img {
      border: var(--pf-border-lg) solid var(--pf-secondary-color);
    }
  }
`;

const StyledHideThumbnailsButton = styled.div`
  background: var(--pf-primary-color);
  position: absolute;
  top: 0px;
  right: 30px;
  display: flex;
  margin-top: -34px;
  justify-content: center;
  color: var(--pf-text-color);
  align-items: center;
  padding: var(--pf-padding-2);
  cursor: pointer;
  border-top-left-radius: var(--pf-rounded-md);
  border-top-right-radius: var(--pf-rounded-md);
  width: 140px;
`;

const StyledToggleThumbnailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
