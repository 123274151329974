import { graphql } from 'gql';
import * as routes from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { useClearancePageParams } from 'pages/clearance-app/pages/clearance/hooks/useClearancePageParams';
import { useQuery } from 'urql';
import { SubmissionAlert } from 'pages/clearance-app/types';
import styled from 'styled-components';
import { textTruncationWithEllipsis } from 'utils/styleUtils';
import { useUpdateSubmissionStatus } from 'pages/clearance-app/pages/clearance/actions';
import { SubmissionStatus } from 'gql/graphql';
import { SUBMISSION_STATUS_TO_PILL_COLOR } from 'pages/clearance-app/pages/work-queue/constants';
import { formatStatus } from 'pages/clearance-app/helpers/string_formatting';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { Button, Col, Pill, Row, Skeleton } from '@indico-data/design-system';
import KabobMenu from '../../shared/kabobMenu/KabobMenu';
import { KabobMenuItem } from '../types';
import { ProgressBar as SubmissionCompletionProgressBar } from '../../shared/progressBar/ProgressBar';

graphql(`
  fragment ClearanceTopBar_Submission on Submission {
    uuid
    status
    igoPercentage
    alerts
    displayId
  }
`);

export default function ClearanceHeader() {
  const { submissionId } = useClearancePageParams();

  const navigate = useNavigate();

  const [{ data: submissionData, fetching }] = useQuery({
    query: graphql(`
      query ClearanceTopBar_GetSubmission($submissionId: String!) {
        submissions(uuids: [$submissionId]) {
          items {
            uuid
            ...ClearanceTopBar_Submission
          }
        }
      }
    `),
    variables: { submissionId: submissionId || '' },
  });

  const { action: updateSubmissionStatus } = useUpdateSubmissionStatus();
  const [loadingElementId, setLoadingElementId] = useState<string | null>(null);
  const [isKabobMenuOpen, setIsKabobMenuOpen] = useState(false);

  if (fetching || !submissionData) {
    return <Skeleton height={36} width={200} className="mx-6" />;
  }

  const submission = submissionData.submissions.items[0];

  const clearSubmission = async () => {
    await updateSubmissionStatus(submission.uuid, SubmissionStatus.Cleared);
    navigate(`${routes.CLEARANCE_APP}/work-queue`);
  };

  const handleKabobMenuItemClick = async (menuItemId: string) => {
    setLoadingElementId(menuItemId);

    if (menuItemId === 'send-to-guidewire' || menuItemId === 'send-to-underwriter') {
      await clearSubmission();
    }

    setLoadingElementId(null);
    setIsKabobMenuOpen(false);
  };

  const handleSendToGuidewireButtonClick = async () => {
    setLoadingElementId('send-to-guidewire-button');
    await clearSubmission();
    setLoadingElementId(null);
  };

  const handleDecline = async () => {
    setLoadingElementId('decline-button');
    await updateSubmissionStatus(submission.uuid, SubmissionStatus.Declined);
    setLoadingElementId(null);
  };

  const submissionActionItems: KabobMenuItem[] = [
    {
      label: 'Send to Underwriter',
      id: 'send-to-underwriter',
      isLoading: loadingElementId === 'send-to-underwriter',
    },
    {
      label: 'Send to Guidewire',
      id: 'send-to-guidewire',
      isLoading: loadingElementId === 'send-to-guidewire',
    },
  ];

  return (
    <StyledClearanceTopBar>
      <Row align="center">
        <Col md={3}>
          <StyledSubmissionIdHeader>
            <StyledHeading className="mr-5">{submission.displayId}</StyledHeading>
            <Pill
              color={SUBMISSION_STATUS_TO_PILL_COLOR[submission.status]}
              size="md"
              data-tooltip-id="status-tooltip"
            >
              {formatStatus(submission.status)}
            </Pill>
            <Tooltip id="status-tooltip" className="tooltipArea">
              <h4>Remaining Items</h4>
              <StyledAlertList>
                {submission.alerts.map((alert: SubmissionAlert) => (
                  <li key={alert.field_vector_name}>{alert.message}</li>
                ))}
              </StyledAlertList>
            </Tooltip>
          </StyledSubmissionIdHeader>
        </Col>
        <Col md={9}>
          <StyledActionsHeader>
            <StyledProgressBar>
              <p>Submission Completion</p>
              <SubmissionCompletionProgressBar
                $minWidth="300px"
                progressValue={submission.igoPercentage}
              />
              <p>{submission.igoPercentage}%</p>
            </StyledProgressBar>
            <Button
              onClick={handleSendToGuidewireButtonClick}
              color="secondary"
              ariaLabel="send to guidewire"
              className="mr-2"
              iconName="fa-envelope"
              data-testid="send-to-guidewire-button"
              variant="outline"
              isLoading={loadingElementId === 'send-to-guidewire-button'}
            >
              Send To Guidewire
            </Button>
            <Button
              onClick={handleDecline}
              color="secondary"
              ariaLabel="decline"
              className="mr-2"
              iconName="reject"
              variant="outline"
              data-testid="decline-button"
              isLoading={loadingElementId === 'decline-button'}
            >
              Decline
            </Button>
            <KabobMenu
              items={submissionActionItems}
              onMenuSelect={handleKabobMenuItemClick}
              isOpen={isKabobMenuOpen}
              setIsOpen={setIsKabobMenuOpen}
              minWidth={215}
            />
          </StyledActionsHeader>
        </Col>
      </Row>
    </StyledClearanceTopBar>
  );
}
const StyledClearanceTopBar = styled.div`
  box-sizing: border-box;
  padding-left: var(--pf-padding-5);
  padding-right: var(--pf-padding-4);
`;

const StyledProgressBar = styled.div`
  display: flex;
  align-items: center;
  margin-right: var(--pf-margin-8);
`;

const StyledActionsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledSubmissionIdHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.h1`
  ${textTruncationWithEllipsis}
`;

const StyledAlertList = styled.ul`
  list-style: disc;
  padding: var(--pf-padding-2) var(--pf-padding-4);
`;
