import { DataType } from 'gql/graphql';
import { PillColor } from '@indico-data/design-system/src/types';
import SubmissionBooleanField from './SubmissionSection/FieldTypeComponents/boolean/SubmissionBooleanField';
import SubmissionTextField from './SubmissionSection/FieldTypeComponents/text/SubmissionTextField';
import { SectionStatus } from './types';

export const submissionFieldComponentMap: Record<DataType, React.FC<any>> = {
  [DataType.Boolean]: SubmissionBooleanField,
  [DataType.Text]: SubmissionTextField,
  [DataType.Address]: SubmissionTextField,
  [DataType.BigText]: SubmissionTextField,
  [DataType.Categorical]: SubmissionTextField,
  [DataType.Composite]: SubmissionTextField,
  [DataType.Number]: SubmissionTextField,
  [DataType.Currency]: SubmissionTextField,
  [DataType.Datetime]: SubmissionTextField,
  [DataType.Name]: SubmissionTextField,
  [DataType.Summary]: SubmissionTextField,
};

export const sectionStatusToColorMap: Record<SectionStatus, PillColor> = {
  [SectionStatus.Incomplete]: 'warning',
  [SectionStatus.Complete]: 'success',
};
