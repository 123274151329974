import styled from 'styled-components';

export const StyledLoginPage = styled.div`
  height: 100vh;
  background-color: var(--pf-background-color-dark);

  .auth__container {
    max-width: 440px;
    border: solid 1px var(--pf-border-color);
    border-radius: 4px;
    padding: 30px;
    margin: 60px auto auto;
    background-color: rgba(92, 112, 140, 0.1);

    hr {
      border: solid 1px var(--pf-horizontal-line-color);
      margin: 0;
    }
    .auth__logo__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .auth__logo {
        display: flex;
        align-items: center;
        max-width: 220px;
        width: 100%;
        border-right: solid 1px var(--pf-border-color);
        margin-right: 20px;

        .auth__logo__text {
          font-family: 'Poppins', sans-serif;
          margin: 0 0 0 10px;
          font-size: 20px;
          font-weight: 500;
        }
      } // Auth Logo

      .auth__signin__text {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 400;
      }
    } // Auth Logo Wrapper

    .auth__corp__signin {
      font-family: 'Poppins', sans-serif;
      margin-bottom: 20px;
      padding: 10px;
      width: 100%;

      .no__decoration {
        text-decoration: none;
      }

      .auth__corp__signin__button {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    } // Auth Signin

    .auth__actions {
      padding: 10px;

      .auth__email__signin__button {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
