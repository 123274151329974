import { useState, useEffect } from 'react';
import { graphql } from 'gql';
import { useQueryParam, NumberParam } from 'use-query-params';

import { Button, Card, Col, Form, Row, SelectInput } from '@indico-data/design-system';
import { ClearancePageTabProps } from 'pages/clearance-app/pages/clearance/ClearancePage';
import styled from 'styled-components';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useDocumentViewerStore } from 'store/documentViewer/documentViewerStore';
import AskMySubmissionFeed from './components/feed/AskMySubmissionFeed';
import UserChatActions from './components/UserChatActions';
import { MessageFeedItem, ConversationMeta, ConversationHistoryItem } from './types';
import { copyLastSystemMessage, formatMessages } from './helpers';
import {
  getNewConversation,
  getConversation,
  getConversationsList,
  getSummaryEventSource,
  useDocuments,
} from './requests';

const DEFAULT_MESSAGE: ConversationHistoryItem = { value: null, label: 'New Chat', chatId: null };

graphql(`
  fragment AskMySubmissionTab_Document on Document {
    uuid
  }
`);

export default function AskMySubmissionTab({ submissionId }: ClearancePageTabProps) {
  const [chatMessages, setChatMessages] = useState<MessageFeedItem[]>([]);
  const [chatIdRaw, setChatId] = useQueryParam<number | null | undefined>(
    'selectedAMSId',
    NumberParam,
  );
  // Use Query Params will output undefined when value is unset. Normalize this to null for typing consistency
  const chatId: number | null = chatIdRaw == null ? null : chatIdRaw;
  const [streamingEventSource, setStreamingEventSource] = useState<EventSource | null>(null);
  const [rawConversationList, setConversationList] = useState<ConversationHistoryItem[]>([]);
  const { register, handleSubmit, setValue } = useForm<Record<string, any>>({ mode: 'onChange' });

  const { documents, fetching: documentsLoading } = useDocuments(submissionId);
  const documentUuids = documents?.map((document) => document.uuid);

  // If the current conversation does not have an ID yet, we add the default_message to the top.
  const conversationList: ConversationHistoryItem[] = [DEFAULT_MESSAGE, ...rawConversationList];
  const selectedConversation = conversationList.find((c) => c.chatId === chatId);

  const busy = documentsLoading || (chatMessages.length > 0 && chatMessages.at(-1)?.isLoading);
  const allowSend = !busy;

  const { setCitation } = useDocumentViewerStore();

  function clearCitation() {
    setCitation(null);
  }

  useEffect(() => {
    return clearCitation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateConvList = () => {
    getConversationsList(submissionId).then((data) => {
      setConversationList(
        data.conversations.map((c: ConversationMeta): ConversationHistoryItem => {
          return {
            label: c.title,
            value: c.conversationId,
            chatId: c.conversationId,
          };
        }),
      );
    });
  };

  const loadConversation = (id: number | null) => {
    if (id === null) {
      clearCitation();
      setChatMessages([]);
      return;
    }
    getConversation(id).then((data) => setChatMessages(formatMessages(data.messages)));
    if (streamingEventSource !== null) {
      streamingEventSource.close();
    }
  };

  useEffect(updateConvList, [submissionId]);

  // Re-run only on first mount to resume previous state.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadConversation(chatId), []);

  const handleStartNewConversation = () => {
    clearCitation();
    setChatId(null);
    setChatMessages([]);
    if (streamingEventSource !== null) {
      streamingEventSource.close();
    }
  };

  const handleSelectConversation = (selectedOption: any) => {
    setValue('select-conversation', selectedOption);
    handleSubmit(onSubmitSelectConversation)();
  };

  const onSubmitSelectConversation: SubmitHandler<Record<string, any>> = async (formData: any) => {
    const id = formData['select-conversation'].chatId;
    if (chatId === id) {
      // If you've tried to switch to the same conversation. Do nothing.
      return;
    }
    clearCitation();
    setChatId(id);
    loadConversation(id);
  };

  const setupStreamForNewMessage = (conversationId: number, messageText: string) => {
    const eventSource = getSummaryEventSource(conversationId, messageText);
    setStreamingEventSource(eventSource);
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.ignore) {
        return;
      }
      setChatMessages(formatMessages(data?.messages));
      // Delay setting the chatId until the first message has come through from the backend.
      setChatId(conversationId);
      updateConvList();
    };

    eventSource.onerror = () => {
      eventSource.close();
      // TODO: some kind of error handling
    };
  };

  const handleMessage = (message: MessageFeedItem) => {
    // Returns a boolean indicating whether the message was sent.
    if (!allowSend || message.text.trim() === '' || !documentUuids) {
      return false;
    }
    setChatMessages([...chatMessages, message]);
    if (chatId === null) {
      getNewConversation(submissionId, documentUuids).then((data) => {
        setupStreamForNewMessage(data.conversationId, message.text);
      });
    } else {
      setupStreamForNewMessage(chatId, message.text);
    }
    return true;
  };

  return (
    <StyledAskMySubmissionCard>
      <StyledHeaderActions>
        <Row justify="end" gutterWidth={10} align="center">
          <Col md={3}>
            <Form onSubmit={handleSubmit(onSubmitSelectConversation) as any}>
              <SelectInput
                value={selectedConversation}
                options={conversationList}
                {...register('select-conversation')}
                onChange={handleSelectConversation}
              />
            </Form>
          </Col>
          <Col md="content">
            <StyledCircleButton
              onClick={handleStartNewConversation}
              color="secondary"
              ariaLabel="Add"
              iconName="plus"
            />
          </Col>
        </Row>
      </StyledHeaderActions>

      <StyledBody>
        <Row>
          <Col xs={12}>
            <AskMySubmissionFeed messages={chatMessages} />
          </Col>
          <Col xs={12}>
            <Button
              iconName="faCopy"
              onClick={() => copyLastSystemMessage(chatMessages)}
              ariaLabel="copy"
            >
              Copy Text
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <UserChatActions onUserSendMessage={handleMessage} allowSend={allowSend} />
          </Col>
        </Row>
      </StyledBody>
    </StyledAskMySubmissionCard>
  );
}

const StyledAskMySubmissionCard = styled(Card)`
  background-color: var(--pf-background-color-dark);
`;

// TODO: Move to design system as an option
const StyledCircleButton = styled(Button)`
  border-radius: var(--pf-rounded-circle);
`;

const StyledHeaderActions = styled.div`
  border-bottom: var(--pf-border-sm) solid var(--pf-border-color);
  padding-bottom: var(--pf-padding-3);
  margin-bottom: var(--pf-margin-5);
`;

const StyledBody = styled.div`
  padding: var(--pf-padding-5);
`;
