import * as routes from 'constants/routes';
import { ListItem } from 'components/Navigation/Sidebar/DrawerLinkList';
import { Insights } from 'providers/Insights';
import { ReactNode } from 'react';

export const ClearanceApp = ({ children }: { children: ReactNode }) => {
  const navigationItems: ListItem[] = [
    {
      id: '1',
      icon: 'fa-envelope',
      label: 'Inbox',
      path: `${routes.CLEARANCE_APP}/inbox`,
    },
    {
      id: '2',
      icon: 'fa-list-dots',
      label: 'Work Queue',
      path: `${routes.CLEARANCE_APP}/work-queue`,
    },
  ];

  return <Insights navigationItems={navigationItems}>{children}</Insights>;
};
