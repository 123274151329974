import styled from 'styled-components';
import { useClearancePageParams } from '../hooks/useClearancePageParams';
import { SubmissionDocument } from './SubmissionDocument';
import { SubmissionDocumentContainer } from './SubmissionDocumentContainer';

export const SubmissionDocumentPanel = () => {
  const { submissionId, selectedDocId } = useClearancePageParams();

  if (!selectedDocId || !submissionId) {
    return (
      <SubmissionDocumentContainer>
        <StyledNoDocumentSelected>Please select a document to view.</StyledNoDocumentSelected>
      </SubmissionDocumentContainer>
    );
  }

  return (
    <SubmissionDocumentContainer>
      <SubmissionDocument submissionId={submissionId} documentId={selectedDocId} />
    </SubmissionDocumentContainer>
  );
};

const StyledNoDocumentSelected = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: var(--pf-font-size-h2);
  color: var(--pf-text-color-light);
`;
