import { useEffect } from 'react';
import { useAuth } from 'hooks';

// TODO: Deprecate component; instead, always use useAuth().logout directly
export default function Logout() {
  const { logout, authUser } = useAuth();

  useEffect(() => {
    if (authUser) {
      logout();
    }
  }, [authUser, logout]);

  return null;
}
