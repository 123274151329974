import { FloatUI, Icon, offset } from '@indico-data/design-system';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FieldColumnHeaderForm } from './FieldColumnHeaderForm';
import { StyledColumnHeader } from '../StyledColumnHeader';
import { ColumnConfig } from '../../types';
import { useFieldDrivenTableState } from '../../FieldDrivenTableContext';

type Props = {
  fieldConfig: ColumnConfig;
};

export const FieldColumnHeader = ({ fieldConfig }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const headerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  const { sortByField, sortAscending, filters } = useFieldDrivenTableState();

  const isSortedField = sortByField === fieldConfig.vectorName;
  const appliedFilter = filters?.find((filter) => filter.field === fieldConfig.vectorName) ?? null;

  const handleHeaderClick = () => {
    setIsOpen(!isOpen);
  };

  // Close the dropdown when the table body is horizontally scrolled
  // TODO: Once the filter can scroll with the table body, we can remove this
  useEffect(() => {
    const table = document.querySelector('.table > div');
    let lastScrollLeft = 0;
    table?.addEventListener('scroll', (e) => {
      if (e.target instanceof HTMLElement && e.target.scrollLeft !== lastScrollLeft) {
        lastScrollLeft = e.target.scrollLeft;
        setIsOpen(false);
      }
    });

    return () => {
      table?.removeEventListener('scroll', () => {});
    };
  }, []);

  return (
    <StyledFloatUI
      ariaLabel={fieldConfig.displayName}
      floatingOptions={{
        placement: 'bottom-end',
        middleware: [
          offset({
            mainAxis: 0,
            crossAxis: 0,
          }),
        ],
      }}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      // TODO: Synchronize table body scroll with dropdown. We may need to not render as a portal in order to achieve this
      isPortal
      portalOptions={{
        rootId: 'theme-root',
      }}
    >
      <StyledColumnHeader $isOpen={isOpen} onClick={handleHeaderClick} ref={headerRef} tabIndex={0}>
        <StyledFieldColumnHeaderText>{fieldConfig.displayName}</StyledFieldColumnHeaderText>
        <StyledFilterIconContainer className="cursor--pointer" $isFiltered={!!appliedFilter}>
          <Icon size="sm" name="filter" ariaLabel="Filter" className="ml-2" />
        </StyledFilterIconContainer>
        {isSortedField && (
          <div className="cursor--pointer">
            <Icon
              className="ml-2"
              size="sm"
              name={sortAscending ? 'chevron-up' : 'chevron-down'}
              ariaLabel="Sort"
            />
          </div>
        )}
      </StyledColumnHeader>
      <FieldColumnHeaderForm fieldConfig={fieldConfig} onClose={() => setIsOpen(false)} />
    </StyledFloatUI>
  );
};

const StyledFieldColumnHeaderText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledFloatUI = styled(FloatUI)`
  & > div:first-child {
    width: 100%;
    height: 100%;
  }
`;

const styledFilterIndicator = css`
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  width: 6px;
  height: 6px;
  border-radius: var(--pf-rounded-circle);
  background-color: var(--pf-secondary-color-400);
`;

const StyledFilterIconContainer = styled.div<{ $isFiltered: boolean }>`
  position: relative;
  display: inline-block;

  ${({ $isFiltered }) =>
    $isFiltered &&
    css`
      &::after {
        ${styledFilterIndicator}
      }
    `}
`;
