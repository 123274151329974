import { Tab } from 'pages/clearance-app/components/clearance/types';

export const CLEARANCE_VIEW_TABS: Tab[] = [
  { name: 'Submission', id: 'submission' },
  { name: 'Documents', id: 'documents' },
  { name: 'Risk Appetite', id: 'appetite' },
  { name: 'Ask My Submission', id: 'askMySubmission' },
  { name: 'History', id: 'history' },
];

export const GENERAL_SECTION = 'General Information';
