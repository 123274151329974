import { Button } from '@indico-data/design-system';
import styled, { css } from 'styled-components';
import { useDeclineEvents, useMarkEventsAsSpam } from '../../actions';

type Props = {
  isHidden: boolean;
  selectedInboxEventIds: string[];
  handleBulkAction: () => void;
};

export const BulkActions = ({ isHidden, selectedInboxEventIds, handleBulkAction }: Props) => {
  const { fetching: fetchingDecline, action: declineEvents } = useDeclineEvents();
  const { fetching: fetchingMarkSpam, action: markEventsAsSpam } = useMarkEventsAsSpam();

  const handleDecline = async () => {
    await declineEvents(selectedInboxEventIds);
    handleBulkAction();
  };

  const handleMarkSpam = async () => {
    await markEventsAsSpam(selectedInboxEventIds);
    handleBulkAction();
  };

  return (
    <StyledBulkActions $isHidden={isHidden}>
      <span className="mr-4">
        {selectedInboxEventIds.length} email{selectedInboxEventIds.length > 1 ? 's' : ''} selected
      </span>
      <Button
        iconName={!fetchingDecline ? 'fa-ban' : undefined}
        color="secondary"
        ariaLabel="Decline"
        className="mr-3"
        onClick={handleDecline}
        variant="outline"
        isLoading={fetchingDecline}
      >
        Decline
      </Button>
      <Button
        iconName={!fetchingMarkSpam ? 'warning' : undefined}
        color="secondary"
        ariaLabel="Spam"
        variant="outline"
        onClick={handleMarkSpam}
        isLoading={fetchingMarkSpam}
      >
        Spam
      </Button>
    </StyledBulkActions>
  );
};

const StyledBulkActions = styled.div<{ $isHidden: boolean }>`
  position: absolute;
  bottom: var(--pf-size-5);
  left: 50%;
  transform: translateX(-50%);
  padding: var(--pf-padding-4);
  border: var(--pf-border-md) solid var(--pf-font-color);
  border-radius: var(--pf-rounded);
  display: flex;
  align-items: center;
  transition: 0.5s ease;
  background-color: var(--pf-background-color);

  ${(props) =>
    props.$isHidden &&
    css`
      visibility: hidden;
      bottom: -100px;
    `}

  & > * {
    white-space: nowrap;
  }
`;
