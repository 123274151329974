import { PillColor } from '@indico-data/design-system/src/types';
import { DataType, Priority } from 'gql/graphql';

export const FIELD_TYPE_TO_COLUMN_WIDTH: Record<DataType, number | null> = {
  [DataType.Text]: 200,
  [DataType.Number]: 170,
  [DataType.Categorical]: 200,
  [DataType.Name]: 200,
  [DataType.Datetime]: 100,
  [DataType.Currency]: 100,
  [DataType.Address]: 350,
  [DataType.Composite]: null,
  [DataType.Summary]: null,
  [DataType.BigText]: null,
  [DataType.Boolean]: null,
};

export const PRIORITY_TO_PILL_COLOR: Record<Priority, PillColor> = {
  [Priority.High]: 'error',
  [Priority.Medium]: 'info',
  [Priority.Low]: 'primary',
};

export const MIN_OPTIONS_FOR_SEARCH_DROPDOWN = 8;

// TODO Make items per page dynamic
export const ITEMS_PER_PAGE = 25;
