import { Cache, UpdateResolver } from '@urql/exchange-graphcache';
import { graphql } from 'gql';
import {
  SubmissionFieldsFragment,
  ConfirmSubmissionFieldMutation,
  ConfirmSubmissionFieldMutationVariables,
} from 'gql/graphql';

import { MutationAndUpdater } from '../types';

const UPDATE_EVENTS_MUTATION = graphql(`
  mutation UpdateStatusOfEvents($eventIds: [String!]!, $status: InboxEventStatus!) {
    updateEvents(uuids: $eventIds, status: $status) {
      items {
        uuid
        status
      }
    }
  }
`);

// Event status updates can remove InboxEvents from the filtered list returned by the InboxPage query, so all cached InboxEvents should be invalidated
const updateEventsUpdater: UpdateResolver = (_result, _args, cache) => {
  cache.invalidate('InboxEvent');
};

export const updateEvents: MutationAndUpdater = {
  mutation: UPDATE_EVENTS_MUTATION,
  updater: updateEventsUpdater,
};

const CREATE_SUBMISSION_MUTATION = graphql(`
  mutation CreateSubmission($eventId: String!) {
    createSubmission(eventUuids: [$eventId]) {
      uuid
      displayId
    }
  }
`);

// Creating a submission causes a status update for any event associated with it, so all cached InboxEvents should be invalidated (along with Submission)
const createSubmissionUpdater: UpdateResolver = (_result, _args, cache) => {
  cache.invalidate('InboxEvent');
  cache.invalidate('SubmissionPage');
};

export const createSubmission: MutationAndUpdater = {
  mutation: CREATE_SUBMISSION_MUTATION,
  updater: createSubmissionUpdater,
};

const ASSOCIATE_EVENTS_WITH_SUBMISSION_MUTATION = graphql(`
  mutation AssociateEventsWithSubmission($submissionId: String!, $eventIds: [String!]!) {
    associateEvents(submissionUuid: $submissionId, uuids: $eventIds) {
      uuid
      displayId
    }
  }
`);

// Associating events with a submission causes a status update for any associated events, so all cached InboxEvents should be invalidated (along with Submission)
const associateEventsUpdater: UpdateResolver = (_result, _args, cache) => {
  cache.invalidate('InboxEvent');
  cache.invalidate('SubmissionPage');
};

export const associateEventsWithSubmission: MutationAndUpdater = {
  mutation: ASSOCIATE_EVENTS_WITH_SUBMISSION_MUTATION,
  updater: associateEventsUpdater,
};

const UPDATE_SUBMISSION_MUTATION = graphql(`
  mutation UpdateSubmission($submissionId: String!, $status: SubmissionStatus!) {
    updateSubmissions(uuids: [$submissionId], status: $status) {
      ...ClearancePage_Submission
    }
  }
`);

// TODO: Update submission status in cache instead of invalidating
const updateSubmissionsUpdater: UpdateResolver = (_result, _args, cache) => {
  cache.invalidate('Submission');
};

export const updateSubmission: MutationAndUpdater = {
  mutation: UPDATE_SUBMISSION_MUTATION,
  updater: updateSubmissionsUpdater,
};

const CONFIRM_SUBMISSION_FIELD = graphql(`
  mutation ConfirmSubmissionField(
    $fieldVectorName: String!
    $submissionUuid: String!
    $valueLinkUuids: [String!]!
    $values: [FieldValue!]!
  ) {
    confirmSubmissionField(
      fieldVectorName: $fieldVectorName
      submissionUuid: $submissionUuid
      valueLinkUuids: $valueLinkUuids
      values: $values
    ) {
      ...SubmissionField_SubmissionLinkedField
    }
  }
`);

const confirmSubmissionFieldUpdater: UpdateResolver = (
  result: ConfirmSubmissionFieldMutation,
  args: ConfirmSubmissionFieldMutationVariables,
  cache: Cache,
) => {
  const sectionName = result.confirmSubmissionField.field.section as string | null;

  const submissionFieldsFragment = graphql(`
    fragment SubmissionFields on Submission {
      uuid
      fields(sectionName: $sectionName) {
        ...SubmissionField_SubmissionLinkedField
      }
    }
  `);

  const submissionEntity = {
    __typename: 'Submission',
    uuid: args.submissionUuid,
  };
  const submissionKey = cache.keyOfEntity(submissionEntity);

  // Invalidate submission fields that might have been updated

  const fieldsToInvalidate = cache
    .inspectFields(submissionKey)
    .filter((field) => ['alerts', 'igoPercentage', 'status'].includes(field.fieldName));

  fieldsToInvalidate.forEach((field) => cache.invalidate(submissionKey, field.fieldKey));

  // Update the submission field in the cache for the correct section
  const submission = cache.readFragment<SubmissionFieldsFragment>(
    submissionFieldsFragment,
    submissionEntity,
    {
      sectionName,
    },
  );

  if (submission) {
    const updatedFields = submission.fields.map((field) => {
      if (field.field.vectorName === args.fieldVectorName) {
        return result.confirmSubmissionField;
      }

      return field;
    });

    cache.writeFragment(
      submissionFieldsFragment,
      {
        ...submission,
        fields: updatedFields,
      },
      {
        sectionName,
      },
    );
  }
};

export const confirmSubmissionField: MutationAndUpdater = {
  mutation: CONFIRM_SUBMISSION_FIELD,
  updater: confirmSubmissionFieldUpdater,
};
