import { Card } from '@indico-data/design-system';
import { graphql } from 'gql';
import styled from 'styled-components';
import { formatToReadableUSDateTime } from 'utils/dateUtils';
import { LineItemCard_AuditLogFragment } from 'gql/graphql';

graphql(`
  fragment LineItemCard_AuditLog on AuditLog {
    message
    timestamp
  }
`);

type Props = {
  lineItem: LineItemCard_AuditLogFragment;
};

export default function LineItemCard({ lineItem }: Props): JSX.Element {
  const { timestamp, message } = lineItem;

  return (
    <StyledLineItemCard>
      <StyledTimestamp>{formatToReadableUSDateTime(new Date(timestamp))}</StyledTimestamp>
      <StyledBody>{message}</StyledBody>
    </StyledLineItemCard>
  );
}

const StyledLineItemCard = styled(Card)`
  border-left: var(--pf-border-lg) solid var(--pf-info-color);
  margin-bottom: var(--pf-margin-2);
  background-color: var(--pf-background-color-dark);
`;

const StyledTimestamp = styled.h4`
  font-weight: var(--pf-font-weight-bold);
  color: var(--pf-font-color-light-header);
  margin-bottom: var(--pf-margin-1);
`;

const StyledBody = styled.p`
  margin-bottom: var(--pf-margin-2);
`;
