import { useCallback, useEffect } from 'react';
import { Filter } from 'gql/graphql';
import { useQueryParam, JsonParam, StringParam, BooleanParam, NumberParam } from 'use-query-params';
import { ITEMS_PER_PAGE } from '../constants';
import { FieldDrivenTableState } from '../types';

export const useFieldTableParams = (options?: {
  defaultSortByField?: string;
  defaultFilters?: Filter[];
  tableKey?: string;
}): FieldDrivenTableState => {
  const tableKey = options?.tableKey || 'default';
  const defaultSortByField = options?.defaultSortByField || '_creationTimeUnix';

  // FILTERS
  const [filters, setFilters] = useQueryParam<Filter[]>(`${tableKey}.filters`, JsonParam);
  const initFilters = useCallback(() => {
    setFilters(options?.defaultFilters ?? []);
  }, [setFilters, options?.defaultFilters]);

  // Initialize default filters on first render
  useEffect(() => {
    if (!filters) {
      initFilters();
    }
  }, [filters, initFilters]);

  // SORTING
  const [sortByField, setSortByField] = useQueryParam(`${tableKey}.sortByField`, StringParam);
  const [sortAscending, setSortAscending] = useQueryParam(
    `${tableKey}.sortAscending`,
    BooleanParam,
  );

  // Initialize default sort on first render
  useEffect(() => {
    if (!sortByField) {
      setSortByField(defaultSortByField);
    }
  }, [sortByField, setSortByField, defaultSortByField]);

  // PAGINATION
  const [page, setPage] = useQueryParam(`${tableKey}.page`, NumberParam);
  const offset = page ? (page - 1) * ITEMS_PER_PAGE : undefined;

  return {
    filters,
    sortByField: sortByField ?? defaultSortByField,
    sortAscending: sortAscending ?? false,
    offset,
    setFilters,
    initFilters,
    setSortByField,
    setSortAscending,
    setPage,
  };
};
