import {
  AssociationModal_SubmissionFragment,
  FieldDrivenTable_FieldConfigFragment,
} from 'gql/graphql';

export const findFieldOnSubmission = (
  submission: AssociationModal_SubmissionFragment,
  fieldConfig: FieldDrivenTable_FieldConfigFragment,
) => {
  return submission.fields.find((field) => field.field.displayName === fieldConfig.displayName);
};
