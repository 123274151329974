import { createGlobalStyle } from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@indico-data/design-system';
import 'react-toastify/dist/ReactToastify.css';
import { MULISH_FONT_STACK } from './constants';

export const AppGlobalStyles = createGlobalStyle`

  // CSS Variables
  :root {

    // Toastify

    // Fonts
    --toastify-font-family: ${MULISH_FONT_STACK};
    --toastify-text-color-light: #000000;

    // General
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #0070F5;
    --toastify-color-success: #008A00;
    --toastify-color-warning: #FFA424;
    --toastify-color-error: #E72326;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  }

  body{
    color: ${COLORS.black};
    border-color: ${COLORS.black};
    font-family: ${MULISH_FONT_STACK};
    font-size: ${TYPOGRAPHY.fontSize.subheadSmall};


    a {
      color: ${COLORS.blueDarknut};
      font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
      &:hover {
        color: ${COLORS.blueDarknut};
      }
    }

    .back-link {
      display: inline-flex;
      align-items: center;
      margin-bottom: var(--pf-margin-3);
      text-decoration: none;
      border-bottom: 1px solid transparent;
      img {
        display: inline-block;
        margin-right: var(--pf-margin-3);
      }
      &:hover {
        border-bottom: 1px solid ${COLORS.blueDarknut};
      }
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${MULISH_FONT_STACK};
    }

    h2 {
      font-size: ${TYPOGRAPHY.fontSize.subheadXl};
      font-weight: bold;
    }

    p {
      font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
    }

    textarea {
      font-family: ${MULISH_FONT_STACK};
      border: 1px solid ${COLORS.tundora};
      box-shadow: none;
      resize: none;
      &:focus {
        outline: 1px solid ${COLORS.blueDarknut};
      }
    }

    label[hidden]{
      display: none;
    }

    button,
    [type='button'],
    [type='reset'],
    [type='submit'],
    .button,
    button {
      box-shadow: none;
      font-family: ${MULISH_FONT_STACK};
      font-weight: bold;
    }

    [type='button'].link-style {
      font-weight: normal;
      text-decoration: none;
      color: ${COLORS.blueDarknut};

      &:hover {
        color: ${COLORS.blueDarknut};
      }

      &:focus {
        color: ${COLORS.endeavor};
      }

      &.purple-link-btn {
        color: ${COLORS.sagatPurple};
        text-decoration: none;
        font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
        &:hover {
          color: ${COLORS.sagatPurple};
        }
        &:focus {
          color: ${COLORS.daisyBush};
        }
      }

      &:hover {
        text-decoration: underline;
      }

      &:disabled {
        background-color: transparent;
        color: ${COLORS.dusty};
        &:hover {
          background-color: transparent;
          color: ${COLORS.dusty};
          text-decoration: none;
        }
      }
    }

    input {
      color: ${COLORS.black};
      border-color: ${COLORS.black};
      font-family: ${MULISH_FONT_STACK};
      font-size: ${TYPOGRAPHY.fontSize.subheadSmall} !important;
    }

    // Remove the browser generated 'x' incon from search inputs
    input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
    // clears the ‘X’ from Chrome 
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }


    // Base Styles for Custom React-Select components
    .InsightsSelect__input-container, .InsightsSelect__dropdown-indicator {
      cursor: pointer;
    }
    .InsightsSelect__control {
      background: ${COLORS.white};
      border: 1px solid ${COLORS.black};
      color: ${COLORS.black};
      box-shadow: none;
  
      &:hover,
      &:focus,
      &--is-focused {
        border: 1px solid ${COLORS.black};
      }
    }
  
    .InsightsSelect__placeholder {
      font-size: ${TYPOGRAPHY.fontSize.base};
    }
  
    .InsightsSelect__indicator-separator {
      background-color: ${COLORS.black};
    }
  
    .InsightsSelect__indicator {
      color: ${COLORS.black};
  
      &:hover,
      &:focus {
        color: ${COLORS.black};
      }
    }
  
    .InsightsSelect__menu {
      border: 1px solid ${COLORS.black};
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
  
    .InsightsSelect__option {
      overflow-wrap: break-word;
      cursor: pointer;
      &--is-focused,
      &:active {
        background: ${COLORS.brightDusk};
        color: ${COLORS.black};
      }
  
      &--is-selected {
        background: ${COLORS.white};
        color: ${COLORS.black};
      }
  
      .InsightsSelect__Items {
        display: flex;
        align-items: center;
  
        .InsightsSelect__SelectedItemCheck {
          margin-right: var(--pf-margin-2);
          width: 12px;
          height: 12px;
        }
      }
    }

    .InsightsSelect__value-container.InsightsSelect__value-container--is-multi {
      padding: 4px;
        .InsightsSelect__multi-value{
        background: ${COLORS.slaaneshGrey};
        margin:0;
        margin-right: 4px;
        border-radius: 4px;
        .InsightsSelect__multi-value__label {
          font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
          padding: 4px;
          padding-left: 9px;
          padding-right: 0;
        }
      }
      &.InsightsSelect__value-container--has-value{
        padding: 2px 4px;
        .InsightsSelect__multi-value{
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }
    }

    .clear-values-icon,
    .remove-multi-value-icon {
      margin: 9px 10px;
      cursor: pointer;
    }

    .InsightsSelect__multi-value__remove:hover {
      background: ${COLORS.slaaneshGrey};
      color: currentColor;
    }

    .insights-select__item {
      display: flex;
      align-items: center;

      .insights-select__item-check {
        margin-right: var(--pf-margin-2);
      }
    }

    .multi-value-label-tooltip {
      color: #000;
      max-width: 700px;
      border: 1px solid #444444;
      border-radius: 4px;
      background-color: #ffffff;
      filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.4));
      opacity: 1;
    }
    // END of Styles for Custom React-Select components


    .general__modal__overlay {
      background-color: rgba(0, 0, 0, 0.65) !important;
    }

    .baseModal__overlay {
      background-color: rgba(0, 0, 0, 0.65) !important;

      .baseModal__content{
        background-color: ${COLORS.white};
        border-radius: 6px;
      }

      .title {
        color: ${COLORS.black};
        font-size: 24px;
        margin: 0;
        margin-bottom: var(--pf-margin-3);
      }

      .cta.confirm.blue {
        background-color: ${COLORS.blueDarknut};
        border-color: ${COLORS.blueDarknut};
        &:hover {
          border-color: ${COLORS.britishShorthair};
          background-color: ${COLORS.britishShorthair};
        }
      }


      .normal.reject {
        background-color: ${COLORS.white};
        color: ${COLORS.blueDarknut};
        border: 1px solid ${COLORS.blueDarknut};
        &:hover {
          color: ${COLORS.britishShorthair};
          border-color: ${COLORS.britishShorthair};
          background-color: ${COLORS.hawkes};
        }
      }

      .actions button {
        width: 180px;
        min-width: auto;
        font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
      }
    }
  }

  
  // Toastify
  .Toastify__close-button {
    color: #000000;
    background-color: transparent;
}

`;
