export enum SectionStatus {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

type SubmissionFieldValue = {
  value: string;
};

export type SubmissionFieldFormState = {
  values: SubmissionFieldValue[];
};
