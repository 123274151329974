import styled from 'styled-components';
import { useRef } from 'react';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
};

export const Content = (props: Props): JSX.Element => {
  const { children, isOpen } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <StyledContent ref={contentRef} $isOpen={isOpen}>
      {children}
    </StyledContent>
  );
};

const StyledContent = styled.div<{ $isOpen: boolean }>`
  height: ${({ $isOpen }) => ($isOpen ? 'auto' : '0px')};
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  padding: 0;

  /* TODO: Add height animation */
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;
  line-height: 26px;

  & > div {
    overflow: hidden;
    margin: 0;
  }
`;
