import styled from 'styled-components';
import { GENERAL_SECTION } from 'pages/clearance-app/pages/clearance/constants';
import { useQuery } from 'urql';
import { useMemo, useState } from 'react';
import { graphql } from 'gql';
import SubmissionSection from './SubmissionSection/SubmissionSection';

interface SubmissionTabProps {
  submissionId: string;
}

export default function SubmissionTab(props: SubmissionTabProps): JSX.Element {
  const { submissionId } = props;

  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query SubmissionTab_GetSections {
        application {
          fields {
            sections
          }
        }
      }
    `),
  });

  const sections = useMemo(
    () => [GENERAL_SECTION, ...(data?.application.fields.sections || [])],
    [data],
  );

  const [activeSection, setActiveSection] = useState<string | null>(GENERAL_SECTION);

  const handleToggleSection = (sectionName: string) => {
    setActiveSection(sectionName === activeSection ? null : sectionName);
  };

  return (
    <StyledSubmissionTab>
      {sections.map((section) => (
        <SubmissionSection
          key={section}
          isOpen={activeSection === section}
          submissionId={submissionId}
          section={section}
          onToggle={handleToggleSection}
          isSectionInfoLoading={fetching}
        />
      ))}
    </StyledSubmissionTab>
  );
}

const StyledSubmissionTab = styled.div``;
