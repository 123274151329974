import { SubmissionStatus } from 'gql/graphql';

export const getSubmissionUpdatedMessage = (status: SubmissionStatus) => {
  if (status === SubmissionStatus.Cleared) {
    return `Submission successfully cleared!`;
  }

  if (status === SubmissionStatus.Declined) {
    return `Submission successfully declined!`;
  }

  return `Submission updated to ${status}!`;
};

export const getErrorMessage = (message: string) => <span>{message}</span>;
