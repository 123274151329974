import styled from 'styled-components';
import { graphql } from 'gql';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Col, Form } from '@indico-data/design-system';
import { SubmissionTextField_SubmissionLinkedFieldFragment } from 'gql/graphql';
import SubmissionFieldEdit from './SubmissionFieldEdit';
import { FieldColWidthSize, getResponsiveFieldColWidthByFieldType } from '../../../helpers';
import SubmissionFieldHeader from '../../SubmissionFields/SubmissionFieldHeader';
import { SubmissionFieldFormState } from '../../../types';

graphql(`
  fragment SubmissionTextField_SubmissionLinkedField on SubmissionLinkedField {
    confirmed
    field {
      typeConfig {
        type
      }
      displayName
      vectorName
    }
    ...SubmissionFieldHeader_SubmissionLinkedField
    ...SubmissionFieldEdit_LinkedFieldWithValues
  }
`);

type Props = {
  field: SubmissionTextField_SubmissionLinkedFieldFragment;
  isLoading: boolean;
  onSubmit: (data: SubmissionFieldFormState) => Promise<void>;
};

export default function SubmissionTextField({ field, isLoading, onSubmit }: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);

  const { handleSubmit, resetField } = useFormContext<SubmissionFieldFormState>();

  const handleSave = () => {
    handleSubmit(async (data: SubmissionFieldFormState) => {
      if (data.values.length === 0) {
        return;
      }
      await onSubmit(data);
      setIsEditing(false);
    })();
  };

  const handleCancel = () => {
    resetField(`values`);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleAddField = () => {
    setIsEditing(true);
  };

  return (
    <StyledSubmissionTextField
      xl={getResponsiveFieldColWidthByFieldType(FieldColWidthSize.XL, field.field.typeConfig.type)}
      lg={getResponsiveFieldColWidthByFieldType(FieldColWidthSize.LG, field.field.typeConfig.type)}
      sm={getResponsiveFieldColWidthByFieldType(FieldColWidthSize.SM, field.field.typeConfig.type)}
    >
      <Form>
        <SubmissionFieldHeader
          field={field}
          isLoading={isLoading}
          isEditing={isEditing}
          onEditClick={handleEditClick}
          onSaveClick={handleSave}
          onCancelClick={handleCancel}
        />
        <SubmissionFieldEdit
          key={field.field.vectorName}
          field={field}
          isEditable={isEditing}
          onAddField={handleAddField}
        />
      </Form>
    </StyledSubmissionTextField>
  );
}

const StyledSubmissionTextField = styled(Col)``;
