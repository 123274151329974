import styled from 'styled-components';
import { TableColumn } from '@indico-data/design-system/src/types';
import {
  SubmissionStatusCell,
  PriorityCell,
} from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/cells/auxiliary';
import { StyledColumnHeader } from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/StyledColumnHeader';
import { FieldColumnHeader } from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/header/FieldColumnHeader';
import * as routes from 'constants/routes';
import { Link } from 'react-router-dom';
import { elapsedTimeString } from 'utils/dateUtils';
import { Button, Icon, Pill } from '@indico-data/design-system';
import { DataType, Priority, SubmissionStatus, WorkQueue_SubmissionFragment } from 'gql/graphql';
import { ColumnConfig } from 'pages/clearance-app/components/tables/FieldsDrivenTable/types';
import { FilterableDropdown } from 'pages/clearance-app/components/inputs/FilterableDropdown/FilterableDropdown';
import { SingleValueCell } from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/cells/core/SingleValueCell';
import { users as mockUsers } from './__mocks__/users';

const fixedColumnConfigs: Record<string, ColumnConfig> = {
  status: {
    displayName: 'Status',
    vectorName: 'status',
    typeConfig: {
      type: DataType.Categorical,
      options: Object.values(SubmissionStatus),
    },
    multi: false,
  },
  elapsedTime: {
    displayName: 'Elapsed Time',
    vectorName: '_creationTimeUnix',
    typeConfig: {
      type: DataType.Datetime,
    },
    multi: false,
  },
  priority: {
    displayName: 'Priority',
    vectorName: 'priority',
    typeConfig: {
      type: DataType.Categorical,
      options: Object.values(Priority),
    },
    multi: false,
  },
  riskAppetite: {
    displayName: 'Risk Appetite',
    vectorName: 'risk_appetite',
    typeConfig: {
      type: DataType.Number,
    },
    multi: false,
  },
};

const ElapsedTimeCell = ({ submission }: { submission: WorkQueue_SubmissionFragment }) => {
  return (
    <Pill className="mx-auto" data-tag="allowRowEvents">
      <Icon name="fa-clock" size="sm" className="mr-1" />
      {elapsedTimeString(new Date(), new Date(submission.createdAt))}
    </Pill>
  );
};

export const workQueueFixedColumns: TableColumn<WorkQueue_SubmissionFragment>[] = [
  {
    name: <StyledColumnHeader>Submission ID</StyledColumnHeader>,
    id: 'submissionId',
    selector: (submission) => submission.displayId,
  },
  {
    name: <StyledColumnHeader>Assigned To</StyledColumnHeader>,
    cell: (submission) => (
      <FilterableDropdown
        key={submission.uuid}
        options={mockUsers}
        initialOption={mockUsers[Math.floor(Math.random() * mockUsers.length)]}
      />
    ),
    width: '232px',
    id: 'Assigned To',
  },
  {
    name: <FieldColumnHeader fieldConfig={fixedColumnConfigs.status} />,
    id: fixedColumnConfigs.status.displayName,
    cell: (submission) => <SubmissionStatusCell status={submission.status} />,
    width: '175px',
  },
  {
    name: <FieldColumnHeader fieldConfig={fixedColumnConfigs.priority} />,
    id: fixedColumnConfigs.priority.displayName,
    cell: (submission) => <PriorityCell priority={submission.priority} />,
    width: '150px',
  },
  {
    name: <FieldColumnHeader fieldConfig={fixedColumnConfigs.riskAppetite} />,
    id: fixedColumnConfigs.riskAppetite.displayName,
    cell: (submission) => <SingleValueCell value={submission.riskAppetite} isNumber />,
    width: '175px',
  },
  {
    name: <FieldColumnHeader fieldConfig={fixedColumnConfigs.elapsedTime} />,
    id: fixedColumnConfigs.elapsedTime.displayName,
    cell: (submission) => <ElapsedTimeCell submission={submission} />,
    width: '160px',
  },
];

export const manageButtonColumn: TableColumn<WorkQueue_SubmissionFragment> = {
  name: '',
  id: 'manage',
  cell: (submission) => (
    <Link to={`${routes.CLEARANCE_APP}/clearance?submissionId=${submission.uuid}`}>
      <StyledManageButton ariaLabel="Manage" variant="outline">
        Manage
      </StyledManageButton>
    </Link>
  ),
  width: '120px',
};

export const StyledManageButton = styled(Button)`
  font-size: var(--pf-table-font-size);
`;
