import { formatToReadableUSDate } from 'utils/dateUtils';
import {
  DataType,
  SubmissionFieldHeader_SubmissionLinkedFieldFragment,
  SubmissionSection_SubmissionLinkedFieldFragment,
} from 'gql/graphql';
import { SectionStatus } from './types';

// Reconsider pattern when design is finalized.
export enum FieldColWidthSize {
  XL = 'xl',
  LG = 'lg',
  SM = 'sm',
}

const fieldColWidthMap: Record<FieldColWidthSize, Record<DataType, number>> = {
  [FieldColWidthSize.XL]: {
    [DataType.BigText]: 12,
    [DataType.Summary]: 12,
    [DataType.Address]: 12,
    [DataType.Text]: 6,
    [DataType.Datetime]: 6,
    [DataType.Number]: 6,
    [DataType.Boolean]: 6,
    [DataType.Categorical]: 6,
    [DataType.Composite]: 6,
    [DataType.Currency]: 6,
    [DataType.Name]: 6,
  },
  [FieldColWidthSize.LG]: {
    [DataType.BigText]: 12,
    [DataType.Summary]: 12,
    [DataType.Address]: 12,
    [DataType.Text]: 6,
    [DataType.Datetime]: 6,
    [DataType.Number]: 6,
    [DataType.Boolean]: 6,
    [DataType.Categorical]: 6,
    [DataType.Composite]: 6,
    [DataType.Currency]: 6,
    [DataType.Name]: 6,
  },
  [FieldColWidthSize.SM]: {
    [DataType.BigText]: 12,
    [DataType.Summary]: 12,
    [DataType.Address]: 12,
    [DataType.Text]: 12,
    [DataType.Datetime]: 12,
    [DataType.Number]: 12,
    [DataType.Boolean]: 12,
    [DataType.Categorical]: 12,
    [DataType.Composite]: 12,
    [DataType.Currency]: 12,
    [DataType.Name]: 12,
  },
};

export function getResponsiveFieldColWidthByFieldType(
  size: FieldColWidthSize,
  type: DataType,
): number {
  return fieldColWidthMap[size]?.[type] ?? 6;
}

export function getDisplayNameForField(field: SubmissionFieldHeader_SubmissionLinkedFieldFragment) {
  const { field: fieldData } = field;
  const { displayName } = fieldData;

  // This is a temporary solution to display the correct field name for address fields
  if (fieldData.typeConfig.type === DataType.Address) {
    return 'Mailing Addresses';
  }

  return displayName;
}

export function computeSectionStatus(fields: SubmissionSection_SubmissionLinkedFieldFragment[]) {
  if (fields.find((field) => !field.confirmed && field.field.required)) {
    return SectionStatus.Incomplete;
  }

  return SectionStatus.Complete;
}

export function formatValueForField(value: string, type: DataType) {
  // TODO: Display datetime values as date input instead of formatting as a string
  if (type === DataType.Datetime && value.length > 0) {
    return formatToReadableUSDate(new Date(value));
  }

  return value;
}

export function tempSubmissionFieldFilter(field: SubmissionSection_SubmissionLinkedFieldFragment) {
  return field.field.displayName !== 'Doc Type';
}
