import { Icon } from '@indico-data/design-system';
import { graphql } from 'gql';
import {
  SuggestedActionDetails_DetailsFragment,
  SuggestedActionDetails_InboxEventFragment,
} from 'gql/graphql';
import styled from 'styled-components';
import { CreateDetails, AssociateDetails, DeclineDetails, SpamDetails } from './details';

graphql(`
  fragment SuggestedActionDetails_Details on SuggestedActionDetails {
    __typename
    ...AssociateDetails_Details
    ...DeclineDetails_Details
    ...SpamDetails_Details
  }

  fragment SuggestedActionDetails_InboxEvent on InboxEvent {
    ...AssociateDetails_InboxEvent
  }
`);

const getDetailsComponent = (
  inboxEvent: SuggestedActionDetails_InboxEventFragment,
  details?: SuggestedActionDetails_DetailsFragment | null,
) => {
  if (!details) {
    return <CreateDetails />;
  }

  switch (details.__typename) {
    case 'SuggestedActionDeclineDetails':
      return <DeclineDetails details={details} />;
    case 'SuggestedActionSpamDetails':
      return <SpamDetails details={details} />;
    case 'SuggestedActionAssociateDetails':
      return <AssociateDetails details={details} inboxEvent={inboxEvent} />;
    default:
      throw new Error('Unknown details type');
  }
};

type Props = {
  inboxEvent: SuggestedActionDetails_InboxEventFragment;
  details?: SuggestedActionDetails_DetailsFragment | null;
};

export const SuggestedActionDetails = ({ inboxEvent, details }: Props) => {
  return (
    <StyledActionDescription>
      <Icon name="gen-ai" className="mr-1" />
      {getDetailsComponent(inboxEvent, details)}
    </StyledActionDescription>
  );
};

const StyledActionDescription = styled.p`
  color: var(--pf-secondary-color-300);
`;
