import React from 'react';
import { DrawerLinkList } from './DrawerLinkList';
import StyledDrawer from './Drawer.styles';
// import { ToggleThemeItem } from './ToggleThemeItem';

type Props = {
  headerComponent?: React.ReactNode;
  navigationItems?: any[];
  footerItems?: any[];
  expandedWidth?: string;
  collapsedWidth?: string;
  // isLight: boolean;
  // handleThemeChange?: () => void;
};

export const Drawer = (props: Props) => {
  const {
    navigationItems,
    footerItems,
    headerComponent,
    expandedWidth = '245px',
    collapsedWidth = '52px',
    // isLight,
    // handleThemeChange,
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const handleMouseOver = () => setIsOpen(true);
  const handleMouseOut = () => setIsOpen(false);
  const currentWidth = isOpen ? expandedWidth : collapsedWidth;

  return (
    <StyledDrawer
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
      aria-expanded={isOpen}
    >
      <div
        className="drawer__wrapper"
        style={{
          width: currentWidth,
        }}
      >
        <div className="drawer__header">{headerComponent}</div>
        <div className="drawer__content">
          {navigationItems?.length ? (
            <div className="drawer__navigation" data-testid="drawer-navigation">
              <DrawerLinkList isOpen={isOpen} listItems={navigationItems} />
            </div>
          ) : null}

          {footerItems?.length ? (
            <div className="drawer__footer" data-testid="drawer-footer">
              {/* <ToggleThemeItem
                isLight={isLight}
                isHidden={isOpen}
                handleThemeChange={handleThemeChange}
              /> */}
              <DrawerLinkList isOpen={isOpen} listItems={footerItems} />
            </div>
          ) : null}
        </div>
      </div>
    </StyledDrawer>
  );
};
