import { lazy } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { Insights } from 'providers/Insights';
import { SearchApp, ClearanceApp } from 'providers/apps';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import * as routes from 'constants/routes';
import InboxPage from 'pages/clearance-app/pages/inbox/InboxPage';
import WorkQueuePage from 'pages/clearance-app/pages/work-queue/WorkQueuePage';
import LaunchPage from 'pages/launch/LaunchPage';
import LoginPage from 'pages/login/LoginPage';
import Logout from 'pages/login/components/Logout';
import ClearancePage from 'pages/clearance-app/pages/clearance/ClearancePage';
import { ToastContainer } from 'pages/clearance-app/components/toast/ToastContainer';

const Search = lazy(() => import('pages/search/SearchPageContainer'));
const Libraries = lazy(() => import('pages/libraries/LibrariesPageContainer'));
const ConfigureFields = lazy(() => import('pages/configure-fields/ConfigureFieldsPageContainer'));
const AskMyCollection = lazy(
  () => import('pages/ask-my-docs/summarization-studio/SummarizationStudioLoader'),
);

export default function Routes() {
  const publicRoutes: RouteObject[] = [{ path: '/login', element: <LoginPage /> }];
  const privateRoutes: RouteObject[] = [
    {
      path: '/',
      element: (
        <Insights>
          <LaunchPage />
        </Insights>
      ),
    },
    {
      path: routes.SEARCH_APP,
      element: (
        <SearchApp>
          <Outlet />
        </SearchApp>
      ),
      children: [
        {
          path: routes.SEARCH_APP,
          element: <Navigate to={`${routes.SEARCH_APP}/search`} replace />,
        },
        { path: `${routes.SEARCH_APP}/search`, element: <Search /> },
        { path: `${routes.SEARCH_APP}/libraries`, element: <Libraries /> },
        { path: `${routes.SEARCH_APP}/libraries/:libraryId/fields`, element: <ConfigureFields /> },
        { path: `${routes.SEARCH_APP}/ask-my-docs/:libraryUid?`, element: <AskMyCollection /> },
      ],
    },
    {
      path: routes.CLEARANCE_APP,
      element: (
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <ClearanceApp>
            <Outlet />
            <ToastContainer />
          </ClearanceApp>
        </QueryParamProvider>
      ),
      children: [
        {
          path: routes.CLEARANCE_APP,
          element: <Navigate to={`${routes.CLEARANCE_APP}/inbox`} replace />,
        },
        {
          path: `${routes.CLEARANCE_APP}/inbox`,
          // Placeholder element for the Inbox page
          element: <InboxPage />,
        },
        {
          path: `${routes.CLEARANCE_APP}/clearance`,
          // Placeholder element for the Submissions page
          element: <ClearancePage />,
        },
        {
          path: `${routes.CLEARANCE_APP}/work-queue`,
          element: <WorkQueuePage />,
        },
      ],
    },
    { path: '/logout', element: <Logout /> },
    { path: '*', element: <Navigate to="/" replace /> },
  ];
  return [...publicRoutes, ...privateRoutes];
}
