import styled from 'styled-components';
import { CirclePulse } from '@indico-data/design-system';
import { useQuery } from 'urql';
import { buildUrlFromPath, convertStoragePathToAppPath } from 'utils/urls';
import { graphql } from 'gql';
import { ACTIONS_SECTION_HEIGHT } from '../../constants';
import { EventInfo } from './components/EventInfo';

type Props = {
  highlightedInboxEventId: string;
  onClosePanel: () => void;
};

// TODO: This component is currently hardcoded to display specific fields.
// In the future, we will allow the user to configure which fields are displayed in the event info panel.
export const EventContent = ({ highlightedInboxEventId, onClosePanel }: Props) => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query EventContent_GetInboxEvent($uuid: String!) {
        events(uuids: [$uuid]) {
          items {
            ...EventInfo_InboxEvent
          }
        }
      }
    `),
    variables: { uuid: highlightedInboxEventId },
  });

  const inboxEvent = data?.events?.items[0];

  const primaryDocumentFirstPage = inboxEvent?.primaryDocument?.filesDigest['original_page_0.png'];

  const thumbnailPath = primaryDocumentFirstPage
    ? convertStoragePathToAppPath(primaryDocumentFirstPage)
    : null;

  if (!fetching && !inboxEvent) {
    return <StyledNoInboxEventFound>No Inbox Event Found.</StyledNoInboxEventFound>;
  }

  return (
    <StyledEventContent>
      <EventInfo inboxEvent={inboxEvent} fetching={fetching} onClosePanel={onClosePanel} />
      <StyledDocument>
        {fetching ? (
          <StyledLoadingContainer>
            Loading Document
            <CirclePulse overallSize="100px" />
          </StyledLoadingContainer>
        ) : (
          <StyledDocumentViewer>
            {thumbnailPath && (
              <img src={buildUrlFromPath(thumbnailPath)} alt="Document Thumbnail" />
            )}
          </StyledDocumentViewer>
        )}
      </StyledDocument>
    </StyledEventContent>
  );
};

const StyledEventContentBase = styled.div`
  display: flex;
  flex-direction: column;
  border-left: var(--pf-border-sm) solid var(--pf-border-color);
  background-color: var(--pf-background-color-dark);
  margin-right: var(--pf-negative-margin-5);
`;

const StyledNoInboxEventFound = styled(StyledEventContentBase)`
  align-items: center;
  justify-content: center;
  margin-top: -84px;
  height: 100vh;
  margin-right: var(--pf-negative-margin-5);
  margin-bottom: var(--pf-negative-margin-5);
`;

const StyledEventContent = styled(StyledEventContentBase)`
  height: calc(100vh - ${ACTIONS_SECTION_HEIGHT}px);
  box-shadow: 0 var(--pf-size-2) var(--pf-size-4) rgba(0, 0, 0, 0.2);
  border-left: var(--pf-border-sm) solid var(--pf-border-color);
  justify-content: space-between;
`;

const StyledDocument = styled.div`
  overflow-y: hidden;
  padding-top: var(--pf-padding-4);
  border-top: var(--pf-border-sm) solid var(--pf-border-color);
`;

const StyledDocumentViewer = styled.div`
  overflow-y: scroll;
  overflow-x: none;
  height: 100%;
  border-top: var(--pf-border-sm) solid var(--pf-border-color);
  margin-left: var(--pf-margin-4);
  margin-right: var(--pf-margin-4);

  img {
    display: block;
    object-fit: cover;
    object-position: top left;
  }
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 800px;
`;
