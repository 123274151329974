import { Icon } from '@indico-data/design-system';

import { graphql } from 'gql';
import { SubmissionFieldHeaderIcon_SubmissionLinkedFieldFragment } from 'gql/graphql';

graphql(`
  fragment SubmissionFieldHeaderIcon_SubmissionLinkedField on SubmissionLinkedField {
    confirmed
    field {
      required
    }
  }
`);

interface SubmissionFieldHeaderIconProps {
  field: SubmissionFieldHeaderIcon_SubmissionLinkedFieldFragment;
}

export const SubmissionFieldHeaderIcon = ({ field }: SubmissionFieldHeaderIconProps) => {
  if (field.confirmed) {
    return <Icon name="check" className="success" size="sm" />;
  }

  if (field.field.required) {
    return <Icon name="warning" className="warning" size="sm" />;
  }

  return null;
};
