import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery } from './useLazyQuery';

export type Props<SortByItem, FilterBy> = {
  query: {
    name: any;
    variables?: any;
  };
  sortBy: {
    initialItem: SortByItem;
    descending: boolean;
  };
  filterBy?: FilterBy;
  pagination: {
    limit: number;
  };
  paginationResetDependencies?: any[];
  refreshInterval?: number;
  pause?: boolean;
};

type PaginationState = {
  aggregateCount?: number;
  limit: number;
  offset: number;
  currentPage: number; // base 1 index
};

/**
 * Hook that wraps together apollo's useQuery with pagination/filtering/sort handlers and state
 * Goal is to use this hook whenever the UI needs to render a "list" of paginated data and not have to
 * manually set up the state and handlers associated with pagination/filtering/sorting every time
 */
export const useQueryWithPagination = <SortByItem, FilterBy>({
  query: { name: queryName, variables: queryVariables = {} },
  sortBy: { initialItem, descending: initialDescending },
  filterBy,
  pagination: { limit },
  paginationResetDependencies,
  pause = false,
}: Props<SortByItem, FilterBy>) => {
  // Establish Sort State
  const [sortByValue, setSortByValue] = useState<SortByItem>(initialItem);
  const [descending, setDescending] = useState<boolean>(initialDescending);
  // Establish Filter State
  const [filters, setFilters] = useState<FilterBy | null>(filterBy || null);
  // Establish Pagination State
  const initialPaginationState = useMemo(
    () => ({
      limit,
      offset: 0,
      currentPage: 1,
      aggregateCount: 0,
    }),
    [limit],
  );

  const [paginationState, setPaginationState] = useState<PaginationState>(initialPaginationState);

  const resetPaginationState = useCallback(() => {
    setPaginationState(initialPaginationState);
  }, [initialPaginationState]);

  // This generic solution allows us to reset to initial state when a specified var(s) changes
  // paginationResetDependencies should be an array of values to watch. Values of any type.
  useEffect(() => {
    if (paginationResetDependencies?.length) {
      resetPaginationState();
    }
  }, [paginationResetDependencies, resetPaginationState]);

  // Establish GQL Query
  const [{ data, fetching: loading, error }, refetchCurrent] = useLazyQuery({
    query: queryName,
    requestPolicy: 'network-only',
    pause,
  });

  const getPage = useCallback(
    (newPage: number) => {
      setPaginationState(() => ({
        limit,
        offset: (newPage - 1) * limit,
        currentPage: newPage,
      }));
      refetchCurrent({ limit, offset: (newPage - 1) * limit, ...queryVariables });
    },
    [limit, refetchCurrent, queryVariables],
  );

  // Wrapper callbacks that update state and reset pagination
  const setSortBy = useCallback(
    (sortBy: SortByItem) => {
      setSortByValue(sortBy);
      resetPaginationState();
    },
    [resetPaginationState],
  );

  const toggleDescending = useCallback(() => {
    setDescending((prevState) => !prevState);
    resetPaginationState();
  }, [resetPaginationState]);

  const updateFilters = useCallback(
    (value: React.SetStateAction<FilterBy | null>) => {
      setFilters(value);
      resetPaginationState();
    },
    [resetPaginationState],
  );

  return {
    limit: paginationState.limit,
    offset: paginationState.offset,
    data,
    loading,
    error,
    refetch: refetchCurrent,
    getPage,
    setSortBy,
    sortByValue,
    toggleDescending,
    descending,
    paginationState,
    filters,
    updateFilters,
    resetPagination: resetPaginationState,
  };
};
