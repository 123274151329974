import { capitalize } from 'lodash';
import { Priority } from 'gql/graphql';
import { CategoricalCell } from '../core';
import { PRIORITY_TO_PILL_COLOR } from '../../../constants';

type Props = {
  priority: Priority;
};

export const PriorityCell = ({ priority }: Props) => {
  return (
    <CategoricalCell
      isCentered
      value={capitalize(priority)}
      pillColor={PRIORITY_TO_PILL_COLOR[priority]}
    />
  );
};
