import axios from 'axios';

const instance = axios.create();

// Request Interceptors
instance.interceptors.request.use(
  (config: any) => {
    const newConfig = {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response Interceptors
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (window.location.pathname !== '/login') {
        // TODO -- This should use either history or react router to redirect.
        // This is a quick fix for now.
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  },
);

export default instance;
