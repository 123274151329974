import { Button, Card, Col, Container, Icon, Row } from '@indico-data/design-system';
// import { useAuth, useTheme } from 'hooks';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { appList } from './constants';

export default function LaunchPage() {
  // const { logout } = useAuth();
  // const { toggleTheme } = useTheme();

  return (
    <StyledLaunchPage>
      <Container>
        <Row>
          <Col xs={12}>
            <StyledHeading>Welcome To Indico Data</StyledHeading>
          </Col>
          <Col xs={12}>
            <StyledApplicationsHeader>
              <Icon name="application-squares" size="xl" className="color-secondary mr-4" />
              <h2>Applications</h2>
            </StyledApplicationsHeader>
          </Col>
        </Row>
        <Row>
          {appList.map((app) => (
            <Col key={app.name} xs={12} sm={6} md={4}>
              <StyledCard className="mb-5">
                <Icon name={app.icon as any} size="xl" className="color-secondary mb-3" />
                <StyledCardTitle>{app.name}</StyledCardTitle>
                <StyledCardBodyText>{app.description}</StyledCardBodyText>
                <hr />
                <StyledCardActions>
                  <Link to={app.redirectTo}>
                    <Button
                      iconName="fa-arrow-right"
                      ariaLabel="Search"
                      size="lg"
                      isDisabled={app.isDisabled}
                      color="secondary"
                      variant="outline"
                    />
                  </Link>
                </StyledCardActions>
              </StyledCard>
            </Col>
          ))}
        </Row>

        {/* <Button className="mt-4" ariaLabel="Toggle Theme" onClick={toggleTheme}>
          Toggle Theme
        </Button>
        <Button className="mt-4 ml-2" ariaLabel="logout" onClick={() => logout()}>
          Logout
        </Button> */}
      </Container>
    </StyledLaunchPage>
  );
}

const StyledLaunchPage = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--pf-background-color-dark);
  padding-top: 150px;

  hr {
    margin-top: var(--pf-margin-6);
    margin-bottom: var(--pf-margin-6);
  }
`;

const StyledHeading = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 4rem; // TODO -- add a design system size for very large headings
`;

const StyledApplicationsHeader = styled.div`
  margin-top: var(--pf-margin-16);
  margin-bottom: var(--pf-margin-8);
  display: flex;
  align-items: center;
  h2 {
    font-size: 2rem; // this is a one off, maybe we consider moving to design system down the road.
  }
`;

const StyledCard = styled(Card)`
  padding: var(--pf-padding-5);
  border: none;
  background-color: rgba(
    92,
    112,
    140,
    0.1
  ); // this is a one off, maybe we consider moving to design system down the road.
`;

const StyledCardTitle = styled.h3`
  font-size: var(--pf-font-size-h2);
  margin-bottom: var(--pf-margin-2);
`;

const StyledCardBodyText = styled.p``;

const StyledCardActions = styled.div`
  text-align: right;
`;
