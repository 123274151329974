import { graphql } from 'gql';
import { SubmissionFieldEdit_LinkedFieldWithValuesFragment } from 'gql/graphql';
import { Button, Col } from '@indico-data/design-system';
import styled from 'styled-components';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SubmissionFieldFormState } from '../../../types';
import { SubmissionTextFieldRow } from './SubmissionTextFieldRow';

graphql(`
  fragment SubmissionFieldEdit_LinkedFieldWithValues on SubmissionLinkedField {
    field {
      typeConfig {
        type
      }
      displayName
      vectorName
      multi
    }
    values {
      value
      uuid
      document {
        uuid
      }
    }
  }
`);

interface InputFieldProps {
  field: SubmissionFieldEdit_LinkedFieldWithValuesFragment;
  isEditable: boolean;
  onAddField: () => void;
}

export default function SubmissionFieldEdit(props: InputFieldProps): JSX.Element {
  const { field, isEditable, onAddField } = props;
  const { field: fieldData } = field;

  const {
    fields: fieldFormValues,
    append,
    remove,
  } = useFieldArray<SubmissionFieldFormState, 'values'>({ name: 'values' });

  const { watch } = useFormContext<SubmissionFieldFormState>();

  const lastValue = watch('values')[fieldFormValues.length - 1].value;

  const addFieldValue = () => {
    append({ value: '' });
    onAddField();
  };

  const removeFieldValue = (index: number) => {
    if (fieldFormValues.length > 1) {
      remove(index);
    }
  };

  return (
    <StyledSubmissionFieldEdit>
      <StyledFieldInputWrapper>
        {fieldFormValues.map((formFieldValue, index) => (
          <SubmissionTextFieldRow
            key={formFieldValue.id}
            index={index}
            value={formFieldValue.value}
            isEditable={isEditable}
            field={field}
            onRemove={() => removeFieldValue(index)}
            disableRemoval={fieldFormValues.length === 1}
            documentId={field.values[index]?.document?.uuid}
            autoFocus={index === 0}
          />
        ))}
        {fieldData.multi && isEditable && lastValue.length > 0 && (
          <Button
            onClick={addFieldValue}
            iconName="plus"
            variant="text"
            size="sm"
            ariaLabel={`Edit Field ${fieldData.displayName}`}
          >
            Add Field
          </Button>
        )}
      </StyledFieldInputWrapper>
    </StyledSubmissionFieldEdit>
  );
}

const StyledFieldInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .form-control {
    width: 100%;
    margin-bottom: 0;
  }
`;

export const StyledSubmissionFieldEdit = styled(Col)`
  margin-bottom: var(--pf-margin-1);
`;
