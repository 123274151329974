import { CLEARANCE_APP } from '../../constants/routes';
import { AppList } from './types';

export const appList: AppList[] = [
  {
    name: 'Clearance',
    description: 'Commercial Property',
    icon: 'shield',
    redirectTo: CLEARANCE_APP,
  },
  {
    name: 'Search',
    description: 'Historical Policies',
    icon: 'search-insights',
    redirectTo: 'https://insights.ins-020.us-east-2.indico-dev.indico.io/',
  },
  {
    name: 'App 3',
    description: 'Description Pending',
    icon: 'square-plus',
    redirectTo: '/',
    isDisabled: true,
  },
];
