import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Header } from './components/Header';
import { Content } from './components/Content';

interface SectionProps extends PropsWithChildren<{}> {}

const Section = (props: SectionProps) => {
  const { children } = props;

  return <StyledSection>{children}</StyledSection>;
};

const StyledSection = styled.div`
  padding: 0;
  margin-bottom: var(--pf-margin-5);
`;

Section.Header = Header;
Section.Content = Content;

export default Section;
