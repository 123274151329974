import styled from 'styled-components';

export const SubmissionDocumentContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledSubmissionDocumentContainerWrapper>
      <StyledSubmissionDocumentContainer>{children}</StyledSubmissionDocumentContainer>
    </StyledSubmissionDocumentContainerWrapper>
  );
};

const StyledSubmissionDocumentContainerWrapper = styled.div`
  height: 100%;
  border-left: var(--pf-border-thin) solid var(--pf-border-color);
`;

const StyledSubmissionDocumentContainer = styled.div`
  width: 100%;
  display: inline-block;
  height: 100%;
  background: var(--pf-background-color-dark);
`;
