import { ClearanceInsuredBlock_SubmissionLinkedFieldFragment, DataType } from 'gql/graphql';
import { FieldColWidthSize } from '../tabs/items/submission/helpers';

export const findFieldWithDisplayName = (
  fields: ClearanceInsuredBlock_SubmissionLinkedFieldFragment[],
  displayName: string,
) => {
  const linkedField = fields.find((field) => field.field.displayName === displayName);

  if (!linkedField) {
    throw Error(`Field with display name '${displayName}' could not be found`);
  }

  return linkedField;
};

const insuredBlockFieldColWidthMap: Record<FieldColWidthSize, Record<DataType, number>> = {
  [FieldColWidthSize.XL]: {
    [DataType.BigText]: 12,
    [DataType.Summary]: 12,
    [DataType.Address]: 12,
    [DataType.Text]: 4,
    [DataType.Datetime]: 4,
    [DataType.Number]: 4,
    [DataType.Boolean]: 4,
    [DataType.Categorical]: 4,
    [DataType.Composite]: 4,
    [DataType.Currency]: 4,
    [DataType.Name]: 4,
  },
  [FieldColWidthSize.LG]: {
    [DataType.BigText]: 12,
    [DataType.Summary]: 12,
    [DataType.Address]: 12,
    [DataType.Text]: 4,
    [DataType.Datetime]: 4,
    [DataType.Number]: 4,
    [DataType.Boolean]: 4,
    [DataType.Categorical]: 4,
    [DataType.Composite]: 4,
    [DataType.Currency]: 4,
    [DataType.Name]: 4,
  },
  [FieldColWidthSize.SM]: {
    [DataType.BigText]: 12,
    [DataType.Summary]: 12,
    [DataType.Address]: 12,
    [DataType.Text]: 12,
    [DataType.Datetime]: 12,
    [DataType.Number]: 12,
    [DataType.Boolean]: 12,
    [DataType.Categorical]: 12,
    [DataType.Composite]: 12,
    [DataType.Currency]: 12,
    [DataType.Name]: 12,
  },
};

export function getResponsiveInsuredColWidthByFieldType(
  size: FieldColWidthSize,
  type: DataType,
): number {
  return insuredBlockFieldColWidthMap[size]?.[type] ?? 6;
}
