import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GlobalStyles as GenericGlobalStyles } from '@indico-data/design-system';
import { AppGlobalStyles } from './App.styles';
import { UrqlProvider } from './providers';
import routes from './Routes';
import useFeatureConfigs from './hooks/useFeatureConfig';
import '@indico-data/design-system/lib/index.css';

declare global {
  interface Window {
    Insights: any;
  }
}

const router = createBrowserRouter(routes());

function App() {
  useFeatureConfigs();

  return (
    <>
      <GenericGlobalStyles />
      <AppGlobalStyles />
      <UrqlProvider>
        <RouterProvider router={router} />
      </UrqlProvider>
      <ToastContainer />
    </>
  );
}

export default App;
