import styled from 'styled-components';
import { Citation } from '../../types';

interface CitationButtonProps {
  citation: Citation;
  handleCitationClick: (citation: Citation) => void;
  citationNumber: string;
}

export default function CitationButton({
  citation,
  handleCitationClick,
  citationNumber,
}: CitationButtonProps) {
  return (
    <StyledCitationButton onClick={() => handleCitationClick(citation)}>
      {citationNumber}
    </StyledCitationButton>
  );
}

const StyledCitationButton = styled.div`
  cursor: pointer;
  display: inline-block;
  padding: var(--pf-padding-1) var(--pf-padding-2);
  background-color: var(--pf-secondary-color);
  border-radius: var(--pf-rounded);
  margin-left: var(--pf-margin-1);
  margin-right: var(--pf-margin-1);
  font-size: var(--pf-font-size-overline);
  font-weight: var(--pf-font-weight-bold);
  color: var(--pf-font-color-light);
  margin-top: var(--pf-margin-2);

  &:hover {
    background-color: var(--pf-secondary-color-700);
  }
`;
