import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '@indico-data/design-system';
import { TableFilterProps } from '../../types';

export const TableHeaderSearch = ({ displayName }: TableFilterProps) => {
  const { register } = useFormContext();

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  return (
    <div className="pa-2 mb-3" ref={searchInputRef}>
      <Input
        label={`Filter ${displayName}`}
        hasHiddenLabel
        iconName="filter-outline"
        placeholder="Filter"
        {...register('filter.values.0')}
      />
    </div>
  );
};
