import { Link } from 'react-router-dom';
import { CLEARANCE_APP } from 'constants/routes';

export const getSubmissionCreatedMessage = (submissionUuid: string, displayId: string) => (
  <span>
    Submission{' '}
    <Link to={`${CLEARANCE_APP}/clearance?submissionId=${submissionUuid}`}>{displayId}</Link>{' '}
    successfully created.
  </span>
);

export const getEventsAssociatedMessage = (
  submissionUuid: string,
  displayId: string,
  eventCount: number,
) => (
  <span>
    Event{eventCount > 1 ? 's' : ''} successfully associated with submission{' '}
    <Link to={`${CLEARANCE_APP}/clearance?submissionId=${submissionUuid}`}>{displayId}</Link>.
  </span>
);

export const getEventsMarkedAsSpamMessage = (eventCount: number) => (
  <span>
    {eventCount === 1
      ? 'Event successfully marked as spam.'
      : `${eventCount} events successfully marked as spam.`}
  </span>
);

export const getEventsDeclinedMessage = (eventCount: number) => (
  <span>
    {eventCount === 1
      ? 'Event successfully declined.'
      : `${eventCount} events successfully declined.`}
  </span>
);

export const getErrorMessage = (message: string) => <span>{message}</span>;
