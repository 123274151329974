import styled, { css } from 'styled-components';
import { textTruncationWithEllipsis } from 'utils/styleUtils';

type Props = {
  value: any;
  isNumber?: boolean;
};

export const SingleValueCell = ({ value, isNumber }: Props) => {
  return (
    <StyledSingleValueCell data-tag="allowRowEvents" $rightJustify={isNumber}>
      {value}
    </StyledSingleValueCell>
  );
};

const StyledSingleValueCell = styled.span<{
  $rightJustify?: boolean;
}>`
  width: 100%;
  ${textTruncationWithEllipsis};

  ${({ $rightJustify }) =>
    $rightJustify &&
    css`
      text-align: right;
    `};
`;
