import { useCallback, useEffect, useState } from 'react';

type UsePollingFetch = {
  fetchFunction: (variables: any) => void;
  interval: number;
};

export const usePollingFetch = ({ fetchFunction, interval }: UsePollingFetch) => {
  const [timeUntilNextFetch, setTimeUntilNextFetch] = useState(interval);

  const resetTimeUntilNextFetch = useCallback(() => {
    setTimeUntilNextFetch(interval);
  }, [interval]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeUntilNextFetch((prevTimeUntilNextFetch) => {
        if (prevTimeUntilNextFetch < 1) {
          fetchFunction({ pollingTimestamp: Date.now() });
          return interval;
        }
        return prevTimeUntilNextFetch - 1;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchFunction, interval]);

  return { timeUntilNextFetch, resetTimeUntilNextFetch };
};
