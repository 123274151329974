import React, { useState } from 'react';
import { Input, PasswordInput } from '@indico-data/design-system';
import { StyledAuthForm } from './AuthForm.styles';

type Props = {
  errorMessage?: string;
  handleFormInput: (data: { email: string; password: string }) => void;
};

export function AuthForm(props: Props) {
  const { handleFormInput, errorMessage } = props;

  const [formValues, setFormValues] = useState({ email: '', password: '' });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setFormValues({
      ...formValues,
      [e.target.name]: value,
    });
    handleFormInput({ ...formValues, [e.target.name]: value });
  };

  return (
    <StyledAuthForm>
      <div className="auth__form">
        <div className="form__control">
          <div className="input__wrapper">
            <Input
              data-testid="email-input"
              name="email"
              placeholder="Enter Email Address"
              value={formValues.email}
              onBlur={handleChange}
              isRequired
              onChange={handleChange}
              onKeyDown={(event: { key: string }) => {
                if (event.key === 'Enter') {
                  handleFormInput(formValues);
                }
              }}
              label="Email Address"
            />
          </div>
          <div className="input__wrapper">
            <PasswordInput
              data-testid="password-input"
              label="Password"
              name="password"
              isRequired
              placeholder="Enter Password"
              value={formValues.password}
              onBlur={handleChange}
              onChange={handleChange}
              onKeyDown={(event: { key: string }) => {
                if (event.key === 'Enter') {
                  handleFormInput(formValues);
                }
              }}
            />
          </div>
          {errorMessage && (
            <p data-testid="error-message" className="form--error" aria-live="polite">
              {errorMessage}
            </p>
          )}
        </div>
      </div>
    </StyledAuthForm>
  );
}
