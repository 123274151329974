import { useFieldTableParams } from 'pages/clearance-app/components/tables/FieldsDrivenTable/hooks/useFieldTableParams';
import { TableColumn } from '@indico-data/design-system/src/types';
import { AssociationModal_SubmissionFragment } from 'gql/graphql';
import { Button } from '@indico-data/design-system';
import { graphql } from 'gql';
import { ITEMS_PER_PAGE } from 'pages/clearance-app/components/tables/FieldsDrivenTable/constants';
import { FieldDrivenTable } from 'pages/clearance-app/components/tables/FieldsDrivenTable';
import styled from 'styled-components';
import { useQuery } from 'urql';
import {
  ASSOCIATION_MODAL_FIELD_NAMES,
  ASSOCIATION_MODAL_TABLE_KEY,
  DEFAULT_ASSOCIATION_MODAL_FILTERS,
} from './constants';
import { associationModalFixedColumns } from './association_modal_fixed_columns';
import { findFieldOnSubmission } from './helpers';
import { AssociateButton } from './AssociateButton';

type Props = {
  onClose: () => void;
  onSubmissionClick: (submissionId: string) => void;
};

graphql(`
  fragment AssociationModal_Submission on Submission {
    uuid
    displayId
    createdAt
    fields {
      ...FieldDrivenTable_LinkedFieldWithValues
    }
  }
`);

export const AssociationModalContent = ({ onClose, onSubmissionClick }: Props) => {
  const { sortByField, sortAscending, filters, initFilters } = useFieldTableParams({
    tableKey: ASSOCIATION_MODAL_TABLE_KEY,
    defaultSortByField: '_creationTimeUnix',
    defaultFilters: DEFAULT_ASSOCIATION_MODAL_FILTERS,
  });

  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query AssociationModal_GetSubmissionsAndFields(
        $limit: Int
        $offset: Int
        $sortByField: String
        $sortAscending: Boolean
        $filters: [Filter!]
      ) {
        submissions(
          limit: $limit
          offset: $offset
          sortByField: $sortByField
          sortAscending: $sortAscending
          filters: $filters
        ) {
          items {
            ...WorkQueue_Submission
          }
        }
        application {
          fields {
            fields {
              ...FieldDrivenTable_FieldConfig
            }
          }
        }
      }
    `),
    variables: {
      sortByField,
      sortAscending,
      filters,
      // TODO: Should be settable in field table
      limit: ITEMS_PER_PAGE,
      offset: 0,
    },
  });

  const associationModalFieldConfigs =
    data?.application.fields.fields.filter((field) =>
      ASSOCIATION_MODAL_FIELD_NAMES.includes(field.displayName),
    ) || [];

  const handleAssociate = async (submissionId: string) => {
    await onSubmissionClick(submissionId);
    onClose();
  };

  const associateButtonColumn: TableColumn<AssociationModal_SubmissionFragment> = {
    name: '',
    id: 'associate',
    // eslint-disable-next-line
    cell: (submission) => <AssociateButton onAssociate={() => handleAssociate(submission.uuid)} />,
    width: '150px',
  };

  return (
    <StyledModalContent>
      <StyledModalHeader>
        <StyledModalHeaderContent>
          <h2>Associate to Submission</h2>
          <Button
            variant="outline"
            color="secondary"
            onClick={initFilters}
            ariaLabel="Reset filters"
            isDisabled={fetching}
          >
            Reset filters
          </Button>
        </StyledModalHeaderContent>
        <Button iconName="fa-times" variant="text" onClick={onClose} ariaLabel="Close" />
      </StyledModalHeader>
      <FieldDrivenTable
        keyField="uuid"
        columnDefinitions={[
          associationModalFixedColumns[0],
          ...associationModalFieldConfigs,
          ...associationModalFixedColumns.slice(1),
          associateButtonColumn,
        ]}
        isLoading={fetching}
        fieldFromRowSelector={findFieldOnSubmission}
        data={data?.submissions.items ?? []}
        pagination
        paginationServer
        paginationPerPage={25}
        fixedHeaderScrollHeight="calc(100vh - 280px)"
        pointerOnHover
        tableKey={ASSOCIATION_MODAL_TABLE_KEY}
        defaultFilters={DEFAULT_ASSOCIATION_MODAL_FILTERS}
      />
    </StyledModalContent>
  );
};

const StyledModalContent = styled.div`
  padding: var(--pf-padding-5);
  color: var(--pf-white-color);
  width: 1300px;
  max-width: calc(100vw - 100px);
`;

const StyledModalHeader = styled.div`
  display: flex;
  margin-bottom: var(--pf-margin-4);
  justify-content: space-between;
`;

const StyledModalHeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: var(--pf-size-2);
`;
