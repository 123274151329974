import styled from 'styled-components';
import { Filter } from 'gql/graphql';
import { TableLoading } from 'components/Loading/TableLoading';
import { graphql } from 'gql';
import { useMemo } from 'react';
import { Table } from '@indico-data/design-system';
import { TableProps } from '@indico-data/design-system/src/types';
import { ColumnDefinition, FieldFromRowSelector } from './types';
import { buildColumnsFromDefinitions } from './column_builders';
import { NoDataComponent } from './components/NoDataComponent';
import { FieldDrivenTableContext } from './FieldDrivenTableContext';

export type Props<T> = Omit<TableProps<T>, 'columns'> & {
  // A unique key for the table. This is used to prefix the query params for table state in case there are multiple tables on the page.
  tableKey: string;
  // Default filters
  defaultFilters?: Filter[];
  // The column definitions for the table (either standard RDT columns or field definitions)
  columnDefinitions: ColumnDefinition<T>[];
  // Defines how to extract the field from the entity used for row data (e.g. how to extract a field from a given event or submission)
  fieldFromRowSelector: FieldFromRowSelector<T>;
  isLoading?: boolean;
};

graphql(`
  # Field configuration for determining how to display a column
  fragment FieldDrivenTable_FieldConfig on Field {
    displayName
    vectorName
    typeConfig {
      type
      ... on CategoricalTypeConfig {
        options
      }
    }
    multi
  }

  # Linked field with values
  fragment FieldDrivenTable_LinkedFieldWithValues on BaseLinkedField {
    values {
      value
      uuid
    }
    field {
      ...FieldDrivenTable_FieldConfig
    }
  }
`);

/**
 * FieldDrivenTable is a table component that can accept standard Column definitions as well as field configurations.
 * If field configurations are passed, the table will render columns based on the the type of the field.
 * The fieldFromRowSelector should define how to extract the field value for the appropriate column from the row data.
 */
export const FieldDrivenTable = <T extends any>(props: Props<T>) => {
  const { columnDefinitions, fieldFromRowSelector, tableKey, isLoading, defaultFilters, ...rest } =
    props;

  const contextValue = useMemo(() => ({ tableKey, defaultFilters }), [tableKey, defaultFilters]);

  return (
    <FieldDrivenTableContext.Provider value={contextValue}>
      <StyledTableContainer>
        <Table
          columns={buildColumnsFromDefinitions(columnDefinitions, fieldFromRowSelector)}
          progressComponent={<TableLoading message="Fetching Table Data" />}
          persistTableHead={!isLoading}
          noDataComponent={<NoDataComponent />}
          selectableRowsComponent={StyledCheckbox}
          isLoading={isLoading}
          fixedHeader={!isLoading}
          {...rest}
          paginationComponentOptions={{ noRowsPerPage: true }} // TODO -- this is broken, hiding it for ITC
        />
      </StyledTableContainer>
    </FieldDrivenTableContext.Provider>
  );
};

const StyledTableContainer = styled.div`
  /* TODO: Remove this once overrides no longer affect table font sizes */
  * {
    font-size: var(--pf-font-body2);
  }

  .rdt_TableRow {
    transform: none;
  }

  .rdt_TableCol {
    padding: 0 !important;

    input[type='checkbox'] {
      margin-left: var(--pf-margin-5);
    }
  }

  .rdt_TableCol_Sortable > div:first-child {
    width: 100%;
    height: 100%;
  }
`;

// TODO: Replace with Design System Checkbox
const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  appearance: none;
  width: var(--pf-size-5);
  height: var(--pf-size-5);
  border: var(--pf-border-sm) solid var(--pf-font-color);
  border-radius: var(--pf-rounded-sm);
  display: grid;
  place-content: center;

  &:checked {
    background-color: var(--pf-secondary-color);
    border-color: var(--pf-secondary-color);

    // ::before is a pseudo element used to style the checkmark
    &::before {
      display: block;
      content: '';
      width: var(--pf-size-5);
      height: var(--pf-size-5);
      transform: scale(0.6);
      background-color: var(--pf-white-color);
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
  }
`;
