import styled from 'styled-components';

export const StyledLoading = styled.div<{ $padding?: number; $minHeight?: number }>`
  .loading {
    min-height: ${({ $minHeight }) => $minHeight ?? 200}px;
    padding: ${({ $padding }) => $padding ?? 30}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h2 {
    text-align: center;
    margin-left: 20px;
  }

  .circle__loader {
    padding: 0;
    margin: 0 auto;
    height: 100px;
    width: 100px;
  }
`;
