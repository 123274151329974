import { graphql } from 'gql';
import { SpamDetails_DetailsFragment } from 'gql/graphql';

graphql(`
  fragment SpamDetails_Details on SuggestedActionSpamDetails {
    reasons
  }
`);

type Props = {
  details: SpamDetails_DetailsFragment;
};

export const SpamDetails = ({ details }: Props) => {
  const { reasons } = details;
  return <span>{reasons[0]}</span>;
};
