import { FieldDrivenTable_FieldConfigFragment } from 'gql/graphql';
import { FIELD_TYPE_TO_COLUMN_WIDTH } from './constants';

export const getWidthForField = (fieldConfig: FieldDrivenTable_FieldConfigFragment) => {
  if (fieldConfig.displayName === 'Priority') {
    return '140px';
  }

  return FIELD_TYPE_TO_COLUMN_WIDTH[fieldConfig.typeConfig.type]
    ? `${FIELD_TYPE_TO_COLUMN_WIDTH[fieldConfig.typeConfig.type]}px`
    : undefined;
};
