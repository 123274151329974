import { TableColumn } from '@indico-data/design-system/src/types';
import { AssociationModal_SubmissionFragment, DataType } from 'gql/graphql';
import { StyledColumnHeader } from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/StyledColumnHeader';
import { FieldColumnHeader } from 'pages/clearance-app/components/tables/FieldsDrivenTable/components/header/FieldColumnHeader';
import { ColumnConfig } from 'pages/clearance-app/components/tables/FieldsDrivenTable/types';
import { formatToReadableUSDateTime } from 'utils/dateUtils';

// "Created On" is a pseudo-field, and so we define its config manually here
const createdOnColumnFieldConfig: ColumnConfig = {
  displayName: 'Created On',
  vectorName: '_creationTimeUnix',
  typeConfig: {
    type: DataType.Text,
  },
  multi: false,
};

export const associationModalFixedColumns: TableColumn<AssociationModal_SubmissionFragment>[] = [
  {
    name: <StyledColumnHeader>Submission ID</StyledColumnHeader>,
    id: 'submissionId',
    selector: (submission) => submission.displayId,
    width: '150px',
  },
  {
    name: <FieldColumnHeader fieldConfig={createdOnColumnFieldConfig} />,
    id: 'received',
    selector: (event) => formatToReadableUSDateTime(new Date(event.createdAt)),
  },
];
