import { useState } from 'react';
import { Section } from 'components/Section';
import styled from 'styled-components';
import { Col, Pill, Row } from '@indico-data/design-system';
import { RiskAppetiteCard } from './RiskAppetiteCard/RiskAppetiteCard';
import { RiskAnswer, RiskArea } from '../types';

type Props = {
  riskAreas: RiskArea[];
  onAnswer: (riskAreaName: string, question: string, answer: RiskAnswer) => void;
};

export const RiskAppetiteSections = (props: Props) => {
  const { riskAreas, onAnswer } = props;

  const [activeSection, setActiveSection] = useState<string | null>(riskAreas[0].name);

  const handleToggleSection = (sectionName: string) => {
    setActiveSection(sectionName === activeSection ? null : sectionName);
  };

  return riskAreas.map((riskArea) => {
    const isPositive = riskArea.questions.every((q) => q.answer === false);

    return (
      <Section key={riskArea.name}>
        <Section.Header
          onToggle={() => handleToggleSection(riskArea.name)}
          isOpen={activeSection === riskArea.name}
        >
          <StyledTitle>
            <h3 className="mb-0 mr-3">{riskArea.name}</h3>
            <Pill size="sm" color={isPositive ? 'success' : 'error'}>
              {isPositive ? 'Positively Affects Risk Score' : 'Negatively Affects Risk Score'}
            </Pill>
          </StyledTitle>
        </Section.Header>
        <Section.Content isOpen={activeSection === riskArea.name}>
          <Row gutterWidth={15} className="pa-2">
            {riskArea.questions.map((riskQuestion) => (
              <StyledCol key={riskQuestion.question} xs={12} xl={6} xxxl={4}>
                <RiskAppetiteCard
                  riskQuestion={riskQuestion}
                  onAnswer={(answer) => onAnswer(riskArea.name, riskQuestion.question, answer)}
                />
              </StyledCol>
            ))}
          </Row>
        </Section.Content>
      </Section>
    );
  });
};

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCol = styled(Col)`
  margin-bottom: var(--pf-margin-4);
`;
