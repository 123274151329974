import styled from 'styled-components';

const StyledDrawer = styled.div`
  .drawer__wrapper {
    color: var(--pf-font-color);
    background-color: var(--sidebar-background-color);
    box-sizing: border-box;
    height: 100vh;
    padding: var(--pf-padding-8) var(--pf-padding-1) var(--pf-padding-3) var(--pf-padding-1);
    transition: width 0.2s ease-in-out;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    width: 100%;
  }
  .drawer__header__logo-text {
    font-family: 'Poppins', sans-serif;
    font-weight: var(--pf-font-weight-bold);
  }

  a:link {
    color: var(--pf-font-color);
  }

  a {
    color: var(--pf-font-color);
    text-decoration: none;
  }

  .drawer__header {
    margin-bottom: var(--pf-margin-8);
    color: var(--pf-font-color);
    display: flex;
    align-items: center;
    padding-left: var(--pf-padding-3);
    white-space: nowrap;

    .drawer__header__text {
      overflow: hidden;
      transition: opacity 0.2s ease-in-out;
    }

    span {
      display: flex;
      align-items: center;
    }

    p {
      font-size: var(--pf-font-size-h2);
      margin: 0 0 0 var(--pf-padding-3);
    }
  }

  .drawer__content {
    height: calc(100% - 42px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: var(--pf-padding-3);
  }
`;

export default StyledDrawer;
