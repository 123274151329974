import { graphql } from 'gql';
import { AttachedDocuments_InboxEventFragment } from 'gql/graphql';
import { Icon, Pill } from '@indico-data/design-system';
import styled from 'styled-components';
import { StyledValue } from './StyledValue';
import { getDocumentType } from '../helpers';

type Props = {
  inboxEvent: AttachedDocuments_InboxEventFragment;
};

const MAX_DISPLAYABLE_DOCUMENTS = 7;

graphql(`
  fragment AttachedDocuments_InboxEvent on InboxEvent {
    fields(onlyInboxColumns: false) {
      field {
        displayName
      }
      values {
        uuid
        document {
          uuid
        }
        value
      }
    }
    documents {
      uuid
      fileName
    }
  }
`);

export const AttachedDocuments = ({ inboxEvent }: Props) => {
  return (
    <StyledAttachedDocuments className="pt-1">
      {inboxEvent.documents.slice(0, MAX_DISPLAYABLE_DOCUMENTS).map((doc) => {
        const documentType = getDocumentType(doc.uuid, inboxEvent);

        return (
          <StyledValue key={doc.uuid} className="mb-2">
            {documentType ? (
              <Pill size="sm" className="mr-2">
                {documentType}
              </Pill>
            ) : (
              <Icon name="fa-file" size="md" className="mr-1" />
            )}
            {doc.fileName}
          </StyledValue>
        );
      })}
      {inboxEvent.documents.length > MAX_DISPLAYABLE_DOCUMENTS && <StyledMore>More...</StyledMore>}
    </StyledAttachedDocuments>
  );
};

const StyledAttachedDocuments = styled.div``;

const StyledMore = styled.li`
  list-style: none;
`;
