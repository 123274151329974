import { DataType, Filter } from 'gql/graphql';
import { Form } from '@indico-data/design-system';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { useFieldHeaderForm } from '../../hooks/useFieldHeaderForm';
import {
  FieldColumnHeaderFormState,
  FieldColumnHeaderSortDirection,
  ColumnConfig,
} from '../../types';
import { FieldColumnHeaderActions } from '../actions/FieldColumnHeaderActions';
import { FieldColumnHeaderSort } from '../sort/FieldColumnHeaderSort';
import { FieldColumnHeaderFilter } from '../filters/FieldColumnHeaderFilter';
import { FieldColumnHeaderReset } from '../reset/FieldColumnHeaderReset';
import { useFieldDrivenTableState } from '../../FieldDrivenTableContext';

type Props = {
  fieldConfig: ColumnConfig;
  onClose: () => void;
};

export const FieldColumnHeaderForm = ({ fieldConfig, onClose }: Props) => {
  const { filters, setSortByField, setSortAscending, setFilters } = useFieldDrivenTableState();

  const formMethods = useFieldHeaderForm(fieldConfig);
  const { handleSubmit, resetFilterValues } = formMethods;

  const updateFilters = (newFilter: Filter) => {
    if (
      !newFilter.values?.length ||
      newFilter.values.every((value) => typeof value === 'string' && !value.length)
    ) {
      setFilters((filters || []).filter((filter) => filter.field !== newFilter.field));
    } else if (!filters?.find((filter) => filter.field === newFilter.field)) {
      setFilters([...(filters || []), newFilter]);
    } else {
      setFilters(filters?.map((filter) => (filter.field === newFilter.field ? newFilter : filter)));
    }
  };

  const updateSort = (sortDirection: FieldColumnHeaderSortDirection | null) => {
    if (sortDirection === null) {
      return;
    }

    setSortByField(fieldConfig.vectorName);
    setSortAscending(sortDirection === 'asc');
  };

  const onFormSubmit = (data: FieldColumnHeaderFormState) => {
    updateSort(data.sortDirection);
    updateFilters(data.filter);
    onClose();
  };

  return (
    <StyledFieldColumnHeaderForm>
      <FormProvider {...formMethods}>
        {/* @ts-ignore */}
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <FieldColumnHeaderSort fieldConfig={fieldConfig} />
          {/* TODO: Add datetime filters */}
          {fieldConfig.typeConfig.type !== DataType.Datetime && (
            <FieldColumnHeaderFilter fieldConfig={fieldConfig} />
          )}
          {/* TODO: Add resets for other filter types */}
          {fieldConfig.typeConfig.type === DataType.Categorical && (
            <FieldColumnHeaderReset onClick={resetFilterValues} />
          )}
          <FieldColumnHeaderActions handleCancel={onClose} />
        </Form>
      </FormProvider>
    </StyledFieldColumnHeaderForm>
  );
};

const StyledFieldColumnHeaderForm = styled.div`
  min-width: 200px;
  border-radius: var(--pf-rounded);
  border: var(--pf-border-sm) solid var(--pf-border-color-light);
  background: var(--pf-primary-color-400) !important;

  * {
    font-size: var(--pf-font-size-body2);
  }

  .form-control {
    margin-bottom: 0;
  }
`;
