import { CirclePulse as PFCirclePulse } from '@indico-data/design-system';
import { StyledLoading } from './Loading.styles';

type Props = {
  minHeight?: number;
  message?: string;
  padding?: number;
};

export function Loading(props: Props) {
  const { minHeight = 200, message, padding = 30 } = props;

  return (
    <StyledLoading $padding={padding} $minHeight={minHeight}>
      <div className="loading">
        <h2 className="text-weight--regular">{message || 'Loading...'}</h2>
        <PFCirclePulse className="circle__loader" />
      </div>
    </StyledLoading>
  );
}
