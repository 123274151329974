import styled, { css } from 'styled-components';
import { Tab } from '../types';

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-right: var(--pf-margin-5);
  font-weight: var(--pf-font-weight-medium);
  color: var(--pf-font-color-light);
  margin-bottom: var(--pf-margin-5);
  border-bottom: solid 1px var(--pf-border-color);
  width: 100%;
  justify-content: space-between;
  padding-left: var(--pf-padding-5);
  padding-right: var(--pf-padding-5);
  &:last-child {
    margin-right: 0;
  }
`;

export const StyledTab = styled.div<{ $isActive: boolean }>`
  font-weight: var(--pf-font-weight-medium);
  color: var(--pf-font-color-light);
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.$isActive &&
    css`
      color: var(--pf-active-link-color);
      padding-bottom: var(--pf-padding-2);
      font-weight: var(--pf-font-weight-bold);
      border-bottom: solid 2px var(--pf-active-link-color);
    `}
`;

interface ClearanceViewTabsProps {
  tabList: Tab[];
  activeTab: string;
  handleSelectTab: (tab: string) => void;
}

export default function ClearanceViewTabs(props: ClearanceViewTabsProps): JSX.Element {
  const { tabList, activeTab, handleSelectTab } = props;

  return (
    <StyledWrapper>
      {tabList.map((tab) => (
        <StyledTab
          key={tab.id}
          role="button"
          $isActive={tab.id === activeTab}
          onClick={() => handleSelectTab(tab.id)}
          data-testid={`tab-${tab.id}`}
        >
          {tab.name}
        </StyledTab>
      ))}
    </StyledWrapper>
  );
}
