import { Tooltip } from 'react-tooltip';
import { ChangeEvent, useState } from 'react';
import { SemanticColor } from '@indico-data/design-system/src/types';
import { Col, Icon, Input, Pill, Row } from '@indico-data/design-system';
import styled from 'styled-components';
import { RiskAppetiteSections } from './components/RiskAppetiteSections';
import { RiskAnswer, RiskArea } from './types';
import { RISK_APPETITE_EXPLANATION, RISK_AREAS } from './__mocks__/risk_appetite_demo_mocks';
import { computeRiskAppetite } from './helpers';
import { RISK_APPETITE_TO_TEXT_COLOR } from './constants';

// TODO: This tab is based on the assumption that a false answer to a risk question positively impacts the risk appetite.
// Eventually, questions' effect on risk appetite should be dynamic and configurable.
export default function RiskAppetiteTab() {
  const [riskAreas, setRiskAreas] = useState<RiskArea[]>(RISK_AREAS);
  const [search, setSearch] = useState<string>('');

  const handleAnswer = (riskAreaName: string, question: string, answer: RiskAnswer) => {
    setRiskAreas((prev) =>
      prev.map((area) =>
        area.name === riskAreaName
          ? {
              ...area,
              questions: area.questions.map((q) =>
                q.question === question ? { ...q, answer, isAnswerGenerated: false } : q,
              ),
            }
          : area,
      ),
    );
  };

  const currentRiskAppetite = computeRiskAppetite(riskAreas);

  const filteredRiskAreas = riskAreas.filter((area) =>
    area.name.toLowerCase().includes(search.toLowerCase()),
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <StyledRiskAppetiteTab>
      <StyledHeader gutterWidth={12}>
        <Col xs="content">
          <Input
            placeholder="Search sections"
            onChange={handleSearch}
            iconName="search"
            value={search}
            label="Search"
            hasHiddenLabel
            name="search"
          />
        </Col>
        <Col>
          <StyledPill size="md" color="primary" data-tooltip-id="risk-appetite-tooltip">
            <Icon name="gen-ai" className="mr-1" />
            <span>Risk Appetite:&nbsp;</span>
            <StyledRiskDescriptor $color={RISK_APPETITE_TO_TEXT_COLOR[currentRiskAppetite]}>
              {currentRiskAppetite}
            </StyledRiskDescriptor>
          </StyledPill>
          <Tooltip
            id="risk-appetite-tooltip"
            content={RISK_APPETITE_EXPLANATION[currentRiskAppetite]}
          />
        </Col>
      </StyledHeader>
      <RiskAppetiteSections riskAreas={filteredRiskAreas} onAnswer={handleAnswer} />
    </StyledRiskAppetiteTab>
  );
}

const StyledRiskAppetiteTab = styled.div``;

const StyledHeader = styled(Row)`
  margin-bottom: var(--pf-margin-4);
  display: flex;
  align-items: center;
`;

const StyledPill = styled(Pill)`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
`;

const StyledRiskDescriptor = styled.span<{ $color: SemanticColor }>`
  color: var(--pf-${({ $color }) => $color}-color);
`;
