import styled from 'styled-components';
import { Col, Form, Row, ToggleInput } from '@indico-data/design-system';
import { useFormContext } from 'react-hook-form';
import { graphql } from 'gql';
import { SubmissionBooleanField_SubmissionLinkedFieldFragment } from 'gql/graphql';

graphql(`
  fragment SubmissionBooleanField_SubmissionLinkedField on SubmissionLinkedField {
    field {
      displayName
    }
  }
`);

type Props = {
  field: SubmissionBooleanField_SubmissionLinkedFieldFragment;
  onSubmit: () => void;
};

export default function SubmissionBooleanField({ field, onSubmit }: Props): JSX.Element {
  const { field: fieldData } = field;
  const { displayName } = fieldData;

  const { register, handleSubmit } = useFormContext();

  const handleChange = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <StyledSubmissionBooleanField xs={12}>
      <Row align="center">
        <Col md={8}>
          <Form className="pa-3">
            <ToggleInput
              id={displayName}
              label={displayName}
              {...register('values.0.value', { onChange: handleChange })}
            />
          </Form>
        </Col>
      </Row>
    </StyledSubmissionBooleanField>
  );
}

const StyledSubmissionBooleanField = styled(Col)`
  .form-control {
    margin-bottom: 0;
  }
`;
