import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from 'hooks';
import { Navigate, useLocation } from 'react-router-dom';
import useAuthStore from 'store/auth/authStore';
import { Col, Row } from '@indico-data/design-system';
import { Loading } from 'components/Loading';

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthGuard = ({ children }: AuthProviderProps) => {
  const { authUser } = useAuthStore();
  const { authenticateUser } = useAuth();
  const location = useLocation();
  const [error, setError] = useState(false);

  useEffect(() => {
    const tryAuthenticate = async () => {
      try {
        await authenticateUser();
      } catch {
        setError(true);
      }
    };

    if (!authUser || localStorage.getItem('imIn') === null) {
      tryAuthenticate();
    }
  }, [authenticateUser, location, authUser]);

  if (error) {
    return <Navigate to="/login" replace />;
  }

  if (!authUser) {
    return (
      <Row>
        <Col>
          <Loading minHeight={500} />
        </Col>
      </Row>
    );
  }

  return children;
};
