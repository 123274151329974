import styled from 'styled-components';
import { useDocumentViewerStore } from 'store/documentViewer/documentViewerStore';
import { Button } from '@indico-data/design-system';
import { MAX_ZOOM_FACTOR, MIN_ZOOM_FACTOR } from '../constants';
import { ZoomControls } from '../ZoomControls/ZoomControls';

export function DocumentControls() {
  const { currentPageNumber, changePage, pages, zoomFactor, incrementZoomFactor } =
    useDocumentViewerStore();

  const zoomPercent = (): string => {
    return `${Math.round(zoomFactor * 100).toString()}%`;
  };
  return (
    <StyledDocumentControls>
      <StyledPageSelector>
        <StyledButton
          iconName="faChevronLeft"
          className="mr-2 rounded-circle"
          ariaLabel="Previous page"
          isDisabled={currentPageNumber <= 1}
          size="sm"
          onClick={() => changePage(currentPageNumber - 1)}
        />

        <span className="text-weight--bold mr-1">{`Page ${currentPageNumber}`}</span>
        {` of ${pages.length}`}
        <StyledButton
          iconName="faChevronRight"
          ariaLabel="Next Page"
          isDisabled={currentPageNumber >= pages.length}
          className="ml-2"
          size="sm"
          onClick={() => changePage(currentPageNumber + 1)}
        />
      </StyledPageSelector>
      <ZoomControls
        onZoomIn={() => {
          incrementZoomFactor(0.2);
        }}
        onZoomOut={() => {
          incrementZoomFactor(-0.2);
        }}
        isDisabledZoomIn={zoomFactor >= MAX_ZOOM_FACTOR}
        isDisabledZoomOut={zoomFactor <= MIN_ZOOM_FACTOR}
        zoomPercent={zoomPercent()}
      />
    </StyledDocumentControls>
  );
}

const StyledDocumentControls = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: var(--pf-margin-4);
`;

const StyledPageSelector = styled.div`
  padding: var(--pf-padding-1) var(--pf-padding-3);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--pf-primary-color);
  border-radius: var(--pf-rounded);
  margin-right: var(--pf-margin-3);
  color: var(--pf-white-color);
  border: var(--pf-border-sm) solid var(--pf-border-color);
`;

const StyledButton = styled(Button)`
  border-radius: var(--pf-rounded-circle);
  font-size: var(--pf-font-size-h2);
  height: 24px;
  width: 26px;
`;
