import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@indico-data/design-system';

type AnswerButtonProps = {
  isSelected: boolean;
  isGenerated: boolean;
  ariaLabel: string;
  onClick: () => void;
};

export const AnswerButton = (props: PropsWithChildren<AnswerButtonProps>) => {
  const { isSelected, isGenerated, ariaLabel, onClick, children } = props;

  return (
    <StyledButton
      size="sm"
      $isSelected={isSelected}
      iconName={isGenerated ? 'gen-ai' : null}
      ariaLabel={ariaLabel}
      onClick={onClick}
      color={isSelected ? 'secondary' : 'primary'}
      data-tooltip-id={isSelected && isGenerated ? 'explanation-suggestion-tooltip' : null}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ $isSelected: boolean }>`
  ${({ $isSelected }) =>
    $isSelected
      ? css`
          border: none !important;
        `
      : css`
          svg {
            display: none;
          }
        `}
`;
