import styled from 'styled-components';

interface UserMessageProps {
  text: string;
  id: string;
}

export default function UserMessage({ text, id }: UserMessageProps) {
  return <StyledUserMessageItem key={id}>{text}</StyledUserMessageItem>;
}

const StyledUserMessageItem = styled.li`
  background-color: var(--pf-secondary-color-800);
  max-width: 600px;
  width: fit-content;
  padding: var(--pf-padding-3);
  margin: var(--pf-margin-3) 0;
  border-radius: var(--pf-rounded-lg);
  justify-self: flex-end;
  text-align: left;
  white-space: pre-wrap;
`;
