import { Col, Icon, Row } from '@indico-data/design-system';
import { graphql } from 'gql';
import { FieldValueComparison_HighlightedFieldFragment } from 'gql/graphql';
import styled from 'styled-components';

graphql(`
  fragment FieldValueComparison_HighlightedField on HighlightedField {
    matchesSubmission
    submissionLinkedField {
      field {
        displayName
      }
      values {
        value
      }
    }
    eventLinkedField {
      field {
        displayName
      }
      values {
        value
      }
    }
  }
`);

type Props = {
  highlightedField: FieldValueComparison_HighlightedFieldFragment;
};

export const FieldValueComparison = ({ highlightedField }: Props) => {
  const { matchesSubmission, eventLinkedField, submissionLinkedField } = highlightedField;

  return (
    <StyledHighlightedField>
      <Row>
        <Col>
          <dt>{eventLinkedField?.field?.displayName}</dt>
        </Col>
      </Row>
      <Row>
        <Col xs={6} width="350px">
          <StyledEventValue>{eventLinkedField?.values?.[0]?.value}</StyledEventValue>
        </Col>
        <Col xs={6} width="350px">
          <StyledSubmissionValue>
            <StyledIconContainer $matching={matchesSubmission}>
              <Icon name={matchesSubmission ? 'fa-check-circle' : 'fa-times-circle'} />
            </StyledIconContainer>
            {submissionLinkedField?.values?.[0]?.value}
          </StyledSubmissionValue>
        </Col>
      </Row>
    </StyledHighlightedField>
  );
};

const StyledHighlightedField = styled.div``;

const StyledValue = styled.dd`
  color: var(--pf-white-color);
  padding: var(--pf-padding-2) 0;
  margin-bottom: var(--pf-margin-1);
`;

const StyledSubmissionValue = styled(StyledValue)`
  background: var(--pf-background-color-dark);
  border-radius: var(--pf-rounded);
  padding-left: var(--pf-padding-2);
  padding-right: var(--pf-padding-2);
`;

const StyledEventValue = styled(StyledValue)``;

const StyledIconContainer = styled.div<{ $matching: boolean }>`
  color: ${({ $matching }) => ($matching ? 'var(--pf-success-color)' : 'var(--pf-error-color)')};
  margin-right: var(--pf-margin-1);
  display: inline-block;
  vertical-align: middle;
`;
