import { useQueryParam, StringParam } from 'use-query-params';

export const useInboxPageParams = () => {
  const [highlightedInboxEventId, setHighlightedInboxEventId] = useQueryParam(
    'highlightedInboxEventId',
    StringParam,
  );

  return {
    highlightedInboxEventId: highlightedInboxEventId ?? null,
    setHighlightedInboxEventId,
  };
};
