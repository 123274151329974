import { ReactNode } from 'react';
// import { useTheme } from 'hooks';
import '@indico-data/design-system/lib/index.css';
import '../assets/styles/theme.css';

interface ThemeProviderProps {
  children: ReactNode;
}

export type Theme = 'light' | 'dark';

export const ThemeWrapper = ({ children }: ThemeProviderProps) => {
  // const { theme } = useTheme();

  return (
    <div className="theme" data-theme="dark" id="theme-root">
      {children}
    </div>
  );
};
