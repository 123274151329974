import { Button, Icon, Input, Menu, FloatUI } from '@indico-data/design-system';
import { useState } from 'react';
import styled from 'styled-components';
import { textTruncationWithEllipsis } from 'utils/styleUtils';

export const FilterableDropdown = ({
  initialOption,
  options,
}: {
  initialOption: string;
  options: string[];
}) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options);

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
    setIsPopperOpen(false);
  };

  const handleSearch = (search: string) => {
    setFilteredOptions(options.filter((user) => user.toLowerCase().includes(search.toLowerCase())));
  };

  return (
    <FloatUI
      ariaLabel="Assigned To"
      isOpen={isPopperOpen}
      isPortal
      portalOptions={{ rootId: 'theme-root' }}
      setIsOpen={setIsPopperOpen}
    >
      <StyledTrigger
        ariaLabel="Assigned To"
        color="primary"
        variant="outline"
        onClick={() => setIsPopperOpen(true)}
      >
        <span>{selectedOption}</span>
        <Icon name="fa-pencil" />
      </StyledTrigger>
      <div className="pa-2">
        <Input
          placeholder="Search Users"
          label="Search Users"
          className="text-body-2"
          hasHiddenLabel
          name="search-users"
          iconName="fa-search"
          onChange={(e) => handleSearch(e.target.value)}
        />
        <StyledMenuWrapper>
          <Menu>
            {filteredOptions.map((option) => (
              <StyledOption
                onClick={() => handleSelectOption(option)}
                ariaLabel={option}
                key={option}
                size="md"
              >
                {option}
              </StyledOption>
            ))}
          </Menu>
        </StyledMenuWrapper>
      </div>
    </FloatUI>
  );
};

const StyledTrigger = styled(Button)`
  width: 200px;
  display: flex;
  justify-content: space-between;
  gap: var(--pf-size-2);
  font-size: var(--pf-table-font-size);

  span {
    ${textTruncationWithEllipsis};
  }
`;

const StyledMenuWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
  min-height: 200px;
  max-width: 300px;
`;

const StyledOption = styled(Button)`
  width: 100%;
  ${textTruncationWithEllipsis};
`;
