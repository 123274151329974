import { TableColumn } from '@indico-data/design-system/src/types';
import { FieldDrivenTable_FieldConfigFragment } from 'gql/graphql';
import { ColumnDefinition, FieldFromRowSelector, isFieldDrivenTableFieldConfig } from './types';
import { getWidthForField } from './helpers';
import { FieldColumnHeader } from './components/header/FieldColumnHeader';
import { FieldCell } from './components/cells/FieldCell';

const buildColumnFromFieldConfig = <T extends any>(
  fieldConfig: FieldDrivenTable_FieldConfigFragment,
  fieldFromRowSelector: FieldFromRowSelector<T>,
): TableColumn<T> => {
  return {
    name: <FieldColumnHeader fieldConfig={fieldConfig} />,
    id: fieldConfig.vectorName,
    sortable: false, // Sorting is handled separately
    width: getWidthForField(fieldConfig),
    cell: (row: T) => (
      <FieldCell row={row} fieldFromRowSelector={fieldFromRowSelector} fieldConfig={fieldConfig} />
    ),
  };
};

export const buildColumnsFromDefinitions = <T extends any>(
  columnDefinitions: ColumnDefinition<T>[],
  fieldFromRowSelector: FieldFromRowSelector<T>,
): TableColumn<T>[] => {
  return columnDefinitions.map((columnDefinition) =>
    isFieldDrivenTableFieldConfig(columnDefinition)
      ? buildColumnFromFieldConfig(columnDefinition, fieldFromRowSelector)
      : columnDefinition,
  );
};
