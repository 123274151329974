import { graphql } from 'gql';
import { textTruncationWithEllipsis } from 'utils/styleUtils';
import { DataType, SubmissionFieldHeader_SubmissionLinkedFieldFragment } from 'gql/graphql';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Button, Col, Row } from '@indico-data/design-system';
import { SubmissionFieldFormState } from '../../types';
import { getDisplayNameForField } from '../../helpers';
import { SubmissionFieldHeaderIcon } from './SubmissionFieldHeaderIcon';

graphql(`
  fragment SubmissionFieldHeader_SubmissionLinkedField on SubmissionLinkedField {
    confirmed
    field {
      displayName
      typeConfig {
        type
      }
    }
    ...SubmissionFieldHeaderIcon_SubmissionLinkedField
  }
`);
interface SubmissionFieldHeaderProps {
  field: SubmissionFieldHeader_SubmissionLinkedFieldFragment;
  isEditing: boolean;
  isLoading: boolean;
  onEditClick?: () => void;
  onSaveClick?: () => void;
  onCancelClick?: () => void;
}

export default function SubmissionFieldHeader(props: SubmissionFieldHeaderProps): JSX.Element {
  const { field, isEditing, isLoading, onCancelClick, onEditClick, onSaveClick } = props;

  const { watch } = useFormContext<SubmissionFieldFormState>();

  const values = watch('values');

  const displayName = getDisplayNameForField(field);

  // TODO: Add editing support for datetime fields
  const enableEditing = field.field.typeConfig.type !== DataType.Datetime;

  return (
    <StyledSubmissionFieldHeader $isEditing={isEditing}>
      <Row align="center" nogutter>
        <Col width="var(--pf-size-3)" className="mr-2">
          <SubmissionFieldHeaderIcon field={field} />
        </Col>
        <StyledLabelAndActions $isEditing={isEditing}>
          <Row align="center" nogutter>
            <StyledNameCol>
              <span className="ml-1">{displayName}</span>
            </StyledNameCol>
            {enableEditing && (
              <Col xs="content">
                {!isEditing ? (
                  <Button
                    onClick={onEditClick}
                    iconName="edit"
                    size="sm"
                    ariaLabel={`Edit Field ${displayName}`}
                  >
                    Edit
                  </Button>
                ) : (
                  <>
                    <Button
                      isLoading={isLoading}
                      onClick={onSaveClick}
                      size="sm"
                      isDisabled={values.some((value) => !value.value.length)}
                      ariaLabel={`Save Field ${displayName}`}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={onCancelClick}
                      size="sm"
                      ariaLabel={`Cancel Field ${displayName}`}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Col>
            )}
          </Row>
        </StyledLabelAndActions>
      </Row>
    </StyledSubmissionFieldHeader>
  );
}

const StyledSubmissionFieldHeader = styled.div<{ $isEditing: boolean }>`
  font-size: var(--pf-font-size-body);
  color: var(--pf-font-color-emphasis);
  margin-top: var(--pf-margin-2);
  padding-right: var(--pf-padding-2);
  padding-left: var(--pf-padding-2);
  padding-bottom: var(--pf-padding-1);
`;

const StyledNameCol = styled(Col)`
  font-size: var(--pf-label-size-sm);
  font-weight: var(--pf-font-weight-bold);
  ${textTruncationWithEllipsis};
`;

const StyledLabelAndActions = styled(Col)<{ $isEditing: boolean }>`
  padding: var(--pf-padding-1) 0;
  ${({ $isEditing }) =>
    !$isEditing &&
    css`
      border-bottom: var(--pf-border-sm) solid var(--pf-border-color-light);
    `}
`;
