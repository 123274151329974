import { EventInfo_InboxEventFragment } from 'gql/graphql';
import { graphql } from 'gql';
import styled from 'styled-components';
import { formatToReadableUSDate } from 'utils/dateUtils';
import { Col, Icon, Pill, Row, Skeleton } from '@indico-data/design-system';
import { findFieldWithDisplayName } from '../helpers';
import { AttachedDocuments } from './AttachedDocuments';
import { EventActions } from '../../EventActions';
import { StyledValue } from './StyledValue';
import { ACTIONS_SECTION_HEIGHT } from '../../../constants';

graphql(`
  fragment EventInfo_InboxEvent on InboxEvent {
    uuid
    createdAt
    fields(onlyInboxColumns: false) {
      ...EventInfo_LinkedField
    }
    documents {
      uuid
      filesDigest(filenames: ["_original"])
    }
    primaryDocument {
      uuid
      filesDigest(filenames: ["original_page_0.png"])
    }
    ...EventActions_InboxEvent
    ...AttachedDocuments_InboxEvent
  }

  fragment EventInfo_LinkedField on LinkedField {
    field {
      displayName
    }
    values {
      value
      document {
        uuid
      }
    }
  }
`);

const MAX_DISPLAYABLE_SIC_CODES = 5;

type Props = {
  inboxEvent?: EventInfo_InboxEventFragment;
  fetching: boolean;
  onClosePanel: () => void;
};

export const EventInfo = ({ inboxEvent, fetching, onClosePanel }: Props) => {
  const proposedEffectiveDate =
    inboxEvent && findFieldWithDisplayName(inboxEvent, 'Proposed Effective Date')?.values[0]?.value;

  return (
    <StyledEventInfo>
      <StyledActionsSection>
        <EventActions
          inboxEvent={inboxEvent}
          fetchingEvent={fetching}
          onClosePanel={onClosePanel}
        />
      </StyledActionsSection>
      <StyledDetailsHeader>Details</StyledDetailsHeader>
      {fetching || !inboxEvent ? (
        <Row>
          <Col xs={6}>
            <Skeleton className="my-4" height={30} width={180} />
            <Skeleton className="my-4" height={30} width={200} />
            <Skeleton className="my-4" height={30} width={190} />
            <Skeleton className="my-4" height={30} width={210} />
            <Skeleton className="my-4" height={30} width={180} />
            <Skeleton className="my-4" height={30} width={210} />
          </Col>
          <Col xs={6}>
            <Skeleton className="my-4" height={30} width={195} />
            <Skeleton className="my-4" height={30} width={205} />
            <Skeleton className="my-4" height={30} width={185} />
            <Skeleton className="my-4" height={30} width={215} />
            <Skeleton className="my-4" height={30} width={180} />
            <Skeleton className="my-4" height={30} width={210} />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={3}>
              <StyledSection>
                <StyledSectionLabel>Named Insured</StyledSectionLabel>
                <StyledValue>
                  {findFieldWithDisplayName(inboxEvent, 'Named Insured')?.values[0]?.value}
                </StyledValue>
              </StyledSection>
            </Col>
            <Col xs={4}>
              <StyledSection>
                <StyledSectionLabel>Proposed Effective Date</StyledSectionLabel>
                <StyledValue>
                  {proposedEffectiveDate && formatToReadableUSDate(new Date(proposedEffectiveDate))}
                </StyledValue>
              </StyledSection>
            </Col>
            <Col xs={5}>
              <StyledSection>
                <StyledSectionLabel>Broker Name</StyledSectionLabel>
                <StyledValue>
                  {findFieldWithDisplayName(inboxEvent, 'Broker Name')?.values[0]?.value}
                </StyledValue>
              </StyledSection>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <StyledSection>
                <StyledSectionLabel>Mailing Address</StyledSectionLabel>
                <StyledValue>
                  {findFieldWithDisplayName(inboxEvent, 'Mailing Address')?.values[0]?.value}
                </StyledValue>
              </StyledSection>
            </Col>
            <Col xs={5}>
              <StyledSection>
                <StyledSectionLabel>SIC Codes</StyledSectionLabel>
                {findFieldWithDisplayName(inboxEvent, 'SIC')
                  ?.values.slice(0, MAX_DISPLAYABLE_SIC_CODES)
                  .map((linkedValue) => (
                    <Pill key={linkedValue.uuid} size="sm" color="info" className="mr-1 my-1">
                      {linkedValue.value}
                    </Pill>
                  ))}
              </StyledSection>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <StyledSummarySection>
                <StyledSectionLabel>
                  <Icon name="gen-ai" className="mr-1" />
                  Summary
                </StyledSectionLabel>
                <StyledSummaryValue>
                  {findFieldWithDisplayName(inboxEvent, 'Summary')?.values[0]?.value}
                </StyledSummaryValue>
              </StyledSummarySection>
            </Col>
            <Col xs={5}>
              <StyledSection>
                <StyledSectionLabel>
                  Attached Documents ({inboxEvent?.documents.length})
                </StyledSectionLabel>
                <AttachedDocuments inboxEvent={inboxEvent} />
              </StyledSection>
            </Col>
          </Row>
        </>
      )}
    </StyledEventInfo>
  );
};

const StyledEventInfo = styled.div`
  padding: var(--pf-padding-4);
  margin-bottom: var(--pf-margin-4);
  background-color: var(--pf-background-color-dark);
`;

const StyledSection = styled.section`
  margin-bottom: var(--pf-margin-4);
`;

const StyledActionsSection = styled.section`
  margin-top: -${ACTIONS_SECTION_HEIGHT}px; /* Moves the action section to the top of the page */
  margin-right: var(--pf-negative-margin-4);
  margin-bottom: var(--pf-margin-4);
  height: 80px;
  margin-left: -17px; /* Ensures that the action section aligns with the right side of the table */
  border-left: 1px solid var(--pf-border-color);
`;

const StyledSectionLabel = styled.label`
  font-size: var(--pf-font-size-body);
  font-weight: var(--pf-font-weight-bold);
  margin-bottom: var(--pf-margin-2);
  color: var(--pf-font-color-light-header);
`;

const StyledDetailsHeader = styled.h2`
  color: var(--pf-font-color-light);
  margin-bottom: var(--pf-margin-4);
`;

const StyledSummarySection = styled(StyledSection)`
  padding: var(--pf-padding-3) var(--pf-padding-4);
  background-color: var(--pf-steel-color-900);
  border-radius: var(--pf-rounded);
`;

const StyledSummaryValue = styled(StyledValue)`
  margin-top: var(--pf-margin-3);
  overflow-y: auto;
  white-space: wrap;
  max-height: 225px;
`;
