import { PropsWithChildren } from 'react';
import { ListItem } from 'components/Navigation/Sidebar/DrawerLinkList';
import { AuthGuard } from './AuthGuard';
import { ThemeWrapper } from './ThemeWrapper';
import { Layout } from './Layout';

type Props = {
  navigationItems?: ListItem[];
};

export const Insights = ({ children, navigationItems }: PropsWithChildren<Props>) => {
  if (!navigationItems) {
    return (
      <AuthGuard>
        <ThemeWrapper>{children}</ThemeWrapper>
      </AuthGuard>
    );
  }

  return (
    <AuthGuard>
      <ThemeWrapper>
        <Layout navigationItems={navigationItems}>{children}</Layout>
      </ThemeWrapper>
    </AuthGuard>
  );
};
