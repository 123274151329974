import { TableColumn } from '@indico-data/design-system/src/types';
import {
  CategoricalTypeConfig,
  FieldDrivenTable_FieldConfigFragment,
  FieldDrivenTable_LinkedFieldWithValuesFragment,
  Filter,
  QueryEventsArgs,
  QuerySubmissionsArgs,
} from 'gql/graphql';
import { OmitTypename } from 'utils/types';

export type ColumnDefinition<T> = TableColumn<T> | FieldDrivenTable_FieldConfigFragment;
export type FieldFromRowSelector<T> = (
  row: T,
  fieldConfig: FieldDrivenTable_FieldConfigFragment,
) => FieldDrivenTable_LinkedFieldWithValuesFragment | undefined;

export type FieldColumnHeaderSortDirection = 'asc' | 'desc';

export type FieldColumnHeaderFormState = {
  sortDirection: FieldColumnHeaderSortDirection | null;
  filter: Filter;
};

export type TableFilterProps = {
  displayName: string;
  appliedFilter: Filter | null;
};

export function isFieldDrivenTableFieldConfig(
  obj: any,
): obj is FieldDrivenTable_FieldConfigFragment {
  return obj && typeof obj === 'object' && 'vectorName' in obj && 'typeConfig' in obj;
}

export function isCategoricalTypeConfig(obj: any): obj is CategoricalTypeConfig {
  return obj && typeof obj === 'object' && 'options' in obj;
}

// TODO: Consider not deriving this from Field Config
// - Change vectorName -> columnId (used for sorting/filtering)
// - Flatten typeConfig
export type ColumnConfig = OmitTypename<FieldDrivenTable_FieldConfigFragment>;

export type FieldDrivenTableState = QueryEventsArgs &
  QuerySubmissionsArgs & {
    setFilters: (filters: Filter[]) => void;
    initFilters: () => void;
    setSortByField: (sortByField: string) => void;
    setSortAscending: (sortAscending: boolean) => void;
    setPage: (page: number) => void;
  };
