import { useRef, useEffect, useState, useCallback } from 'react';
import { useDocumentViewerStore } from 'store/documentViewer/documentViewerStore';
import { CircleSpinner } from '@indico-data/design-system';
import styled from 'styled-components';
import { fetchData } from 'utils/axios/restApiHelpers';
import { buildUrlFromPath, convertStoragePathToAppPath } from 'utils/urls';
import { getCurrentScrollPosition, scrollContainerToPosition } from './helpers';
import { VERTICAL_SCROLL_PADDING } from './constants';
import { OnDocCanvas } from './OnDocCanvas';

type Props = {
  etlOutputPath: string;
};

export const DocumentContainer = ({ etlOutputPath }: Props) => {
  const {
    pages,
    setPages,
    currentPageNumber,
    currentPage,
    scrollPosition,
    zoomFactor,
    changePage,
    currentPageAspectRatio,
    currentPageCitation,
  } = useDocumentViewerStore();

  const documentContainer = useRef<HTMLDivElement>(null!);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const page = currentPage();

  // TODO: BETTER HANDLING OF NULL page handling
  // Doc viewer and its associated store should not be initialized until pages are fetched
  const imagePath = page?.imagePath;
  const imageUrl: string = imagePath ? buildUrlFromPath(imagePath) : '';

  const [isLoading, setIsLoading] = useState(false);

  // Refresh the canvas size so that the full width is used, and the height is adjusted to maintain aspect ratio
  const refreshCanvasSize = useCallback(() => {
    const containerWidth = documentContainer.current.clientWidth;
    const adjustedHeight = containerWidth / currentPageAspectRatio();

    setCanvasWidth(containerWidth);
    setCanvasHeight(adjustedHeight);
  }, [currentPageAspectRatio]);

  // Fetch and initialize pages after initial render
  useEffect(() => {
    const fetchAndSetPages = async () => {
      setIsLoading(true);
      const etlResponse = await fetchData(etlOutputPath);
      setPages(
        etlResponse.pages.map((etlPage: any) => ({
          imagePath: convertStoragePathToAppPath(etlPage.image),
          thumbnailPath: convertStoragePathToAppPath(etlPage.thumbnail),
          dimensions: etlPage.size,
        })),
      );
      setIsLoading(false);
    };

    fetchAndSetPages();
  }, [setPages, etlOutputPath]);

  // Refresh canvas dimensions dimensions after initial render and on resize
  useEffect(() => {
    refreshCanvasSize();
    window.addEventListener('resize', refreshCanvasSize);

    return () => {
      window.removeEventListener('resize', refreshCanvasSize);
    };
  }, [refreshCanvasSize]);

  // Scroll to the correct position and refresh page dimensions on page change
  useEffect(() => {
    if (page === null) return;
    scrollContainerToPosition(
      documentContainer,
      scrollPosition === 'top' ? 'bottom' : 'top',
      currentPageNumber,
    );

    refreshCanvasSize();
  }, [currentPageNumber, pages, refreshCanvasSize, page, scrollPosition]);

  // Scroll to the bottom or top of the next or previous page depending on the previous scroll positiobn
  const handleScroll = () => {
    const currentScrollPosition = getCurrentScrollPosition(documentContainer);

    if (currentScrollPosition === 'bottom' && currentPageNumber < pages.length) {
      changePage(currentPageNumber + 1, {
        scrollPosition: 'top',
      });
    }

    if (currentScrollPosition === 'top' && currentPageNumber > 1) {
      changePage(currentPageNumber - 1, {
        scrollPosition: 'bottom',
      });
    }
  };

  return (
    <StyledDocumentContainer onScroll={handleScroll} ref={documentContainer}>
      <StyledDocumentContainerContent
        $paddingTop={currentPageNumber > 1 ? VERTICAL_SCROLL_PADDING : 0}
        $paddingBottom={currentPageNumber < pages.length ? VERTICAL_SCROLL_PADDING : 0}
      >
        {isLoading || !page ? (
          <StyledLoadingContainer
            $zoomFactor={zoomFactor}
            $canvasHeight={canvasHeight}
            $canvasWidth={canvasWidth}
          >
            <CircleSpinner size="40px" className="loading-spinner" />
          </StyledLoadingContainer>
        ) : (
          <StyledBackgroundImageContainer
            $backgroundImage={imageUrl}
            $zoomFactor={zoomFactor}
            $canvasHeight={canvasHeight}
            $canvasWidth={canvasWidth}
          >
            <OnDocCanvas
              citation={currentPageCitation()}
              zoomFactor={zoomFactor}
              canvasHeight={canvasHeight}
              canvasWidth={canvasWidth}
              pageDimensions={page.dimensions}
            />
          </StyledBackgroundImageContainer>
        )}
      </StyledDocumentContainerContent>
    </StyledDocumentContainer>
  );
};

const StyledSizedContainer = styled.div<{
  $zoomFactor: number;
  $canvasHeight: number;
  $canvasWidth: number;
}>`
  width: ${(props) => `${props.$canvasWidth * props.$zoomFactor}px`};
  height: ${(props) => `${props.$canvasHeight * props.$zoomFactor}px`};
`;

const StyledLoadingContainer = styled(StyledSizedContainer)`
  background: var(--pf-white-color);
`;

const StyledBackgroundImageContainer = styled(StyledSizedContainer)<{
  $backgroundImage: string | null;
}>`
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.$backgroundImage});
`;

const StyledDocumentContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  background: var(--pf-white-color);
`;

// The vertical padding and height are a temporary hack to enable virtual scrolling
const StyledDocumentContainerContent = styled.div<{
  $paddingTop: number;
  $paddingBottom: number;
}>`
  padding-top: ${(props) => props.$paddingTop}px;
  padding-bottom: ${(props) => props.$paddingBottom}px;
  width: 100%;
  height: calc(100% + ${(props) => props.$paddingTop + props.$paddingBottom}px);
  display: inline-block;
  scrollbar-gutter: stable;
  background: var(--pf-white-color);
`;
