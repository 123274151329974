import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { DataType, Operator } from 'gql/graphql';
import { FieldColumnHeaderFormState, FieldColumnHeaderSortDirection, ColumnConfig } from '../types';
import { useFieldDrivenTableState } from '../FieldDrivenTableContext';

// Initializes a form for a field column header with filters and sort direction from the query params (or empty values)
export const useFieldHeaderForm = (columnConfig: ColumnConfig) => {
  const { sortByField, sortAscending, filters, defaultFilters } = useFieldDrivenTableState();
  const isSortedField = sortByField === columnConfig.vectorName;
  const defaultFilter = defaultFilters?.find((filter) => filter.field === columnConfig.vectorName);

  const defaultFormValues: FieldColumnHeaderFormState = useMemo(() => {
    let sortDirection: FieldColumnHeaderSortDirection | null = null;

    if (isSortedField) {
      sortDirection = sortAscending ? 'asc' : 'desc';
    }

    const formFilter =
      filters?.find((filter) => filter.field === columnConfig.vectorName) ??
      createEmptyFieldFilter(columnConfig);

    return { sortDirection, filter: formFilter };
  }, [isSortedField, sortAscending, filters, columnConfig]);

  const methods = useForm<FieldColumnHeaderFormState>({
    defaultValues: defaultFormValues,
  });

  const { setValue } = methods;

  const setFilterValues = (values: string[]) => {
    setValue('filter.values', values);
  };

  const resetFilterValues = () => {
    setValue('filter.values', defaultFilter?.values ?? []);
  };

  return { ...methods, setFilterValues, resetFilterValues };
};

const createEmptyFieldFilter = (fieldConfig: ColumnConfig) => {
  if (fieldConfig.typeConfig.type === DataType.Number) {
    return {
      field: fieldConfig.vectorName,
      op: Operator.Between,
      values: [0, 100],
    };
  }

  return {
    field: fieldConfig.vectorName,
    op: Operator.Contains,
    values: [],
  };
};
