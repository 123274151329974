import React, { useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import { Col, Container, Row } from 'react-grid-system';
import { Button, Icon } from '@indico-data/design-system';
import { useAuth } from 'hooks';
import { AuthFormValues } from 'hooks/useAuth';
import useAuthStore from 'store/auth/authStore';
import * as routes from '../../constants/routes';
import { StyledLoginPage } from './LoginPage.styles';
import { AuthForm } from './components/AuthForm';
import { Loading } from '../../components/Loading';
import { ThemeWrapper } from '../../providers';

export default function LoginPage() {
  const { authUser } = useAuthStore();
  const { login, authenticateUser } = useAuth();
  const [authFormValues, setAuthFormValues] = useState<AuthFormValues>({
    email: '',
    password: '',
  });
  const [formError, setFormError] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  const handleFormInput = (data: { email: string; password: string }) => {
    setAuthFormValues(data);
  };

  // authenticate user if not already authenticated
  useEffect(() => {
    const setupAuthUser = async () => {
      try {
        await authenticateUser();
        navigate('/', { replace: true });
      } catch (error) {
        console.error('Failed to initialize auth user', error);
      }
    };

    if (!authUser) {
      setupAuthUser();
    }
  }, [authenticateUser, authUser, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoggingIn(true);

    try {
      await login(authFormValues);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 401) {
        setFormError("You can't sign in with that email address/password.");
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <ThemeWrapper>
      <StyledLoginPage>
        <Container>
          <Row align="center">
            <Col xs={12}>
              <div className="auth__container">
                {isLoggingIn ? (
                  <div className="auth__loading">
                    <Loading message="Logging In" minHeight={400} />
                  </div>
                ) : (
                  <>
                    <div className="auth__logo__wrapper">
                      <div className="auth__logo">
                        <Icon size="lg" name="indico-o" aria-label="Indico logo" />
                        <p className="auth__logo__text">INDICO INSIGHTS</p>
                      </div>
                      <p className="auth__signin__text">SIGN IN</p>
                    </div>
                    <div className="auth__corp__signin">
                      <Link to={routes.SSO} className="no__decoration">
                        <Button
                          color="secondary"
                          variant="outline"
                          className="auth__corp__signin__button"
                          ariaLabel="Sign in to corporate account"
                          iconName="key"
                          href={routes.SSO}
                        >
                          Sign In To Corporate Account
                        </Button>
                      </Link>
                    </div>
                    <hr />
                    <form onSubmit={handleSubmit}>
                      <AuthForm handleFormInput={handleFormInput} errorMessage={formError} />
                      <div className="auth__actions">
                        <Button
                          type="submit"
                          color="secondary"
                          variant="outline"
                          className="auth__email__signin__button"
                          onClick={handleSubmit}
                          ariaLabel="Sign in to my account"
                        >
                          Sign In To My Account
                        </Button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </StyledLoginPage>
    </ThemeWrapper>
  );
}
