import { InboxEventStatus } from 'gql/graphql';
import { INBOX_EVENT_STATUS_TO_PILL_COLOR } from 'pages/clearance-app/pages/inbox/constants';
import { formatStatus } from 'pages/clearance-app/helpers/string_formatting';
import { CategoricalCell } from '../core';

type Props = {
  status: InboxEventStatus;
};

export const EventStatusCell = ({ status }: Props) => {
  return (
    <CategoricalCell
      isCentered
      value={formatStatus(status)}
      pillColor={INBOX_EVENT_STATUS_TO_PILL_COLOR[status]}
    />
  );
};
