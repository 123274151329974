import styled from 'styled-components';
import { GENERAL_SECTION } from 'pages/clearance-app/pages/clearance/constants';
import { Section } from 'components/Section';
import { Card, Pill, Row, Skeleton } from '@indico-data/design-system';
import { useQuery } from 'urql';
import { useMemo } from 'react';
import {
  SubmissionSection_GetFieldsQuery,
  SubmissionSection_GetFieldsQueryVariables,
} from 'gql/graphql';
import { graphql } from 'gql';
import { computeSectionStatus, tempSubmissionFieldFilter } from '../helpers';
import { SubmissionField } from './SubmissionFields/SubmissionField';
import { Loading } from '../../../../../../../../components/Loading';
import { sectionStatusToColorMap } from '../constants';

interface SubmissionSectionProps {
  submissionId: string;
  section: string;
  isOpen: boolean;
  isSectionInfoLoading: boolean;
  onToggle: (sectionName: string) => void;
}

graphql(`
  fragment SubmissionSection_SubmissionLinkedField on SubmissionLinkedField {
    confirmed
    field {
      required
      displayName
      vectorName
    }
    ...SubmissionField_SubmissionLinkedField
  }
`);

export default function SubmissionSection(props: SubmissionSectionProps): JSX.Element {
  const { submissionId, section, isOpen, onToggle, isSectionInfoLoading } = props;

  const [{ data, fetching: fetchingFields }] = useQuery<
    SubmissionSection_GetFieldsQuery,
    SubmissionSection_GetFieldsQueryVariables
  >({
    query: graphql(`
      query SubmissionSection_GetFields($submissionId: String!, $section: String = null) {
        submissions(uuids: [$submissionId]) {
          items {
            uuid
            fields(sectionName: $section) {
              ...SubmissionSection_SubmissionLinkedField
            }
          }
        }
      }
    `),
    variables: {
      submissionId,
      section: section === GENERAL_SECTION ? null : section,
    },
    // TODO: Uncomment this when we section status is not derived from the fields on the FE
    // pause: !isOpen,
  });

  // TODO: Remove temporary filter once field "scope" is implemented
  const fields = useMemo(
    () => data?.submissions.items[0].fields.filter(tempSubmissionFieldFilter),
    [data],
  );

  const sectionStatus = fields ? computeSectionStatus(fields) : undefined;

  return (
    <Section>
      <Section.Header onToggle={() => onToggle(section)} isOpen={isOpen}>
        <StyledTitle>
          {isSectionInfoLoading || !sectionStatus ? (
            <Skeleton height={30} width={300} />
          ) : (
            <>
              <h3 className="mb-0 mr-3" data-testid={`${section}-for-${submissionId}`}>
                {section}
              </h3>
              <Pill size="sm" color={sectionStatusToColorMap[sectionStatus]}>
                {sectionStatus}
              </Pill>
            </>
          )}
        </StyledTitle>
      </Section.Header>
      <Section.Content isOpen={isOpen}>
        <StyledSubmissionAccordionContent>
          {fetchingFields ? (
            <Loading message={`Fetching Fields for ${section}`} />
          ) : (
            <Row>
              {fields?.map((field) => (
                <SubmissionField key={field.field.vectorName} field={field} />
              ))}
            </Row>
          )}
        </StyledSubmissionAccordionContent>
      </Section.Content>
    </Section>
  );
}

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSubmissionAccordionContent = styled(Card)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;
