import { Button } from '@indico-data/design-system';
import styled from 'styled-components';

type Props = {
  handleCancel: () => void;
};

export const FieldColumnHeaderActions = ({ handleCancel }: Props) => {
  return (
    <StyledActions>
      <Button type="reset" ariaLabel="Cancel" variant="text" onClick={handleCancel}>
        Cancel
      </Button>
      <Button type="submit" ariaLabel="Apply" color="secondary" size="sm">
        Apply
      </Button>
    </StyledActions>
  );
};

const StyledActions = styled.div`
  border-top: var(--pf-border-sm) solid var(--pf-horizontal-line-color);
  display: flex;
  justify-content: right;
  align-items: center;
  padding: var(--pf-padding-1) var(--pf-padding-2);
`;
