import { graphql } from 'gql';
import { ClearancePageTabProps } from 'pages/clearance-app/pages/clearance/ClearancePage';
import { useQuery } from 'urql';
import { DocumentItem } from './DocumentItem';
import { Loading } from '../../../../../../../components/Loading';

export default function DocumentsTab({ submissionId }: ClearancePageTabProps) {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query DocumentsTab_GetDocuments($submissionId: String!) {
        submissions(uuids: [$submissionId]) {
          items {
            uuid
            events {
              uuid
              documents {
                uuid
                ...DocumentItem_Document
              }
            }
          }
        }
      }
    `),
    variables: {
      submissionId,
    },
  });

  const documents = data?.submissions.items[0].events.map((event) => event.documents).flat();

  return fetching ? (
    <Loading message="Fetching Documents..." />
  ) : (
    <ul>
      {documents
        ? documents.map((document) => <DocumentItem key={document.uuid} document={document} />)
        : null}
    </ul>
  );
}
