import styled from 'styled-components';
import { graphql } from 'gql';
import { Button } from '@indico-data/design-system';
import { useFieldTableParams } from 'pages/clearance-app/components/tables/FieldsDrivenTable/hooks/useFieldTableParams';
import { ITEMS_PER_PAGE } from 'pages/clearance-app/components/tables/FieldsDrivenTable/constants';
import { useQuery } from 'urql';
import { FieldDrivenTable } from 'pages/clearance-app/components/tables/FieldsDrivenTable';
import * as routes from 'constants/routes';
import {
  QueryEventsArgs,
  WorkQueue_GetSubmissionsAndFieldsQuery,
  WorkQueue_SubmissionFragment,
} from 'gql/graphql';
import { useNavigate } from 'react-router-dom';
import { WORK_QUEUE_TABLE_KEY, DEFAULT_WORK_QUEUE_FILTERS } from './constants';
import { findFieldOnSubmission } from './helpers';
import { manageButtonColumn, workQueueFixedColumns } from './work_queue_fixed_columns';
import { TableLoading } from '../../../../components/Loading/TableLoading';

graphql(`
  fragment WorkQueue_Submission on Submission {
    uuid
    createdAt
    displayId
    riskAppetite
    priority
    status
    fields(onlyQueueColumns: true) {
      ...FieldDrivenTable_LinkedFieldWithValues
    }
  }
`);

export default function WorkQueuePage() {
  const { filters, sortByField, sortAscending, initFilters } = useFieldTableParams({
    defaultSortByField: '_creationTimeUnix',
    defaultFilters: DEFAULT_WORK_QUEUE_FILTERS,
    tableKey: WORK_QUEUE_TABLE_KEY,
  });

  const [{ data, fetching }] = useQuery<WorkQueue_GetSubmissionsAndFieldsQuery, QueryEventsArgs>({
    query: graphql(`
      query WorkQueue_GetSubmissionsAndFields(
        $sortByField: String
        $sortAscending: Boolean
        $limit: Int
        $offset: Int
        $filters: [Filter!]
      ) {
        submissions(
          sortByField: $sortByField
          sortAscending: $sortAscending
          limit: $limit
          offset: $offset
          filters: $filters
        ) {
          items {
            ...WorkQueue_Submission
          }
        }
        application {
          fields {
            fields {
              isWorkQueueColumn
              ...FieldDrivenTable_FieldConfig
            }
          }
        }
      }
    `),
    variables: {
      filters,
      sortByField,
      sortAscending,
      // TODO: Should be settable in field table
      limit: ITEMS_PER_PAGE,
    },
    requestPolicy: 'cache-and-network',
  });

  const workQueueFieldConfigs =
    data?.application.fields.fields.filter((field) => field.isWorkQueueColumn) || [];

  const navigate = useNavigate();

  const handleRowClick = (submission: WorkQueue_SubmissionFragment) => {
    navigate(`${routes.CLEARANCE_APP}/clearance?submissionId=${submission.uuid}`);
  };

  return (
    <StyledPage className="pa-5">
      <StyledHeader>
        <StyledHeaderContent>
          <h1>Work Queue</h1>
          <Button
            isDisabled={fetching}
            variant="outline"
            color="secondary"
            onClick={initFilters}
            ariaLabel="Reset filters"
          >
            Reset filters
          </Button>
        </StyledHeaderContent>
      </StyledHeader>
      <StyledSubmissions>
        <FieldDrivenTable
          defaultFilters={DEFAULT_WORK_QUEUE_FILTERS}
          keyField="uuid"
          columnDefinitions={[
            ...workQueueFixedColumns,
            ...workQueueFieldConfigs,
            manageButtonColumn,
          ]}
          fieldFromRowSelector={findFieldOnSubmission}
          data={data?.submissions.items ?? []}
          isLoading={fetching}
          progressComponent={<TableLoading message="Fetching Table Data" />}
          pagination
          paginationServer
          paginationPerPage={25}
          persistTableHead={!fetching}
          fixedHeader={!fetching}
          fixedHeaderScrollHeight="calc(100vh - 140px)"
          pointerOnHover
          onRowClicked={handleRowClick}
          tableKey={WORK_QUEUE_TABLE_KEY}
        />
      </StyledSubmissions>
    </StyledPage>
  );
}

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  margin-bottom: var(--pf-margin-4);
`;

const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: var(--pf-size-2);
`;

const StyledSubmissions = styled.div`
  flex: 1;
  border-radius: var(--pf-rounded);
`;
