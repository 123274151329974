import { RiskAppetite, RiskArea } from '../types';

export const RISK_AREAS: RiskArea[] = [
  {
    name: 'Property Location',
    questions: [
      {
        question:
          'Is the property located in an area prone to natural disasters (e.g., floods, earthquakes, hurricanes)?',
        answer: true,
        isAnswerGenerated: true,
        suggestionForExplanation:
          'The submission slip mentions that the property is in a flood plane.',
      },
      {
        question: 'Are any properties in a blacklisted state?',
        answer: false,
        isAnswerGenerated: true,
        suggestionForExplanation: 'None of the property addresses are in a blacklisted state.',
      },
    ],
  },
  {
    name: 'Occupancy and Use',
    questions: [
      {
        question:
          'Is the building used for hazardous operations (e.g., manufacturing, storage of flammable materials)?',
        answer: false,
        isAnswerGenerated: true,
        suggestionForExplanation:
          'There is no mention of manufacturing, flammable materials, or chemical synthesis.',
      },
      {
        question: 'Are there any hazardous materials stored or used on the premises?',
        answer: null,
      },
    ],
  },
  {
    name: 'Fire and Security',
    questions: [
      {
        question: 'Are there known historical fire code violations for this property?',
        answer: false,
        isAnswerGenerated: true,
        suggestionForExplanation:
          'The submission contains no mention of previous fire code violations.',
      },
    ],
  },
  {
    name: 'Maintenance and Upkeep',
    questions: [
      {
        question:
          'Is the property in poor condition or are there indicators the property is badly maintained?',
        answer: false,
        isAnswerGenerated: true,
        suggestionForExplanation: 'There is no indication that the property is poorly maintained.',
      },
    ],
  },
];

export const RISK_APPETITE_EXPLANATION: Record<RiskAppetite, string> = {
  Low: 'There are some security measures and environmental risks that should be resolved before processing.',
  Medium:
    'There are some security measures and environmental risks that should be resolved before processing.',
  High: 'There are some security measures and environmental risks that should be resolved before processing.',
};
