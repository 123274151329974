import { Card } from '@indico-data/design-system';
import styled from 'styled-components';
import { MessageFeedItem } from '../../types';
import SystemMessage from './SystemMessage';
import UserMessage from './UserMessage';
import LoadingIndicator from './LoadingIndicator';

interface AskMySubmissionFeedProps {
  messages: MessageFeedItem[];
}

export default function AskMySubmissionFeed({ messages }: AskMySubmissionFeedProps) {
  const lastMessage = messages.length > 0 ? messages.at(-1) : null;
  const showLoading =
    lastMessage != null && lastMessage.isLoading && lastMessage.messageType === 'user';
  return (
    <StyledAskMySubmissionFeed>
      <StyledMessageList>
        {messages.map(
          ({ text, id, citations, messageType, isLoading, timestamp }: MessageFeedItem) =>
            messageType === 'ai' ? (
              <SystemMessage
                key={id}
                text={text}
                id={id}
                citations={citations}
                messageType={messageType}
                isLoading={isLoading}
                timestamp={timestamp}
              />
            ) : (
              <UserMessage key={id} text={text} id={id} />
            )
        )}
        {showLoading ? <LoadingIndicator /> : null}
      </StyledMessageList>
    </StyledAskMySubmissionFeed>
  );
}

const StyledAskMySubmissionFeed = styled(Card)`
  background-color: var(--pf-background-color-dark);
  border: none;
  height: 400px;
  margin-bottom: var(--pf-margin-5);

  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
`;

const StyledMessageList = styled.ul`
  display: grid;
  grid-auto-flow: column-reverse;
`;
