import _ from 'lodash';
import { Input, Checkbox, Form } from '@indico-data/design-system';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { TableFilterProps } from '../../types';
import { MIN_OPTIONS_FOR_SEARCH_DROPDOWN } from '../../constants';

type Props = TableFilterProps & {
  options: string[];
};

const formatOption = (option: string) => {
  return _.startCase(_.toLower(_.snakeCase(option)));
};

export const TableHeaderSearchWithMultiSelect = ({ displayName, options }: Props) => {
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options);

  const { register, watch } = useFormContext();

  const currentFilters = watch('filter.values');

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (search: string) => {
    setFilteredOptions(options.filter((str) => str.toLowerCase().includes(search.toLowerCase())));
  };

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  return (
    <StyledSearchWithMultiSelect>
      {options.length >= MIN_OPTIONS_FOR_SEARCH_DROPDOWN && (
        <StyledSearchInputWrapper>
          <Input
            placeholder="Search"
            label={`Search ${displayName}`}
            hasHiddenLabel
            name={`search-${displayName}`}
            iconName="fa-search"
            onChange={(e) => handleSearch(e.target.value)}
            ref={searchInputRef}
          />
        </StyledSearchInputWrapper>
      )}
      <StyledOptions>
        <Form>
          {filteredOptions.map((option) => (
            <StyledOption key={option}>
              <div className="ml-2">
                <Checkbox
                  label={formatOption(option)}
                  isChecked={currentFilters?.includes(option)}
                  id={option}
                  value={option}
                  {...register('filter.values')}
                />
              </div>
            </StyledOption>
          ))}
        </Form>
      </StyledOptions>
    </StyledSearchWithMultiSelect>
  );
};

const StyledSearchWithMultiSelect = styled.div`
  border-top: var(--pf-border-sm) solid var(--pf-horizontal-line-color);
`;

const StyledSearchInputWrapper = styled.div`
  padding: var(--pf-padding-2);
  border-bottom: var(--pf-border-sm) solid var(--pf-horizontal-line-color);
`;

const StyledOption = styled.div`
  padding: var(--pf-padding-2) 0;
  /* Fix for lightmode */
  background: var(--pf-primary-color-400);

  &:not(:last-child) {
    border-bottom: var(--pf-border-sm) solid var(--pf-horizontal-line-color);
  }

  label {
    margin-bottom: 0;
    width: 100%;
  }

  input {
    // TODO: Remove once checkbox element is fixed
    visibility: hidden;
  }

  label:before,
  label:after {
    box-sizing: content-box;
  }

  .checkbox-wrapper {
    margin-bottom: 0;
  }
`;

const StyledOptions = styled.div`
  max-height: 200px;
  position: relative;
  overflow-y: auto;
  width: 100%;
  text-align: left;
  color: var(--pf-white-color);
`;
