import styled from 'styled-components';
import { graphql } from 'gql';
import { useQuery } from 'urql';
import LineItemCard from './LineItemCard';
import { Loading } from '../../../../../../../components/Loading';

interface HistoryTabProps {
  submissionId: string;
}

export default function HistoryTab(props: HistoryTabProps) {
  const { submissionId } = props;

  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query HistoryTab_GetAuditLog($submissionId: String!) {
        logs(submissionUuid: $submissionId) {
          id
          ...LineItemCard_AuditLog
        }
      }
    `),
    variables: {
      submissionId,
    },
  });

  return fetching ? (
    <Loading message="Fetching Audit Logs..." />
  ) : (
    <StyledHistoryTab>
      {data?.logs.map((item) => <LineItemCard key={item.id} lineItem={item} />)}
    </StyledHistoryTab>
  );
}

const StyledHistoryTab = styled.div``;
