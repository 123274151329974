import { RangeSlider } from 'pages/clearance-app/components/shared/RangeInput/RangeInput';
import { useFormContext } from 'react-hook-form';

export const TableHeaderRangeInput = () => {
  const { setValue, watch } = useFormContext();

  const handleChange = (value: number[]) => {
    setValue('filter.values', value);
  };

  const values = watch('filter.values');

  return (
    <div className="mb-4">
      <RangeSlider min={0} max={100} onChange={handleChange} values={values} />
    </div>
  );
};
