import { ReactNode, Suspense } from 'react';
import { CirclePulse as PFCirclePulse, COLORS, Icon } from '@indico-data/design-system';
import { Drawer } from 'components/Navigation/Sidebar/Drawer';
import styled from 'styled-components';
import { MULISH_FONT_STACK, POPPINS_FONT_STACK } from 'constants/typography';
import { ListItem } from 'components/Navigation/Sidebar/DrawerLinkList';
import { Link } from 'react-router-dom';
// import { useTheme } from 'hooks';

export const StyledLayout = styled.div`
  hr {
    border-bottom: 1px solid ${COLORS.silverChalice};
    margin: var(--pf-margin-3) 0;
  }

  .layout {
    display: flex;
    padding-left: 50px;
    min-height: 100vh;
    height: 100%;
    overflow: clip;
  }

  .layout__sidebar {
    width: 50px;
    position: fixed;
    z-index: 100;
    height: 100vh;
    flex-shrink: 0;
    left: 0;

    .drawer__header {
      font-family: ${POPPINS_FONT_STACK};
      font-weight: 500;
    }
    .drawer__content {
      font-family: ${MULISH_FONT_STACK};
      font-weight: 500;
    }
  }

  .layout__sidebar__header__link {
    display: flex;

    &:hover {
      color: var(--pf-font-color);
    }
  }

  .layout__content__container {
    width: 100%;
    position: relative;
  }
`;

const LogoHeader = () => (
  <Link to="/" className="layout__sidebar__header__link">
    <Icon name="indico-o" size="lg" data-testid="logo-icon" />
    <p data-testid="logo-text" className="drawer__header__logo-text">
      INDICO INSIGHTS
    </p>
  </Link>
);

interface LayoutProviderProps {
  children?: ReactNode;
  navigationItems: ListItem[];
}

export const Layout = ({ children, navigationItems }: LayoutProviderProps) => {
  // const { toggleTheme, theme } = useTheme();

  const footerItems: ListItem[] = [
    {
      id: '42',
      icon: 'logout',
      label: 'Sign Out',
      path: '/logout',
    },
  ];

  return (
    <StyledLayout>
      <div className="layout">
        <div className="layout__sidebar">
          <div className="layout__sidebar__content">
            <Drawer
              headerComponent={<LogoHeader />}
              navigationItems={navigationItems}
              footerItems={footerItems}
              // handleThemeChange={toggleTheme}
              // isLight={theme === 'dark'}
            />
          </div>
        </div>
        <div className="layout__content__container">
          <Suspense fallback={<PFCirclePulse />}>{children}</Suspense>
        </div>
      </div>
    </StyledLayout>
  );
};
