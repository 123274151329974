import { Col, Icon, Row } from '@indico-data/design-system';
import { CLEARANCE_APP } from 'constants/routes';
import { graphql } from 'gql';
import { AssociateDetails_DetailsFragment, AssociateDetails_InboxEventFragment } from 'gql/graphql';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { formatToReadableUSDateTime } from 'utils/dateUtils';
import { FieldValueComparison } from './components/FieldValueComparison';

graphql(`
  fragment AssociateDetails_Details on SuggestedActionAssociateDetails {
    submission {
      uuid
      displayId
      createdAt
    }
    highlightedFields {
      eventLinkedField {
        field {
          vectorName
        }
      }
      ...FieldValueComparison_HighlightedField
    }
  }

  fragment AssociateDetails_InboxEvent on InboxEvent {
    uuid
    createdAt
  }
`);

type Props = {
  details: AssociateDetails_DetailsFragment;
  inboxEvent: AssociateDetails_InboxEventFragment;
};

export const AssociateDetails = ({ details, inboxEvent }: Props) => {
  const { submission, highlightedFields } = details;
  const formattedEventCreatedAt = formatToReadableUSDateTime(new Date(inboxEvent.createdAt));
  const formattedSubmissionCreatedAt = formatToReadableUSDateTime(new Date(submission.createdAt));

  return (
    <StyledAssociateDetails>
      This data matches submission ID{' '}
      <StyledSmallSubmissionLink
        target="_blank"
        to={`${CLEARANCE_APP}/clearance?submissionId=${submission.uuid}`}
        data-tooltip-id="associate-details-tooltip"
      >
        {submission.displayId}
        <Icon className="ml-1" name="fa-external-link" />
      </StyledSmallSubmissionLink>{' '}
      almost completely.
      <StyledTooltip id="associate-details-tooltip" clickable place="bottom" opacity={1}>
        <StyledSubmissionComparisonArea>
          <Row>
            <Col xs={6}>
              <StyledHeader>
                <h2 className="text-h2 mb-1">Current</h2>
                <StyledReceived>
                  Received <StyledDateSpan>{formattedEventCreatedAt}</StyledDateSpan>
                </StyledReceived>
              </StyledHeader>
            </Col>
            <Col xs={6}>
              <StyledHeader>
                <StyledSubmissionLink
                  className="mb-1 text-h2"
                  target="_blank"
                  to={`${CLEARANCE_APP}/clearance?submissionId=${submission.uuid}`}
                >
                  {submission.displayId}
                </StyledSubmissionLink>
                <StyledReceived>
                  Received <StyledDateSpan>{formattedSubmissionCreatedAt}</StyledDateSpan>
                </StyledReceived>
              </StyledHeader>
            </Col>
          </Row>
          <StyledFieldList>
            {highlightedFields.map((field) => (
              <FieldValueComparison
                key={field.eventLinkedField?.field?.vectorName}
                highlightedField={field}
              />
            ))}
          </StyledFieldList>
        </StyledSubmissionComparisonArea>
      </StyledTooltip>
    </StyledAssociateDetails>
  );
};

const StyledAssociateDetails = styled.span``;

const StyledTooltip = styled(Tooltip)`
  &#associate-details-tooltip {
    max-width: 900px;
    background: var(--pf-background-color);
  }
`;

const StyledHeader = styled.div`
  padding-bottom: var(--pf-padding-3);
  border-bottom: 1px solid var(--pf-horizontal-line-color);
`;

const StyledReceived = styled.p`
  white-space: nowrap;
  font-size: var(--pf-font-size-body2);
`;

const StyledDateSpan = styled.span`
  color: var(--pf-white-color);
`;

const StyledSubmissionComparisonArea = styled.div`
  color: var(--pf-font-color);
  padding: var(--pf-padding-3);
`;

const StyledSubmissionLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  width: fit-content;

  &,
  &:hover,
  &:focus,
  &:active {
    color: var(--pf-secondary-color-300);
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledSmallSubmissionLink = styled(StyledSubmissionLink)`
  text-decoration: underline;
  display: inline;
`;

const StyledFieldList = styled.dl`
  margin-top: var(--pf-margin-3);
`;
