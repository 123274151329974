import { useRef, useEffect } from 'react';
import { PageDimensions, BoundingBox } from 'store/documentViewer/types';

type CanvasProps = {
  citation: BoundingBox | null;
  canvasWidth: number;
  canvasHeight: number;
  zoomFactor: number;
  pageDimensions: PageDimensions;
};

const calculateRectangleDimensions = (
  box: BoundingBox,
  pageDimensions: PageDimensions,
  canvasWidth: number,
  canvasHeight: number,
  zoomFactor: number,
  padding: number,
) => {
  const xFactor = (zoomFactor * canvasWidth) / pageDimensions.width;
  const yFactor = (zoomFactor * canvasHeight) / pageDimensions.height;

  const x = box.left * xFactor - padding;
  const y = box.top * yFactor - padding;
  const width = (box.right - box.left) * xFactor + 2 * padding;
  const height = (box.bottom - box.top) * yFactor + 2 * padding;

  return {
    x,
    y,
    width,
    height,
  };
};

export const OnDocCanvas = (props: CanvasProps) => {
  const { citation, canvasWidth, canvasHeight, zoomFactor, pageDimensions } = props;
  const canvas = useRef<HTMLCanvasElement>(null!);
  const canvasCxt = useRef<CanvasRenderingContext2D | null>(null);

  useEffect(() => {
    canvasCxt.current = canvas.current?.getContext('2d');
    canvasCxt.current?.save();
    canvasCxt.current?.restore();
  }, []);

  useEffect(() => {
    const bCxt = canvasCxt?.current;
    if (bCxt === null) {
      return;
    }
    bCxt?.clearRect(0, 0, canvasWidth * zoomFactor, canvasHeight * zoomFactor);
    if (citation !== null && pageDimensions != null) {
      const lineWidth = 3;
      const { x, y, width, height } = calculateRectangleDimensions(
        citation,
        pageDimensions,
        canvasWidth,
        canvasHeight,
        zoomFactor,
        3,
      );
      bCxt.lineWidth = lineWidth;
      bCxt.strokeStyle = '#0070F5';
      bCxt.fillStyle = `${bCxt.strokeStyle}13`;
      bCxt.strokeRect(x, y, width, height);
      bCxt.fillRect(x, y, width, height)
    }
  });

  return (
    <canvas
      id="canvas"
      ref={canvas}
      width={`${canvasWidth * zoomFactor}`}
      height={`${canvasHeight * zoomFactor}`}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      style={{
        height: `${canvasHeight * zoomFactor}px`,
        width: `${canvasWidth * zoomFactor}px`,
      }}
    >
      Please Turn On JS
    </canvas>
  );
};
