import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useTabNavigation = (defaultTab: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const activeTab = queryParams.get('tab') || defaultTab;

  const handleSelectTab = (tab: string) => {
    queryParams.set('tab', tab);
    navigate({ search: queryParams.toString() });
  };

  useEffect(() => {
    if (!queryParams.get('tab')) {
      queryParams.set('tab', defaultTab);
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [navigate, queryParams, defaultTab]);

  return { activeTab, handleSelectTab };
};
