import { IconName } from '@indico-data/design-system/src/types';
import { Filter, InboxAction, InboxEventStatus, Operator } from 'gql/graphql';

export const INBOX_ACTION_TO_ACTION_NAME: Record<InboxAction, string> = {
  [InboxAction.AssociateSubmission]: 'Associate to existing submission',
  [InboxAction.CreateSubmission]: 'Create new submission',
  [InboxAction.Decline]: 'Decline',
  [InboxAction.MarkSpam]: 'Spam',
};

export const INBOX_ACTION_TO_ICON_NAME: Record<InboxAction, IconName> = {
  [InboxAction.AssociateSubmission]: 'compare',
  [InboxAction.CreateSubmission]: 'fa-plus',
  [InboxAction.Decline]: 'fa-ban',
  [InboxAction.MarkSpam]: 'warning',
};

export const DEFAULT_INBOX_FILTERS: Filter[] = [
  {
    field: 'status',
    op: Operator.Equal,
    values: [InboxEventStatus.New],
  },
];
