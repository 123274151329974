import { Button, Skeleton } from '@indico-data/design-system';
import {
  InboxAction,
  InboxEventStatus,
  SuggestedActionAssociateDetails,
  SuggestedAction_InboxEventFragment,
} from 'gql/graphql';
import { graphql } from 'gql';
import styled from 'styled-components';
import { useQuery } from 'urql';
import { INBOX_ACTION_TO_ICON_NAME, INBOX_ACTION_TO_ACTION_NAME } from './constants';
import { SuggestedActionDetails } from './SuggestedActionDetails';
import {
  useAssociateEvents,
  useCreateSubmission,
  useDeclineEvents,
  useMarkEventsAsSpam,
} from '../../actions';

graphql(`
  fragment SuggestedAction_InboxEvent on InboxEvent {
    uuid
    status
    ...AssociateDetails_InboxEvent
  }
`);

type Props = {
  inboxEvent: SuggestedAction_InboxEventFragment;
};

export const SuggestedAction = ({ inboxEvent }: Props) => {
  const [{ data, fetching: fetchingSuggestedAction }] = useQuery({
    query: graphql(`
      query SuggestedAction_GetSuggestedAction($eventUuid: String!) {
        suggestInboxAction(eventUuid: $eventUuid) {
          action
          details {
            ...SuggestedActionDetails_Details
          }
        }
      }
    `),
    variables: {
      eventUuid: inboxEvent.uuid,
    },
  });

  const { fetching: fetchingCreateSubmission, action: createNewSubmission } = useCreateSubmission();
  const { fetching: fetchingDecline, action: declineEvents } = useDeclineEvents();
  const { fetching: fetchingMarkSpam, action: markEventsAsSpam } = useMarkEventsAsSpam();
  const { fetching: fetchingAssociate, action: associateEventsWithSubmission } =
    useAssociateEvents();

  if (fetchingSuggestedAction) {
    return (
      <StyledSuggestedAction>
        <Skeleton height={30} width={300} />
      </StyledSuggestedAction>
    );
  }

  if (inboxEvent.status !== InboxEventStatus.New || !data) {
    return (
      <StyledSuggestedAction>
        This event is already associated with a submission.
      </StyledSuggestedAction>
    );
  }

  const { action, details } = data.suggestInboxAction;

  const suggestedActionHandlerMap: Record<InboxAction, () => Promise<void>> = {
    [InboxAction.AssociateSubmission]: async () => {
      await associateEventsWithSubmission(
        (details as SuggestedActionAssociateDetails).submission.uuid,
        [inboxEvent.uuid],
      );
    },
    [InboxAction.CreateSubmission]: async () => {
      await createNewSubmission(inboxEvent.uuid);
    },
    [InboxAction.Decline]: async () => {
      await declineEvents([inboxEvent.uuid]);
    },
    [InboxAction.MarkSpam]: async () => {
      await markEventsAsSpam([inboxEvent.uuid]);
    },
  };

  const isRunningAction =
    fetchingCreateSubmission || fetchingDecline || fetchingMarkSpam || fetchingAssociate;

  const handleActionClick = async () => {
    await suggestedActionHandlerMap[action]();
  };

  return (
    <StyledSuggestedAction>
      <StyledButtonContainer>
        <Button
          color="secondary"
          ariaLabel="Suggested Action"
          className="mr-3"
          iconName={!isRunningAction ? INBOX_ACTION_TO_ICON_NAME[action] : undefined}
          onClick={handleActionClick}
          isLoading={isRunningAction}
        >
          {INBOX_ACTION_TO_ACTION_NAME[action]}
        </Button>
      </StyledButtonContainer>
      <SuggestedActionDetails details={details} inboxEvent={inboxEvent} />
    </StyledSuggestedAction>
  );
};

const StyledSuggestedAction = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  button {
    white-space: nowrap;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: var(--pf-padding-1);
  border-right: 1px solid var(--border-color);
  margin-right: var(--pf-margin-4);
  height: 50px;
`;
