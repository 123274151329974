import styled from 'styled-components';

export const StyledAuthForm = styled.div`
  .auth__form {
    padding: 10px;
    margin-top: 20px;

    .form__control {
      .input__wrapper {
        margin-bottom: 20px;
      }

      .auth__form__remember__me__label {
        display: flex;
        align-items: center;
        font-weight: 600;
        margin: 0;
        cursor: pointer;
        font-weight: 400;
      }

      input[type='checkbox'] {
        height: 18px;
        width: 18px;
        border-radius: 20px;
        cursor: pointer;
        margin: 0;
      }
      .auth__form__remember__me__label__text {
        margin-left: 10px;
      }
    }
  } // Auth Form

  .form--error {
    margin-top: 20px;
    font-size: 14px;
    color: #f16e00;
    font-weight: 600;
  }
`;
