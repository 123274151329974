import { Button } from '@indico-data/design-system';
import styled from 'styled-components';

interface FieldColumnHeaderResetProps {
  onClick: () => void;
}

export const FieldColumnHeaderReset = ({ onClick }: FieldColumnHeaderResetProps) => {
  return (
    <StyledFieldColumnHeaderReset>
      <Button variant="text" onClick={onClick} ariaLabel="Reset" size="sm">
        Reset
      </Button>
    </StyledFieldColumnHeaderReset>
  );
};

const StyledFieldColumnHeaderReset = styled.div`
  display: flex;
  justify-content: end;
  background-color: var(--pf-primary-color-300);
`;
