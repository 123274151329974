import { PillColor } from '@indico-data/design-system/src/types';
import { InboxEventStatus } from 'gql/graphql';

export const INBOX_TABLE_KEY = 'inbox';

export const INBOX_EVENT_STATUS_TO_PILL_COLOR: Record<InboxEventStatus, PillColor> = {
  [InboxEventStatus.Associated]: 'success',
  [InboxEventStatus.New]: 'warning',
  [InboxEventStatus.Rejected]: 'error',
  [InboxEventStatus.Spam]: 'error',
};

export const ACTIONS_SECTION_HEIGHT = 95;

export const INBOX_COLUMNS = [
  'Received',
  'Status',
  'Priority',
  'Risk Appetite',
  'Broker Name',
  'Description of Operations',
  'Named Insured',
];
