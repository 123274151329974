import styled from 'styled-components';
import { Loading } from '.';

interface TableLoadingProps {
  message: string;
}

export const TableLoading = ({ message }: TableLoadingProps) => {
  return (
    <StyledTableLoading>
      <Loading minHeight={300} message={message} />
    </StyledTableLoading>
  );
};

const StyledTableLoading = styled.div`
  background-color: var(--pf-table-background-color);
  width: 100%;
  color: var(--pf-table-font-color);
  h2 {
    font-size: var(--pf-font-size-h1);
  }
`;
