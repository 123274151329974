import * as routes from 'constants/routes';
import { ListItem } from 'components/Navigation/Sidebar/DrawerLinkList';
import { Insights } from 'providers/Insights';
import { ReactNode } from 'react';

export const SearchApp = ({ children }: { children: ReactNode }) => {
  const navigationItems: ListItem[] = [
    {
      id: '1',
      icon: 'search-thin',
      label: 'Search',
      path: `${routes.SEARCH_APP}/search`,
    },
    {
      id: '4',
      icon: 'libraries',
      label: 'Libraries',
      path: `${routes.SEARCH_APP}/libraries`,
    },
  ];

  return <Insights navigationItems={navigationItems}>{children}</Insights>;
};
