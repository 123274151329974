import { Button } from '@indico-data/design-system';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  onToggle: () => void;
  isOpen: boolean;
};

export const Header = (props: Props): JSX.Element => {
  const { children, onToggle, isOpen } = props;

  const handleToggle = () => {
    onToggle();
  };
  return (
    <StyledHeader onClick={handleToggle}>
      <Row align="center">
        <Col>{children}</Col>
        <Col className="text-align--right" xs="content">
          <Button
            onClick={handleToggle}
            iconName={isOpen ? 'chevron-up' : 'chevron-down'}
            ariaLabel="Toggle Section"
          />
        </Col>
      </Row>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  background-color: var(--pf-background-color-dark);
  padding: var(--pf-padding-4);
  cursor: pointer;
`;
