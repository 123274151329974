import { useMutation } from 'urql';
import { confirmSubmissionField as confirmSubmissionFieldMutation } from 'pages/clearance-app/mutations';
import { useClearancePageParams } from 'pages/clearance-app/pages/clearance/hooks/useClearancePageParams';
import { graphql } from 'gql';
import { FormProvider, useForm } from 'react-hook-form';
import { SubmissionField_SubmissionLinkedFieldFragment } from 'gql/graphql';
import { submissionFieldComponentMap } from '../../constants';
import { SubmissionFieldFormState } from '../../types';
import { formatValueForField } from '../../helpers';

graphql(`
  fragment SubmissionField_SubmissionLinkedField on SubmissionLinkedField {
    field {
      typeConfig {
        type
      }
      vectorName
      section
    }
    ...SubmissionTextField_SubmissionLinkedField
    ...SubmissionBooleanField_SubmissionLinkedField
  }
`);

export type Props = {
  field: SubmissionField_SubmissionLinkedFieldFragment;
};

export const SubmissionField = ({ field }: Props) => {
  const { submissionId } = useClearancePageParams();

  // Formatting the value is temporary. Remove once we have better input support for dates.
  const defaultValues =
    field.field.multi && field.values.length
      ? field.values.map(({ value }) => ({
          value: formatValueForField(value, field.field.typeConfig.type),
        }))
      : [{ value: formatValueForField(field.values[0]?.value ?? '', field.field.typeConfig.type) }];

  const formMethods = useForm<SubmissionFieldFormState>({
    mode: 'onChange',
    defaultValues: {
      values: defaultValues,
    },
  });

  const { reset } = formMethods;

  const SubmissionFieldComponent = submissionFieldComponentMap[field.field.typeConfig.type];

  const [{ fetching }, confirmSubmissionField] = useMutation(
    confirmSubmissionFieldMutation.mutation,
  );

  const updateField = async (formData: SubmissionFieldFormState) => {
    const { values } = formData;

    if (!submissionId) {
      throw new Error('No submission ID was provided, so the field could not be updated.');
    }

    await confirmSubmissionField({
      fieldVectorName: field.field.vectorName,
      submissionUuid: submissionId,
      // TODO: Remove once this is optional in API (currently required)
      valueLinkUuids: [],
      values: values.map(({ value }) => value),
    });

    // Reset the form's defaults to the new values
    reset({ values });
  };

  return (
    <FormProvider {...formMethods}>
      <SubmissionFieldComponent
        key={field.field.vectorName}
        field={field}
        onSubmit={updateField}
        isLoading={fetching}
      />
    </FormProvider>
  );
};
