import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery, UseQueryArgs, UseQueryResponse } from 'urql';

export const useLazyQuery: (args: UseQueryArgs) => UseQueryResponse = (args) => {
  const firstUpdate = useRef(true);
  const [variables, setVariables] = useState<any>();
  const { pause = false, ...restArgs } = args;

  const [result, refetch] = useQuery({
    ...restArgs,
    requestPolicy: args.requestPolicy,
    variables,
    pause: true,
  });

  useEffect(() => {
    if (pause || firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    refetch();
  }, [variables, refetch, pause]);

  const makeRequest = useCallback((reqVariables: any) => {
    setVariables(reqVariables);
  }, []);

  return [result, makeRequest];
};
