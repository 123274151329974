import { toast } from 'react-toastify';
import { useMutation } from 'urql';
import { SubmissionStatus } from 'gql/graphql';
import { updateSubmission as updateSubmissionMutation } from 'pages/clearance-app/mutations';
import { useCallback } from 'react';
import { getErrorMessage, getSubmissionUpdatedMessage } from './messages';

export const useUpdateSubmissionStatus = () => {
  const [{ fetching }, updateSubmission] = useMutation(updateSubmissionMutation.mutation);

  const action = useCallback(
    async (submissionId: string, status: SubmissionStatus) => {
      const response = await updateSubmission({ submissionId, status });

      if (response.data?.updateSubmissions) {
        toast.success(getSubmissionUpdatedMessage(status));
      }

      if (response.error) {
        toast.error(getErrorMessage('Failed to update submission'));
      }
    },
    [updateSubmission],
  );

  return { fetching, action };
};
