import { StringParam, useQueryParam } from 'use-query-params';

export const useClearancePageParams = () => {
  const [submissionId] = useQueryParam('submissionId', StringParam);
  const [selectedDocId, setSelectedDocId] = useQueryParam('selectedDocId', StringParam);

  return {
    submissionId,
    selectedDocId,
    setSelectedDocId,
  };
};
