import styled from 'styled-components';

const StyledLoadingIndicator = styled.span`
  ::after {
    animation: loading steps(1, end) 2s infinite;
    content: '';
    height: 1em;
  }

  @keyframes loading {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
    100% {
      content: '.';
    }
  }
`;

export default function LoadingIndicator() {
  return (
    <StyledLoadingIndicator>
      <span />
    </StyledLoadingIndicator>
  );
}
