import { PillColor } from '@indico-data/design-system/src/types';
import { Filter, Operator, SubmissionStatus } from 'gql/graphql';

export const WORK_QUEUE_TABLE_KEY = 'work-queue';

export const DEFAULT_WORK_QUEUE_FILTERS: Filter[] = [
  {
    field: 'status',
    op: Operator.Equal,
    values: [SubmissionStatus.Incomplete],
  },
];

export const SUBMISSION_STATUS_TO_PILL_COLOR: Record<SubmissionStatus, PillColor> = {
  [SubmissionStatus.AutoDeclined]: 'error',
  [SubmissionStatus.Declined]: 'error',
  [SubmissionStatus.Incomplete]: 'warning',
  [SubmissionStatus.QuoteReady]: 'success',
  [SubmissionStatus.AutoCleared]: 'success',
  [SubmissionStatus.Cleared]: 'success',
};
