import { graphql } from 'gql';
import { useState } from 'react';
import { EventActions_InboxEventFragment, InboxAction } from 'gql/graphql';
import { Button, Col, Container, ModalBase, Row, Skeleton } from '@indico-data/design-system';
import KabobMenu from 'pages/clearance-app/components/shared/kabobMenu/KabobMenu';
import { KabobMenuItem } from 'pages/clearance-app/components/clearance/types';
import styled from 'styled-components';
import { SuggestedAction } from './SuggestedAction';
import { INBOX_ACTION_TO_ICON_NAME } from './constants';
import { AssociationModalContent } from './association/AssociationModalContent';
import {
  useAssociateEvents,
  useCreateSubmission,
  useDeclineEvents,
  useMarkEventsAsSpam,
} from '../../actions';

graphql(`
  fragment EventActions_InboxEvent on InboxEvent {
    uuid
    ...SuggestedAction_InboxEvent
  }
`);

type Props = {
  inboxEvent?: EventActions_InboxEventFragment;
  fetchingEvent: boolean;
  onClosePanel: () => void;
};

export const EventActions = ({ inboxEvent, fetchingEvent, onClosePanel }: Props) => {
  const [isAssociationModalOpen, setIsAssociationModalOpen] = useState(false);
  const [isKabobMenuOpen, setIsKabobMenuOpen] = useState(false);

  const { fetching: fetchingCreateSubmission, action: createNewSubmission } = useCreateSubmission();
  const { fetching: fetchingDecline, action: declineEvents } = useDeclineEvents();
  const { fetching: fetchingMarkSpam, action: markEventsAsSpam } = useMarkEventsAsSpam();
  const { fetching: fetchingAssociate, action: associateEventsWithSubmission } =
    useAssociateEvents();

  const actionHandlerMap: Record<
    InboxAction,
    (event: EventActions_InboxEventFragment) => Promise<void>
  > = {
    [InboxAction.AssociateSubmission]: async () => {
      setIsAssociationModalOpen(true);
    },
    [InboxAction.CreateSubmission]: async (event: EventActions_InboxEventFragment) => {
      await createNewSubmission(event.uuid);
    },
    [InboxAction.Decline]: async (event: EventActions_InboxEventFragment) => {
      await declineEvents([event.uuid]);
    },
    [InboxAction.MarkSpam]: async (event: EventActions_InboxEventFragment) => {
      await markEventsAsSpam([event.uuid]);
    },
  };

  const handleCloseAssociationModal = () => {
    setIsAssociationModalOpen(false);
  };

  const handleAssociation = async (submissionId: string, eventUuid: string) => {
    await associateEventsWithSubmission(submissionId, [eventUuid]);
    handleCloseAssociationModal();
  };

  const handleKabobMenuItemClick = async (
    action: InboxAction,
    event: EventActions_InboxEventFragment,
  ) => {
    await actionHandlerMap[action](event);
    setIsKabobMenuOpen(false);
  };

  const eventActionItems: KabobMenuItem[] = [
    {
      label: 'Create',
      icon: INBOX_ACTION_TO_ICON_NAME[InboxAction.CreateSubmission],
      id: InboxAction.CreateSubmission,
      isLoading: fetchingCreateSubmission,
    },
    {
      label: 'Associate',
      icon: INBOX_ACTION_TO_ICON_NAME[InboxAction.AssociateSubmission],
      id: InboxAction.AssociateSubmission,
      isLoading: fetchingAssociate,
    },
    {
      label: 'Decline',
      icon: INBOX_ACTION_TO_ICON_NAME[InboxAction.Decline],
      id: InboxAction.Decline,
      isLoading: fetchingDecline,
    },
    {
      label: 'Spam',
      icon: INBOX_ACTION_TO_ICON_NAME[InboxAction.MarkSpam],
      id: InboxAction.MarkSpam,
      isLoading: fetchingMarkSpam,
    },
  ];

  const handleSubmissionClick = (submissionId: string) => {
    if (!inboxEvent) {
      throw new Error('Inbox event is undefined. Cannot associate event.');
    }

    handleAssociation(submissionId, inboxEvent.uuid);
  };

  const handleKabobMenuSelect = async (action: string) => {
    if (!inboxEvent) {
      throw new Error('Inbox event is undefined. Cannot perform action.');
    }

    handleKabobMenuItemClick(action as InboxAction, inboxEvent);
  };

  return (
    <StyledEventActions>
      <StyledContainer>
        <StyledRow>
          <Col>
            <StyledSuggestedActionContainer>
              {!inboxEvent || fetchingEvent ? (
                <Skeleton height={30} width={300} />
              ) : (
                <SuggestedAction inboxEvent={inboxEvent} />
              )}
            </StyledSuggestedActionContainer>
          </Col>
          <Col width="fit-content">
            <StyledEventInfoButtons>
              <KabobMenu
                items={eventActionItems}
                onMenuSelect={handleKabobMenuSelect}
                isOpen={isKabobMenuOpen}
                setIsOpen={setIsKabobMenuOpen}
              />
              <Button
                className="ml-2"
                iconName="fa-arrow-right-to-bracket"
                ariaLabel="Close Panel"
                color="secondary"
                variant="outline"
                onClick={onClosePanel}
              />
            </StyledEventInfoButtons>
          </Col>
        </StyledRow>
      </StyledContainer>
      <StyledModalBase open={isAssociationModalOpen} clickToDismiss={handleCloseAssociationModal}>
        <AssociationModalContent
          onClose={handleCloseAssociationModal}
          onSubmissionClick={handleSubmissionClick}
        />
      </StyledModalBase>
    </StyledEventActions>
  );
};

const StyledContainer = styled(Container)`
  height: 100%;
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledEventActions = styled.div`
  background-color: var(--pf-background-color-secondary-dark);
  overflow: none;
  width: 100%;
  height: 100%;
`;

const StyledEventInfoButtons = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledModalBase = styled(ModalBase)``;

const StyledSuggestedActionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
