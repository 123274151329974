import { Icon, RadioInput } from '@indico-data/design-system';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { ColumnConfig } from '../../types';

type Props = {
  fieldConfig: ColumnConfig;
};

export const FieldColumnHeaderSort = ({ fieldConfig }: Props) => {
  const { register, setValue, watch } = useFormContext();
  const currentSortDirection = watch('sortDirection');

  return (
    <StyledFieldColumnHeaderSort>
      <StyledMenuItem
        onClick={() => {
          setValue('sortDirection', 'asc');
        }}
        $isActive={currentSortDirection === 'asc'}
      >
        <StyledRadioInputContainer>
          <Icon name="fa-sort-alpha-asc" ariaLabel="Sort Ascending" />
          <RadioInput
            id={`sort-ascending-${fieldConfig.vectorName}`}
            value="asc"
            label="Sort Ascending"
            {...register('sortDirection')}
          />
        </StyledRadioInputContainer>
        {currentSortDirection === 'asc' && (
          <StyledCheckIconContainer>
            <Icon name="fa-check" ariaLabel="Selected" />
          </StyledCheckIconContainer>
        )}
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => {
          setValue('sortDirection', 'desc');
        }}
        $isActive={currentSortDirection === 'desc'}
      >
        <StyledRadioInputContainer>
          <Icon name="fa-sort-alpha-desc" ariaLabel="Sort Descending" />
          <RadioInput
            id={`sort-descending-${fieldConfig.vectorName}`}
            value="desc"
            label="Sort Descending"
            {...register('sortDirection')}
          />
        </StyledRadioInputContainer>
        {currentSortDirection === 'desc' && (
          <StyledCheckIconContainer>
            <Icon name="fa-check" ariaLabel="Selected" />
          </StyledCheckIconContainer>
        )}
      </StyledMenuItem>
    </StyledFieldColumnHeaderSort>
  );
};

const StyledFieldColumnHeaderSort = styled.div`
  margin-bottom: var(--pf-margin-2);
`;

const StyledMenuItem = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--pf-padding-2) var(--pf-padding-3);
  color: var(--pf-white-color);

  /* TODO: Remove these radio input styles when we have a design system configuration for custom radio buttons */
  .form-control {
    .radio-wrapper {
      margin: 0;
      .radio-input-label {
        padding-left: 0;
        margin-bottom: 0;
      }

      label::before,
      label::after {
        display: none;
      }
    }

    /* Hide the input to avoid scroll jumps */
    /* TODO: Determine a better solution for scroll jumps without sacrificing accessibility */
    input {
      display: none;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: var(--pf-secondary-color-400) !important;
    `}
`;

const StyledRadioInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--pf-margin-2);
`;

const StyledCheckIconContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: var(--pf-margin-2);
`;
