import { Breakpoint } from 'constants/breakpoints';
import { css } from 'styled-components';

export const textTruncationWithEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const media = {
  xs: `@media (min-width: ${Breakpoint.xs}px) and (max-width: ${Breakpoint.sm}px)`,
  sm: `@media (min-width: ${Breakpoint.sm}px) and (max-width: ${Breakpoint.md}px)`,
  md: `@media (min-width: ${Breakpoint.md}px) and (max-width: ${Breakpoint.lg}px)`,
  lg: `@media (min-width: ${Breakpoint.lg}px) and (max-width: ${Breakpoint.xl}px)`,
  xl: `@media (min-width: ${Breakpoint.xl}px) and (max-width: ${Breakpoint.xxl}px)`,
  xxl: `@media (min-width: ${Breakpoint.xxl}px) and (max-width: ${Breakpoint.xxxl}px)`,
  xxxl: `@media (min-width: ${Breakpoint.xxxl}px)`,
};
