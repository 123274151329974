import styled from 'styled-components';

export const StyledDrawerLinkList = styled.div`
  .drawer__list {
    white-space: nowrap;

    .drawer__list__item {
      box-sizing: border-box;
      margin-bottom: var(--pf-margin-2);
      font-weight: var(--pf-font-weight-medium);
      font-size: var(--pf-font-size-base);
      display: flex;
      align-items: center;
      padding-top: var(--pf-padding-3);
      padding-bottom: var(--pf-padding-3);

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: var(--sidebar-link-hover-text-color);
        background-color: var(--sidebar-link-hover-color);

        a,
        &:active {
          color: var(--pf-primary-color);
        }
      }

      &--active {
        color: var(--pf-white-color);
        background-color: var(--pf-primary-color);
        border-radius: var(--pf-rounded);

        a,
        &:active {
          color: var(--pf-white-color);
        }
      }

      .drawer__list__item__link {
        font-size: var(--pf-font-size-base);
        display: flex;
        align-items: center;

        .drawer__list__item__icon {
          margin-right: var(--pf-margin-3);
          margin-left: var(--pf-margin-3);
        }

        .drawer__list__item__label {
          opacity: 1;
        }

        .drawer__list__item__label--hidden {
          opacity: 0;
          transition: all 0.3s ease-out;
        }
      }
    }
  }
`;
