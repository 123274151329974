import { Icon } from '@indico-data/design-system';
import styled, { css } from 'styled-components';

type ThumbIconProps = {
  isPositive: boolean;
};

export const Thumb = ({ isPositive }: ThumbIconProps) => (
  <StyledThumb $isPositive={isPositive}>
    <Icon name={isPositive ? 'thumbs-up' : 'thumbs-down'} />
  </StyledThumb>
);

const StyledThumb = styled.span<{ $isPositive: boolean }>`
  border-radius: var(--pf-rounded-circle);
  padding: 6px;
  width: 28px !important;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $isPositive }) =>
    $isPositive
      ? css`
          border: 1px solid var(--pf-green-color-100);
          background: var(--pf-green-color-900);
          svg {
            color: var(--pf-green-color-100);
          }
        `
      : css`
          border: 1px solid var(--pf-red-color-100);
          background: var(--pf-red-color-900);
          svg {
            color: var(--pf-red-color-100);
          }
        `}
`;
